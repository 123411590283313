import { searchProduct } from '../../../../odooApi/product';
import { getSingleattribute } from '../../../../odooApi/productAttribute';
import formInputsTypes from '../simulatorConstant';
export const TOILE_STORE_BANNE = 106;
export const FIXATION_ATTRIBUTE = 7;

import droite from '../../../../assets/img/stock/store/Droite.jpg';

import gauche from '../../../../assets/img/stock/store/Gauche.jpg';

import manivelles from '../../../../assets/img/stock/store/manivelle.jpg';

export const capteur = {
    id: 'capteur_vent',
    type: formInputsTypes?.RADIO_INPUT,
    label: 'Capteur de vent',
    name: 'capteur_vent',
    nextStepData: false,
    nextStep: false,
    options: null,

    fetchData: (tokenUserCurent, currentFormValue, fnSuccess) => {
        let data = '';
        const generateDescription = (data) => {
            return ` ${currentFormValue?.StoreBanneData ? `` + currentFormValue?.StoreBanneData?.value : ''
                } ${currentFormValue?.manoeuvre_store
                    ? ` ` + currentFormValue?.manoeuvre_store?.value + ' complet'
                    : ''
                } ${currentFormValue?.TypeStorebanne
                    ? ` \n - Store banne ` + currentFormValue?.TypeStorebanne?.label
                    : ''
                } ${currentFormValue?.largeurToile
                    ? ` \n - Largeur de store banne ` + currentFormValue?.largeurToile + ' (mm)'
                    : ''
                } ${currentFormValue?.avancee ? `\n - Avancée ` + currentFormValue?.avancee?.label : ''
                } ${data ? `\n - ` + data : ''} ${currentFormValue?.lambrequin
                    ? `\n - ` + currentFormValue?.lambrequin?.value
                    : ''
                } ${currentFormValue?.telecommande
                    ? `\n - ` + currentFormValue?.telecommande?.value
                    : ''
                } ${currentFormValue?.sortie
                    ? `\n - Sortie électrique ` + currentFormValue?.sortie?.value
                    : ''
                } 
             `;
        };
        const options = [

            {
                id: 'avec',
                label: 'Avec capteur de vent',
                value: 'Avec capteur de vent',
                product_id: currentFormValue?.StoreBanneData?.id,
                description: generateDescription((data = 'Avec capteur de vent')),
                nextStep: false,
            },
            {
                id: 'sans',
                label: 'Sans capteur de vent',
                value: 'Sans capteur de vent',
                product_id: currentFormValue?.StoreBanneData?.id,
                description: generateDescription((data = 'Sans capteur de vent')),
                nextStep: false,
            },

        ];

        fnSuccess(options);
    },
};

export const sortie = {
    id: 'sortie',
    type: formInputsTypes?.RADIO_INPUT,
    label: 'Sortie électrique (vue en face)',
    name: 'sortie',
    nextStepData: capteur,
    classGroup: "SProduit",
    nextStep: false,
    options: [

        {
            id: 'gauche',
            label: '',
            value: 'gauche',
            imageUrl: gauche,
            nextStep: false,
        },
        {
            id: 'droite',
            label: '',
            value: 'droite',
            imageUrl: droite,
            nextStep: false,
        }
    ],
};

export const telecommande = {
    id: 'telecommande',
    type: formInputsTypes?.RADIO_INPUT,
    label: 'Type de motorisation',
    name: 'telecommande',
    nextStepData: sortie,
    nextStep: false,
    options: [
        {
            id: 'motorisation_radio',
            label: 'Radio',
            value: 'Motorisation radio',
            nextStep: false,
        },
        {
            id: 'motorisation_filaire',
            label: 'Filaire',
            value: 'Motorisation filaire',
            nextStep: false,
        },
        {
            id: 'motorisation_solaire',
            label: 'Solaire',
            value: 'Motorisation solaire',
            nextStep: false,
        },
    ],
};





export default telecommande;