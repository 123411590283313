import Modal, { ModalBody, ModalFooter, ModalHeader } from '../bootstrap/Modal';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../bootstrap/Card';
import Button from '../bootstrap/Button';
import FormGroup from '../bootstrap/forms/FormGroup';
import { useFormik } from 'formik';
import Input from '../bootstrap/forms/Input';
import Alert from '../bootstrap/Alert';
import { AutoComplete, getPlaceDetails } from '../../googleApi/placeAutoComplete';
import SearchAutoComplete from '../customComponents/SearchAutoComplete';
import AuthContext from '../../contexts/authContext';
import { searchCountry, searchCountryState } from '../../odooApi/country';
import { createClientPartner } from '../../odooApi/clientPartner';
import Spinner from '../bootstrap/Spinner';
import StatusPopup from '../PlanningCalendar/StatusPopup';
import ErrorPopup from '../extras/ErrorPopup';
import Checks from '../bootstrap/forms/Checks';
import Label from '../bootstrap/forms/Label';

const CreateClientPopup = ({ setCreateClientModalStatus, handelSelect }) => {
	const [resultSearch, setResultSearch] = useState([]);
	const [selectItem, setSelectItem] = useState();
	const { userData } = useContext(AuthContext);
	const [spinner, setSpinner] = useState(false);
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(false);
	const [isFournisseur, setIsFournisseur] = useState(false)

	const createClient = (values) => {
		if (userData && userData?.id && userData?.password) {
			setSpinner(true);
			setSuccess(false);
			setError(false);
			searchCountryState(
				userData?.password,
				[
					[
						'name',
						'=',
						selectItem.address_components.filter((item) =>
							item?.types.includes('administrative_area_level_1'),
						)[0]?.long_name,
					],
				],
				({ elements }) => {
					const newUser = {
						name: values.name,
						phone: values.phone || '',
						mobile: values.mobile || '',
						email: values.email || '',
						city: values.city || '',
						zip: values.zip || '',
						street: values.street || '',
						street2: values.street2 || '',
						places: selectItem?.url,
						country_id: 75,
						state_id: (!!elements?.length && elements[0]?.id) || '',
						function: isFournisseur ? "fournisseur" : "client"
					};

					createClientPartner(
						userData?.password,
						newUser,
						(data) => {
							handelSelect({
								selected: { client: data },
								value: { client: data?.name },
							});
							setCreateClientModalStatus(false);
							setSpinner(false);
							setSuccess('Contact est créer avec success.');
							setError(false);
						},
						(error) => {
							setSpinner(false);
							setSuccess(false);
							setError(error);
						},
					);
				},
				(error) => {
					setSpinner(false);
					setSuccess(false);
					setError(error);
				},
			);
		}
	};
	console.log(error);
	const formik = useFormik({
		initialValues: {
			name: '',
			phone: '',
			email: '',
			mobile: '',
			adrsFact: '',
			adrsLivr: '',
			street: '',
			city: '',
			zip: '',
		},
		validate: (values) => {
			const errors = {};

			if (!values.name) {
				errors.name = 'Obligatoire !';
			}
			if (!!values.email) {
				if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
					errors.email = 'Adresse e-mail invalide';
				}
			}

			if (!values.phone) {
				errors.phone = 'Obligatoire !';
			}
			if (!values.adrsFact) {
				errors.adrsFact = 'Obligatoire !';
			}
			if (!values.street) {
				errors.street = 'Obligatoire !';
			}
			if (!values.zip) {
				errors.zip = 'Obligatoire !';
			}
			if (!values.city) {
				errors.city = 'Obligatoire !';
			}

			return errors;
		},
		onSubmit: (values) => {
			createClient(values);
		},
	});

	const handleChange = (e) => {
		formik.handleChange(e);
		AutoComplete(e.target.value, (result) => {
			const newLocations = [];
			result?.predictions?.map((item) => {
				newLocations.push({
					id: item?.place_id,
					label: item?.description,
					icon: '',
				});
			});
			setResultSearch(newLocations);
		});
	};

	const handelSelectItem = (item) => {
		formik.setFieldValue('adrsFact', item?.label);
		getPlaceDetails(item?.id, (result) => {
			setSelectItem(result?.result);
			formik.setFieldValue(
				'city',
				result?.result?.address_components.filter((item) =>
					item?.types.includes('locality'),
				)[0]?.long_name ||
					result?.result?.address_components.filter((item) =>
						item?.types.includes('administrative_area_level_2'),
					)[0]?.long_name ||
					'',
			);
			formik.setFieldValue(
				'zip',
				result?.result?.address_components.filter((item) =>
					item?.types.includes('postal_code'),
				)[0]?.long_name || '',
			);
			formik.setFieldValue(
				'street',
				(result?.result?.address_components.filter((item) =>
					item?.types.includes('street_number'),
				)[0]?.long_name || '') +
					' ' +
					(result?.result?.address_components.filter((item) =>
						item?.types.includes('route'),
					)[0]?.long_name || ''),
			);
			formik.setFieldValue(
				'street2',
				result?.result?.address_components.filter((item) =>
					item?.types.includes('administrative_area_level_3'),
				)[0]?.long_name ||
					result?.result?.address_components.filter((item) =>
						item?.types.includes('administrative_area_level_4'),
					)[0]?.long_name ||
					result?.result?.address_components.filter((item) =>
						item?.types.includes('administrative_area_level_5'),
					)[0]?.long_name ||
					'',
			);
		});
	};
	useEffect(() => {
		if (!(formik.values.adrsFact.length > 0)) {
			formik.values.zip = '';
			formik.values.street = '';
			formik.values.city = '';
		}
	}, [formik.values.adrsFact]);
	return (
		<Modal setIsOpen={() => {}} isOpen={true} isStaticBackdrop isScrollable classNameMd='nv-2'>
			<ModalHeader setIsOpen={setCreateClientModalStatus}></ModalHeader>
			<ModalBody className='px-4'>
				<div className='row'>
					<div className='col-md-12'>
						<Card shadow='sm'>
							<CardHeader>
								<CardLabel icon='ManageAccounts' iconColor='primary'>
									<CardTitle>Créer un nouveau contact</CardTitle>
								</CardLabel>
							</CardHeader>
							<CardBody>
								<div className='row g-4'>
									<FormGroup className='col-12' id='name' label='Nom complet'>
										<Input
											onChange={formik.handleChange}
											value={formik.values.name}
											isTouched={formik.touched.name}
											isValid={formik.isValid}
											onBlur={formik.handleBlur}
											invalidFeedback={formik.errors.name}
											validFeedback='Bien!'
											name='name'
											type='text'
											autoComplete='off'
										/>
									</FormGroup>

									<FormGroup className='col-12' id='email' label='Adresse e-mail'>
										<Input
											onChange={formik.handleChange}
											value={formik.values.email}
											onBlur={formik.handleBlur}
											isValid={formik.isValid}
											isTouched={formik.touched.email}
											invalidFeedback={formik.errors.email}
											name='email'
											type='email'
											autoComplete='off'
										/>
									</FormGroup>

									<FormGroup className='col-6' id='phone' label='Tél'>
										<Input
											onChange={formik.handleChange}
											value={formik.values.phone}
											isTouched={formik.touched.phone}
											isValid={formik.isValid}
											onBlur={formik.handleBlur}
											invalidFeedback={formik.errors.phone}
											// validFeedback='Bien!'
											name='phone'
											type='tel'
										/>
									</FormGroup>
									<FormGroup className='col-6' id='mobile' label='Mobile'>
										<Input
											onChange={formik.handleChange}
											value={formik.values.mobile}
											name='mobile'
											type='tel'
										/>
									</FormGroup>

									<SearchAutoComplete
										label='Adresse Facturation'
										className='col-12'
										value={formik.values.adrsFact}
										onChange={handleChange}
										options={resultSearch}
										setSelectItem={handelSelectItem}
										isTouched={formik.touched.adrsFact}
										isValid={formik.isValid}
										onBlur={formik.handleBlur}
										invalidFeedback={formik.errors.adrsFact}
									/>

									<FormGroup className='col-6' id='street' label='Rue'>
										<Input
											type='text'
											name='street'
											value={formik?.values?.street}
											onChange={formik?.handleChange}
											isTouched={formik.touched.street}
											isValid={formik.isValid}
											onBlur={formik.handleBlur}
											invalidFeedback={formik.errors.street}
											validFeedback='Bien!'
										/>
									</FormGroup>
									<FormGroup id='zip' className='col-3' label='Code postal'>
										<Input
											type='number'
											name='zip'
											value={formik?.values?.zip}
											onChange={formik?.handleChange}
											isTouched={formik.touched.zip}
											isValid={formik.isValid}
											onBlur={formik.handleBlur}
											invalidFeedback={formik.errors.zip}
											validFeedback='Bien!'
										/>
									</FormGroup>

									<FormGroup id='city' className='col-3' label='Ville'>
										<Input
											type='text'
											name='city'
											value={formik?.values?.city}
											onChange={formik?.handleChange}
											isTouched={formik.touched.city}
											isValid={formik.isValid}
											onBlur={formik.handleBlur}
											invalidFeedback={formik.errors.city}
											validFeedback='Bien!'
										/>
									</FormGroup>

									<FormGroup id='function' >
										<div className="d-flex">
											<Checks

												type='checkbox'
												name='function'
												id='function'
												value={isFournisseur}
												onChange={() => setIsFournisseur(!isFournisseur)}
												checked={!!isFournisseur}
											/>
											<Label>Fournisseur</Label>
										</div>

									</FormGroup>

									{/* <FormGroup
										className='col-6'
										id='adrsLivr'
										label='Addresse Livraison'>
										<Input
											onChange={formik.handleChange}
											value={formik.values.adrsLivr}
											name='adrsLivr'
											type='text'
										/>
									</FormGroup> */}
								</div>
							</CardBody>
						</Card>
					</div>
				</div>
			</ModalBody>
			<ModalFooter className='px-4 pb-4'>
				<Button
					color='primary'
					className='w-100'
					type='submit'
					isDisable={spinner}
					onClick={formik.handleSubmit}>
					{spinner && <Spinner isSmall inButton />}
					{spinner ? 'En cours' : 'Créer'}
				</Button>
			</ModalFooter>

			{!!success && (
				<StatusPopup
					icon='Verified'
					color='success'
					title={'Super!'}
					label={success}
					setOpen={setSuccess}
				/>
			)}
			{!!error && (
				<ErrorPopup
					error={error}
					setOpen={setError}
				/>
			)}
		</Modal>
	);
};

CreateClientPopup.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	// @ts-ignore
	setCreateClientModalStatus: PropTypes.func.isRequired,
	handelSelect: PropTypes.func,

	// eslint-disable-next-line react/forbid-prop-types

	/* selectedValue:PropTypes.object */
};
export default CreateClientPopup;
