import formInputsTypes from '../simulatorConstant';
export const Options = {
	id: 'Options',
	type: formInputsTypes?.CHECKBOX_INPUT,
	label: "Autre main d'oeuvre",
	name: 'Options',
	className: 'col-12 mt-6',
	classGroup: 'simulator-check',
	nextStepData: false,
	nextStep: false,
	options: false,
	fetchData: (tokenUserCurent, currentFormValue, fnSuccess) => {
		let data = '';
		let depose_data = '';
		let pose_data = '';
		let prix_pose = 0;
		let prix_depose = 0;
		// multi choix depose
		if (currentFormValue?.depose?.length >= 1) {
			currentFormValue?.depose?.map((option) => {
				depose_data += '\n - Dépose ' + option.value;
				prix_depose += option.lst_price;
			});
		}
		// multi choix pose
		if (currentFormValue?.pose?.length >= 1) {
			currentFormValue?.pose?.map((option) => {
				pose_data += '\n - Pose ' + option.value;
				prix_pose += option.lst_price;
			});
		}

		const generateDescription = () => {
			return `Main d'oeuvre ${currentFormValue?.depose ? depose_data : ''} ${currentFormValue?.pose ? pose_data : ''
				} `;
		};
		const options = [
			{
				id: 'Création rideau métallique complet',
				value: "Création d'un rideau métallique complet",
				label: "Création d'un rideau métallique complet",
				isInline: true,
				nextStep: Options,
				lst_price: 0,
				product_id: 41,
				description: generateDescription(),
			},
			{
				id: 'Ouverture du coffre non difficile',
				label: 'Ouverture du coffre non difficile',
				value: 'Ouverture du coffre non difficile',
				isInline: true,
				nextStep: Options,
				lst_price: 0,
				product_id: 41,
				description: generateDescription(),
			},
			{
				id: 'Ouverture du coffre difficile',
				label: 'Ouverture du coffre difficile',
				value: 'Ouverture du coffre difficile',
				isInline: true,
				nextStep: Options,
				lst_price: 0,
				product_id: 41,
				description: generateDescription(),
			},
			{
				id: 'Tirage de ligne électrique',
				label: 'Tirage de ligne électrique',
				value: 'Tirage de ligne électrique',
				lst_price: 0,
				isInline: true,
				product_id: 41,
				description: generateDescription(),
			},
			{
				id: 'Mise en place des agrafes',
				label: 'Mise en place des agrafes',
				value: 'Mise en place des agrafes',
				lst_price: 0,
				isInline: true,
				product_id: 41,
				description: generateDescription(),
			},
			{
				id: 'Branchement électrique',
				label: 'Branchement électrique',
				value: 'Branchement électrique',
				lst_price: 0,
				isInline: true,
				product_id: 41,
				description: generateDescription(),
			},
			{
				id: 'Réglage fin de course',
				label: 'Réglage fin de course',
				value: 'Réglage fin de course',
				lst_price: 0,
				isInline: true,
				product_id: 41,
				description: generateDescription(),
			},
			{
				id: 'Mise en service',
				label: 'Mise en service',
				value: 'Mise en service',
				lst_price: 0,
				isInline: true,
				product_id: 41,
				description: generateDescription(),
			},
			{
				id: 'Mise à la décharge',
				label: 'Mise à la décharge',
				value: 'Mise à la décharge',
				lst_price: 0,
				isInline: true,
				product_id: 41,
				description: generateDescription(),
			},
			{
				id: 'Fermeture du coffre',
				label: 'Fermeture du coffre',
				value: 'Fermeture du coffre',
				lst_price: 0,
				isInline: true,
				product_id: 41,
				description: generateDescription(),
			},
		];

		fnSuccess(options);
	},
};
const pose = {
	id: 'pose',
	type: formInputsTypes?.CHECKBOX_INPUT,
	label: 'Pose',
	name: 'pose',
	className: 'col-12',
	nextStepData: Options,
	nextStep: Options,
	options: [
		{
			id: 'pose_M_RM',
			label: 'Manivelle',
			value: 'manivelle rideau métallique',
			lst_price: 48,
			isInline: true,
		},
		{
			id: 'pose_Mot_RM',
			label: 'Moteur',
			value: 'moteur rideau métallique',
			lst_price: 48,
			isInline: true,
		},
		{
			id: 'pose_axe_manuel_RM',
			label: 'Axe Manuel',
			value: 'axe manuel rideau métallique',
			lst_price: 48,
			isInline: true,
		},
		{
			id: 'pose_axe_mot_RM',
			label: 'Axe motorisé',
			value: 'axe motorisé rideau métallique',
			lst_price: 68,
			isInline: true,
		},
		{
			id: 'pose_tablier_RM',
			label: 'Tablier',
			value: 'tablier rideau métallique',
			lst_price: 42,
			isInline: true,
		},
		{
			id: 'pose__RM',
			label: 'Rideau complet',
			value: 'rideau métallique complet',
			lst_price: 120,
			isInline: true,
		},
	],
};

const depose = {
	id: 'depose',
	type: formInputsTypes?.CHECKBOX_INPUT,
	label: 'Dépose',
	name: 'depose',
	className: 'col-12',
	nextStepData: pose,
	nextStep: pose,
	options: [
		{
			id: 'depose_M',
			label: 'Manivelle',
			value: 'manivelle rideau métallique',
			lst_price: 25,
			isInline: true,
		},
		{
			id: 'depose_Mot',
			label: 'Moteur',
			value: 'moteur rideau métallique',
			lst_price: 40,
			isInline: true,
		},
		{
			id: 'depose_axe_manuel',
			label: 'Axe Manuel',
			value: 'axe manuel rideau métallique',
			lst_price: 30,
			isInline: true,
		},
		{
			id: 'depose_axe_mot',
			label: 'Axe motorisé',
			value: 'axe motorisé rideau métallique',
			lst_price: 60,
			isInline: true,
		},
		{
			id: 'depose_tablier',
			label: 'Tablier',
			value: 'tablier rideau métallique',
			lst_price: 42,
			isInline: true,
		},
		{
			id: 'depose_RM',
			label: 'Rideau complet',
			value: 'rideau métallique complet',
			lst_price: 80,
			isInline: true,
		},
	],
};

export default depose;
