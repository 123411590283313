import React, { useState, useContext, useEffect } from 'react';
import Badge from '../bootstrap/Badge';
import Card, {
	CardBody,
	CardFooter,
	CardFooterLeft,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../bootstrap/Card';
import useDarkMode from '../../hooks/useDarkMode';
import useTourStep from '../../hooks/useTourStep';
import { useTranslation } from 'react-i18next';
import Button from '../bootstrap/Button';
import Wizard, { WizardItem } from '../Wizard';
import { useFormik } from 'formik';
import Avatar from '../Avatar';
import Input from '../bootstrap/forms/Input';
import FormGroup from '../bootstrap/forms/FormGroup';
import Select from '../bootstrap/forms/Select';
import Checks, { ChecksGroup } from '../bootstrap/forms/Checks';
import Label from '../bootstrap/forms/Label';
import { createemployee, getAllPostes, getSingleEmployee, updateEmployee, uploadImageEmployee } from '../../odooApi/employee';
import AuthContext from '../../contexts/authContext';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../bootstrap/Modal';

import { useNavigate, useParams } from 'react-router-dom';
import { getImageByModule } from "../../common/function/functionsUtils";
import SimulatorPopUp from '../Simulateur/SimulatorPopUp';
import MainOeuvre from '../../common/data/simulatorData/MainOuvre';




const DevisSteps = ({ data, setEditModalSimulator, simulatorData, currentFormValue, setCurrentFormValue, children, handelCreateLine }) => {

	const navigate = useNavigate();
	useTourStep(13);
	const { userData } = useContext(AuthContext);
	const { darkModeStatus } = useDarkMode();
	const [editModalStatus, setEditModalStatus] = useState(false);
	const [employee, setEmployee] = useState([])
	const { id } = useParams(0);
	const TABS = {
		VOLET_ROULANT: 'Volet roulant',
		RIDEAU_METALLIQUE: 'Rideau métallique',
		STORE: 'Demande congé',
		MAIN_OEUVRE: 'Main Oeuvre',
		PIECE_DETACHEE: 'Historique'

	};
	const [activeTab, setActiveTab] = useState(TABS.MAIN_OEUVRE);
	const handelCancel = () => {
		setEditModalSimulator(false)
	}

	const { t } = useTranslation(['translation', 'menu']);
	const [rideauModel, setrideauModel] = useState(false);
	const [mainOeuvre, setmainOeuvre] = useState(false)

	return (
		<>
			<Modal
				setIsOpen={() => { }}
				isOpen={true}
				size='lg'
				isScrollable

			>
				<ModalHeader className='px-4' setIsOpen={() => setEditModalSimulator(false)}>
					{<ModalTitle id='project-edit'>{data?.modalTitle}</ModalTitle>}
				</ModalHeader>
				<ModalBody className='px-4' id="modelBody">
					<div className='row'>
						<div className='col-md-12'></div>
						<div className='col-lg-12'>
							<Card >
								<CardHeader>
									<CardLabel icon='AccountCircle'>
										<CardTitle>Simulez votre produit</CardTitle>
									</CardLabel>
								</CardHeader>
								<CardBody isScrollable>
									<div className='row g-3'>
										<div className='col-4'>
											<Button
												icon='Contacts'
												color='info'
												className='w-100 p-3'
												isLight={TABS.VOLET_ROULANT !== activeTab}
												onClick={() => setActiveTab(TABS.VOLET_ROULANT)}>
												{TABS.VOLET_ROULANT}
											</Button>
										</div>
										<div className='col-4'>

											<Button
												icon='LocalPolice'
												color='info'
												className='w-100 p-3'
												isLight={TABS.RIDEAU_METALLIQUE !== activeTab}
												onClick={() => { setrideauModel(true); }}>
												{TABS.RIDEAU_METALLIQUE}
											</Button>

										</div>
										<div className='col-4'>
											<Button
												icon='LocalPolice'
												color='info'
												className='w-100 p-3'
												isLight={TABS.MAIN_OEUVRE !== activeTab}
												onClick={() => setmainOeuvre(true)}>
												{TABS.MAIN_OEUVRE}
											</Button>
										</div>

									</div>
								</CardBody>
								<CardFooter>
									<CardFooterLeft className='w-100'>
										<Button
											color='warning'
											className='w-100'
											onClick={handelCancel}

										>
											Retourner
										</Button>
									</CardFooterLeft>
								</CardFooter>
							</Card>
						</div>

						{!!rideauModel && (
							<RideauMetallique setrideauModel={setrideauModel}
								data={{
									order_id: data?.order_id,
									modalTitle: "Conception de produits et simulation",
									cardTitle: t('Simulate Product')
								}}
								simulatorData={simulatorData}
								currentFormValue={currentFormValue}
								setCurrentFormValue={setCurrentFormValue}>
								<Button
									color='success'
									className='w-100'
									onClick={handelCreateLine}
								>
									{t('Add to devis')}
								</Button>
								<Button
									color='warning'
									className='w-100'
									onClick={handelCancel}
								>
									{t('Cancel')}
								</Button>
							</RideauMetallique>
						)}
						{mainOeuvre && (
							<MainOeuvre setmainOeuvre={setmainOeuvre}
								data={{
									order_id: data?.order_id,
									modalTitle: "Conception de produits et simulation",
									cardTitle: t('Simulate Product')
								}}
								simulatorData={simulatorData}
								currentFormValue={currentFormValue}
								setCurrentFormValue={setCurrentFormValue}>
								<Button
									color='success'
									className='w-100'
									onClick={handelCreateLine}
								>
									{t('Add to devis')}
								</Button>
								<Button
									color='warning'
									className='w-100'
									onClick={handelCancel}
								>
									{t('Cancel')}
								</Button>
							</MainOeuvre>
						)}
						{TABS.VOLET_ROULANT === activeTab && (
							<SimulatorPopUp
								setEditModalSimulator={setEditModalSimulator}
								data={{
									order_id: data?.order_id,
									modalTitle: "Conception de produits et simulation",
									cardTitle: t('Simulate Product')
								}}
								simulatorData={simulatorData}
								currentFormValue={currentFormValue}
								setCurrentFormValue={setCurrentFormValue}
							>
								<Button
									color='success'
									className='w-100'
									isDisable={!currentFormValue?.complet}
									onClick={handelCreateLine}
								>
									{t('Add to devis')}
								</Button>
								<Button
									color='warning'
									className='w-100'
									onClick={handelCancel}
								>
									{t('Cancel')}
								</Button>
							</SimulatorPopUp>

						)}


					</div>
				</ModalBody>
			</Modal>

		</>
	);
};


export default DevisSteps;