import formInputsTypes from '../simulatorConstant';
import { searchProduct } from '../../../../odooApi/product';
import { getSingleattribute } from '../../../../odooApi/productAttribute';



export const TOILE_STORE_BANNE = 106;
export const FIXATION_ATTRIBUTE = 7;

const lambrequin = {
	id: 'lambrequin',
	type: formInputsTypes?.SELECT_INPUT,
	label: 'Lambrequin',
	name: 'lambrequin',
	nextStepData: false,
	nextStep: false,
	options: null,
	fetchData: (tokenUserCurent, currentFormValue, fnSuccess) => {
		let data = '';
		const generateDescription = (data) => {
			return ` ${currentFormValue?.StoreBanneData ? `` + currentFormValue?.StoreBanneData?.value : ''
				} ${currentFormValue?.manoeuvre_store
					? ` ` + currentFormValue?.manoeuvre_store?.value + ' complet'
					: ''
				} ${currentFormValue?.ToileStoreBanneData
					? ` \n - Toile ` + currentFormValue?.ToileStoreBanneData?.label
					: ''
				}${currentFormValue?.TypetoileStorebanne
					? ` \n - ` + currentFormValue?.TypetoileStorebanne?.label
					: ''
				} ${currentFormValue?.largeurToile
					? ` \n - Largeur de la toile ` + currentFormValue?.largeurToile + ' (mm)'
					: ''
				} ${currentFormValue?.projection
					? ` \n - Projection ` + currentFormValue?.projection
					: ''
				} ${currentFormValue?.avancee ? `\n - Avancée ` + currentFormValue?.avancee?.label : ''
				} ${currentFormValue?.cache
					? `\n - Couleur des caches en plastique ` + currentFormValue?.cache?.label
					: ''
				} ${currentFormValue?.fixation_haute
					? `\n - Fixation haute ` + currentFormValue?.fixation_haute?.label
					: ''
				} ${currentFormValue?.fixation_basse
					? `\n - Fixation basse ` + currentFormValue?.fixation_basse?.label
					: ''
				} ${currentFormValue?.type_fixation
					? `\n - Fixation ` + currentFormValue?.type_fixation?.value
					: ''
				} ${data ? `\n - ` + data : ''} ${currentFormValue?.capteur_vent
					? `\n - ` + currentFormValue?.capteur_vent?.value
					: ''
				} ${currentFormValue?.manivelle_store
					? `\n - ` + currentFormValue?.manivelle_store?.label
					: ''
				} ${currentFormValue?.hauteur_manivelle_store
					? `\n - Hauteur de la manivelle ` +
					currentFormValue?.hauteur_manivelle_store?.label
					: ''
				} ${currentFormValue?.remplacement_telecommande?.id == 'oui_telecommande'
					? `\n - Avec télécommande `
					: ''
				} ${currentFormValue?.telecommande
					? `\n - ` + currentFormValue?.telecommande?.label
					: ''
				} ${currentFormValue?.sortie
					? `\n - Sortie électrique ` + currentFormValue?.sortie?.value
					: ''
				} 
             `;
		};
		const options = [
			{
				id: 'sans_lambrequin',
				label: 'Sans lambrequin',
				value: 'sans lambrequin',
				product_id: currentFormValue?.StoreBanneData?.id,
				description: generateDescription((data = 'Sans lambrequin')),
				nextStep: false,
			},
			{
				id: 'coupe_droite',
				label: 'Coupe droite',
				value: 'coupe droite',
				product_id: currentFormValue?.StoreBanneData?.id,
				description: generateDescription((data = 'Lambrequin coupe droite')),
				nextStep: false,
			},
			{
				id: 'coupe_ondulee',
				label: 'Coupe ondulée',
				value: 'coupe ondulée',
				product_id: currentFormValue?.StoreBanneData?.id,
				description: generateDescription((data = 'Lambrequin coupe ondulée')),
				nextStep: false,
			},
			{
				id: 'coupe_vagues',
				label: 'Coupe à vagues',
				value: 'coupe à vagues',
				product_id: currentFormValue?.StoreBanneData?.id,
				description: generateDescription((data = 'Lambrequin coupe à vagues')),
				nextStep: false,
			},
			{
				id: 'lambrequin_enroulable',
				label: 'Lambrequin enroulable 150mm',
				value: 'lambrequin enroulable 150mm',
				product_id: currentFormValue?.StoreBanneData?.id,
				description: generateDescription((data = 'Lambrequin enroulable 150mm')),
				nextStep: false,
			},
		];

		fnSuccess(options);
	},
};

const fixation_basse = {
	id: 'fixation_basse',
	type: formInputsTypes?.SELECT_INPUT,
	label: 'Fixation basse',
	name: 'fixation_basse',
	nextStepData: lambrequin,
	nextStep: false,
	options: null,
	fetchData: (tokenUserCurent, currentFormValue, fnSuccess) => {
		getSingleattribute(tokenUserCurent, FIXATION_ATTRIBUTE, ({ value_ids }) => {
			const options = value_ids?.length
				? value_ids?.map((item) => ({
					id: item?.id,
					label: item?.name,
					value: item?.id,
				}))
				: [];
			fnSuccess(options);
		});
	},
};

const fixation_haute = {
	id: 'fixation_haute',
	type: formInputsTypes?.SELECT_INPUT,
	label: 'Fixation haute',
	name: 'fixation_haute',
	nextStepData: fixation_basse,
	nextStep: false,
	options: null,
	fetchData: (tokenUserCurent, currentFormValue, fnSuccess) => {
		getSingleattribute(tokenUserCurent, FIXATION_ATTRIBUTE, ({ value_ids }) => {
			const options = value_ids?.length
				? value_ids?.map((item) => ({
					id: item?.id,
					label: item?.name,
					value: item?.id,
				}))
				: [];
			fnSuccess(options);
		});
	},
};

const cache = {
	id: 'cache',
	type: formInputsTypes?.RADIO_INPUT,
	label: 'Couleur des caches en plastique',
	name: 'cache',
	className: 'col-12',
	nextStep: fixation_haute,
	options: [
		{
			id: 'blanc',
			label: 'blanc',
			value: 'blanc',
			nextStep: fixation_haute,
		},
		{
			id: 'noir',
			label: 'noir',
			value: 'noir',
			nextStep: fixation_haute,
		},
	],
};

const avancee = {
	id: 'avancee',
	type: formInputsTypes?.SELECT_INPUT,
	label: 'Projection ou Avancée de toile',
	name: 'avancee',
	nextStepData: fixation_haute,
	//nextStep: fixation_haute,
	options: [
		{
			id: '150',
			label: '1500 mm',
			value: '1500 mm',
			nextStep: fixation_haute,
		},
		{
			id: '175',
			label: '1750 mm',
			value: '1750 mm',
			nextStep: fixation_haute,
		},
		{
			id: '200',
			label: '2000 mm',
			value: '2000 mm',
			nextStep: fixation_haute,
		},
		{
			id: '225',
			label: '2250 mm',
			value: '2250 mm',
			nextStep: fixation_haute,
		},
		{
			id: '250',
			label: '2500 mm',
			value: '2500 mm',
			nextStep: fixation_haute,
		},
		{
			id: '300',
			label: '3000 mm',
			value: '3000 mm',
			nextStep: fixation_haute,
		},
		{
			id: '350',
			label: '3500 mm',
			value: '3500 mm',
			nextStep: fixation_haute,
		},
	],
};

const projection = {
	id: 'L-PROJ',
	type: formInputsTypes?.INPUT_NUMBER,
	name: 'projection',
	className: 'col-6',
	label: 'Projection(mm)',
	nextStepData: avancee,
	nextStep: false,
};

const largeurToile = {
	id: 'L-TOILE',
	type: formInputsTypes?.INPUT_NUMBER,
	name: 'largeurToile',
	className: 'col-6',
	label: 'Largeur de la toile(mm)',
	nextStepData: avancee,
	nextStep: false,
	validation: (value) => {
		return value > 0;
	},
};

const TypetoileStorebanne = {
	id: 'T_Store',
	type: formInputsTypes?.SELECT_INPUT,
	label: 'Type de la toile',
	name: 'TypetoileStorebanne',
	nextStepData: largeurToile,
	nextStep: false,
	options: [
		{
			id: 'TSB1',
			label: 'Toile de store banne',
			value: 'Toile de store banne',
			nextStep: false,
		},
		{
			id: 'TSBD1',
			label: 'Toile de store bras droits',
			value: 'Toile de store bras droits',
			nextStep: false,
		},
		{
			id: 'TSBD1',
			label: 'Toile store double pente',
			value: 'Toile store double pente',
			nextStep: false,
		},
		{
			id: 'TSBD1',
			label: 'Toile de store à descente verticale',
			value: 'Toile de store à descente verticale',
			nextStep: false,
		},
	],
};

export const ToileStoreBanneData = {
	id: 'T_Store',
	type: formInputsTypes?.RADIO_INPUT,
	label: 'Sélectionner une toile',
	name: 'T_Store',
	nextStepData: TypetoileStorebanne,
	nextStep: false,
	options: null,
	fetchData: (tokenUserCurent, currentFormValue, fnSuccess) => {
		const generateFilter = () => {
			const filters = [['product_tmpl_id', '=', TOILE_STORE_BANNE]];

			return filters;
		};
		searchProduct(tokenUserCurent, generateFilter(currentFormValue), ({ elements }) => {
			const options = elements?.length
				? elements.map((item) => {
					const { product_template_attribute_value_ids } = item;
					let name = item?.display_name + ' ';
					product_template_attribute_value_ids.map((i) => (name += i.name + ' '));
					return {
						id: item?.id,
						label: name,
						value: item?.id,
					};
				})
				: [];
			fnSuccess(options);
		});
	},
};

export default ToileStoreBanneData;
