import axios from 'axios';

const BASIC_API_URL = '/odoo',
	CONFIRM_API_URI = '/fr/devis/signature',
	ACCEPT_API_URI = '/devis/accept',
	REPORT_API_URI = '/fr/devis/report',
	LIMIT = 0;

export const ConfirmDevisMail = (tokenUserCurent, id, fncSuccess, fnError = () => { }) => {
	var config = {
		method: 'get',
		url: `${BASIC_API_URL + CONFIRM_API_URI + '/' + id + '?access_token=' + tokenUserCurent}`,
		//url:"/odoo/en/devis/signature/6615?access_token=2ad92a31-d902-426a-ab39-2260c80a09a5",
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
	};
	console.log('ConfirmDevisMailv', config);
	axios(config)
		.then(function (response) {
			console.log('responseresponse', response);
			fncSuccess(response.data);
		})
		.catch(function (error) {
			console.log('errorerrorerror', error);
			fnError(error);
		});
};

export const SignatureDevisMail = (tokenUserCurent, id, image, fncSuccess, fnError = () => { }) => {
	let data = new FormData();
	data.append('signature', image.toString());
	var config = {
		method: 'put',
		url: `${BASIC_API_URL + ACCEPT_API_URI + '/' + id + '/?access_token=' + tokenUserCurent}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: data,
	};

	axios(config)
		.then(function (response) {
			fncSuccess(response.data);
		})
		.catch(function (error) {
			fnError(error);
		});
};

export const ReportDevisMail = (tokenUserCurent, id) => {
	return BASIC_API_URL + REPORT_API_URI + `/${id}?access_token=${tokenUserCurent}`;
};

export const DownloadReportDevisMail = (tokenUserCurent, id) => {
	return BASIC_API_URL + REPORT_API_URI + `/${id}?access_token=${tokenUserCurent}`;
};
