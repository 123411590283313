import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import FormGroup from '../bootstrap/forms/FormGroup';
import Input from '../bootstrap/forms/Input';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../bootstrap/Modal';
import Card, {
	CardActions,
	CardBody,
	CardFooter,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../bootstrap/Card';
import Button from '../bootstrap/Button';
import { useFormik } from 'formik';
import Textarea from '../bootstrap/forms/Textarea';
import AuthContext from '../../contexts/authContext';
import {
	createDevisLine,
	deleteDevisLine,
	getDevisLinesById,
	updateDevisLine,
} from '../../odooApi/lineDevis';
import DevisLinesContext from '../../contexts/devisLinesContext';
import CardAddProdectLineDevis from './CardAddProdectLineDevis';
import Select from '../bootstrap/forms/Select';
import Option from '../bootstrap/Option';
import { searchAccountTax } from '../../odooApi/accountTax';
import { useTranslation } from 'react-i18next';
import Icon from '../icon/Icon';
import ListGroup, { ListGroupItem } from '../bootstrap/ListGroup';
import Spinner from '../bootstrap/Spinner';
import { searchProduct } from '../../odooApi/product';
import AddProductPopUp from '../Achats/SingleAchat/LinesTable/AddProduct';
import ConfirmationPopup from '../PlanningCalendar/ConfirmationPopup';
import Alert from '../bootstrap/Alert';
import useOutsideClick from '../customComponents/SearchAutoComplete/useOutsideClick';
import StatusPopup from '../PlanningCalendar/StatusPopup';
import ErrorPopup from '../extras/ErrorPopup';

const PopUpUpdateAndCreateLineDevis = ({
	data,
	setisOpen,
	create,
	loadData,
	isDisabled = false,
}) => {
	const { userData } = useContext(AuthContext);
	const { setDevisLines } = useContext(DevisLinesContext);
	const [datas, setDatas] = useState([]);
	const [createProductModalStatus, setCreateProductModalStatus] = useState(false);
	const [spinner, setSpinner] = useState(false);
	const [selectProduct, setSelectProduct] = useState();
	const [taxs, setTaxs] = useState([]);
	const { t } = useTranslation(['translation', 'menu']);
	const [isConfirmed, setIsConfirmed] = useState(false);
	const [error, setError] = useState();
	const inputRef = useRef(null);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		searchAccountTax(
			userData?.password,
			['&', ['type_tax_use', '=', 'sale'], ['active', '=', true]],
			(taxsData) => {
				setTaxs(taxsData?.elements);
				setError(false);
			},
			(error) => {
				setError(error);
			},
		);
		!!inputRef.current && inputRef.current.focus();
	}, []);

	const handelDelete = () => {
		if (userData && userData?.id && userData?.password) {
			deleteDevisLine(
				userData?.password,
				data?.id,
				() => {
					setisOpen(false);
					loadData();
					setError(false);
				},
				(error) => {
					setError(error);
				},
			);
		}
	};
	const handelUpdate = (values) => {
		const newLineDevis = {
			id: values.id,
			name: values?.name,
			product_uom_qty: values?.product_uom_qty,
			price_unit: values?.price_unit,
			discount: values?.discount,
			tax_id: [parseInt(values?.tax_id)],
		};
		if (userData && userData?.id && userData?.password) {
			updateDevisLine(
				userData?.password,
				newLineDevis,
				() => {
					setisOpen(false);
					loadData();
					setError(false);
				},
				(error) => {
					setError(error);
				},
			);
		}
	};
	const handelCreate = (values) => {
		const newLine = {
			order_id: data?.order_id,
			product_id: selectProduct?.id,
			name: values?.name || (create && selectProduct?.display_name) || '',
			product_uom_qty: values?.product_uom_qty,
			price_unit: values?.price_unit || (create && selectProduct?.lst_price) || 0,
			discount: values?.discount,
			tax_id: [parseInt(values?.tax_id || (!!taxs?.length && taxs[0]?.id))],
		};
		if (userData && userData?.id && userData?.password) {
			setIsLoading(true);
			createDevisLine(
				userData?.password,
				newLine,
				() => {
					setisOpen(false);
					loadData();
					setError(false);
					setIsLoading(false);
				},
				(error) => {
					setError(error);
					setIsLoading(false);
				},
			);
		}
	};
	const handleChangeFilter = (filter) => {
		setSpinner(true);
		searchProduct(
			userData?.password,
			filter,
			({ elements }) => {
				setDatas(elements);
				setSpinner(false);
				setError(false);
			},
			(error) => {
				setError(error);
			},
			1,
			10,
		);
	};
	const handleChangeSearch = (e) => {
		setOpenList(true);
		formik?.setFieldValue('searchInput', e?.target?.value);
		if (e?.target?.value == '') {
			setDatas([]);
		} else {
			handleChangeFilter([
				'|',
				['name', 'like', e.target.value],
				'|',
				[
					'name',
					'like',
					e.target.value.charAt(0).toUpperCase() +
					e.target.value.substring(1).toLowerCase(),
				],
				'|',

				['name', 'like', e.target.value.toUpperCase()],
				'|',
				['default_code', 'like', e.target.value],
				'|',
				[
					'default_code',
					'like',
					e.target.value.charAt(0).toUpperCase() +
					e.target.value.substring(1).toLowerCase(),
				],
				['default_code', 'like', e.target.value.toUpperCase()],
			]);
		}
	};
	const handleSelectedProduct = (item) => {
		setSelectProduct(item);
		setOpenList(false);
		setDatas([]);
	};

	useEffect(() => {
		!!selectProduct &&
			formik.setValues({
				id: selectProduct?.id,
				display_name: selectProduct?.display_name,
				name: selectProduct?.name + " " + selectProduct?.description_sale,
				price_unit: selectProduct?.lst_price,
				product_uom_qty: 1,
				discount: 0,
				searchInput: '',
				tax_id: selectProduct?.taxes_id[0].id,
			});
	}, [selectProduct]);

	const formik = useFormik({
		initialValues: {
			id: data?.id,
			display_name: data?.display_name || '',
			name: data?.name,
			price_unit: data?.price_unit || 0,
			product_uom_qty: data?.product_uom_qty || 1,
			discount: data?.discount?.toFixed(2) || 0,
			price_subtotal: data?.price_subtotal,
			nom_produit: data?.nom_produit,
			tax_id: !!data?.tax_id?.length ? data?.tax_id[0] : null,
			searchInput: '',
		},

		onSubmit: (values) => {
			if (create) {
				handelCreate(values);
			} else {
				handelUpdate(values);
			}
		},
	});
	const calculSubTotal = () => {
		const tvaPercentage =
			!!taxs?.length &&
			taxs?.filter(
				(tax) => tax.id == (formik?.values?.tax_id || (!!taxs?.length && taxs[0]?.id)),
			)[0]?.amount;
		const discountPrrice =
			((formik?.values?.product_uom_qty *
				(formik?.values?.price_unit || (create && selectProduct?.lst_price) || 0)) /
				100) *
			(100 - formik?.values?.discount);

		return ((discountPrrice / 100) * (100 + (tvaPercentage || 0))).toFixed(2) || 0;
	};
	const [openList, setOpenList] = useState(false);
	const handleClickOutside = () => {
		setOpenList(false);
	};
	const ref = useOutsideClick(handleClickOutside);

	return (
		<Modal setIsOpen={() => { }} isOpen={true} isScrollable classNameMd='nv-3'>
			<ModalHeader
				className='px-4 pb-0'
				setIsOpen={() => {
					setisOpen(false);
				}}></ModalHeader>
			<ModalBody className='pt-0 h-fluid-min-100'>
				<Card shadow='none'>
					<CardHeader className='pt-0'>
						<CardLabel icon='Info' iconColor='primary'>
							<CardTitle>
								{create ? 'Ajouter une nouvelle ligne' : t('Line Details')}
							</CardTitle>
						</CardLabel>
						<CardActions>
							{!data?.id && (
								<Button
									icon='AddCircleOutline'
									color='success'
									isLight
									onClick={() => {
										setCreateProductModalStatus(true);
									}}>
									Créer un produit
								</Button>
							)}
						</CardActions>
					</CardHeader>
					<CardBody>
						<div className='row g-4'>
							{!data?.id && (
								<div ref={ref} className='row justify-content-end mt-2'>
									<FormGroup
										className='autocomplete'
									//id='name'
									>
										<div className='d-flex align-items-center bg-l10-brand-two rounded-2 shadow-sm'>
											<label
												className='border-0 ms-2 me-0'
												htmlFor='searchInput'>
												<Icon icon='Search' size='2x' color='primary' />
											</label>

											<Input
												ref={inputRef}
												value={formik.values.searchInput}
												onChange={handleChangeSearch}
												placeholder='Rechercher...'
												type='text'
												autoComplete='off'
												className='border-0 bg-transparent'
											/>
										</div>

										{openList && (
											<div
												id='myInputautocomplete-list'
												className='autocomplete-items position-absolute'>
												<div className='body'>
													{spinner ? (
														<div>
															<Spinner isSmall isGrow />{' '}
															<Spinner isSmall isGrow />{' '}
															<Spinner isSmall isGrow />
														</div>
													) : (
														!!datas?.length &&
														datas?.map((item) => (
															<div
																key={item?.id}
																onClick={() => {
																	handleSelectedProduct(item);
																}}>
																<Icon
																	color={'primary'}
																	icon={
																		'ProductionQuantityLimits'
																	}
																	size='lg'
																	className='me-2'
																/>
																{item?.name}
															</div>
														))
													)}
												</div>
											</div>
										)}
									</FormGroup>
								</div>
							)}
							{data?.id && (
								<FormGroup className='col-12' id='ref' label={t('ref')}>
									<Input
										onChange={formik.handleChange}
										value={data?.id}
										disabled
									/>
								</FormGroup>
							)}
							{(!!selectProduct ||
								!!data?.id) && (
									<>
										{' '}
										<FormGroup
											className='col-12'
											id='name'
											label={t('Description')}>
											<Textarea
												onChange={formik.handleChange}
												value={formik?.values?.name}
												rows={6}
												name='name'
											/>
										</FormGroup>
										<FormGroup
											className='col-6'
											id='price_unit'
											label={t('Price unite')}>
											<Input
												type='number'
												onChange={formik.handleChange}
												value={formik?.values?.price_unit}
												name='price_unit'
												min={0}
											/>
										</FormGroup>
										<FormGroup
											className='col-6'
											id='description'
											label={t('Quantity')}>
											<Input
												type='number'
												onChange={formik.handleChange}
												value={formik?.values?.product_uom_qty}
												name='product_uom_qty'
												min={1}
											/>
										</FormGroup>
										<FormGroup
											className='col-6'
											id='description'
											label={t('Disc')}>
											<Input
												type='number'
												onChange={formik.handleChange}
												value={formik?.values?.discount}
												min={0}
												name='discount'
											/>
										</FormGroup>
										<FormGroup
											className='col-6'
											id='description'
											label={t('Taxe')}>
											<Select
												ariaLabel='Board select'
												placeholder={t('Select group')}
												onChange={formik.handleChange}
												value={formik?.values?.tax_id}
												name='tax_id'>
												{!!taxs?.length &&
													taxs?.map((tax) => {
														return (
															<Option key={tax?.id} value={tax?.id}>
																{tax?.name}
															</Option>
														);
													})}
											</Select>
										</FormGroup>
										<FormGroup
											className='col-12'
											id='description'
											label={t('Sous-total')}>
											<Input
												value={
													calculSubTotal() > 0
														? calculSubTotal().toLocaleString('fr-FR', {
															style: 'currency',
															currency: 'EUR',
														})
														: '0'.toLocaleString('fr-FR', {
															style: 'currency',
															currency: 'EUR',
														})
												}
												disabled
											/>
										</FormGroup>
									</>
								)}
						</div>
					</CardBody>

				</Card>
			</ModalBody>
			{!isDisabled && (
				<ModalFooter>
					{(!!selectProduct || !!data?.id) && (
						<Button
							color='primary'
							className='w-100'
							type='submit'
							onClick={formik.handleSubmit}
							disabled={isLoading}>
							{isLoading && <Spinner isSmall inButton />}
							{isLoading ? 'En cours' : 'Sauvegarder'}
						</Button>
					)}

					{!create && (
						<Button
							color='danger'
							className='w-100'
							onClick={() => setIsConfirmed(true)}>
							{t('Delete')}
						</Button>
					)}
					{isConfirmed && (
						<ConfirmationPopup
							handleConfirm={handelDelete}
							setOpen={setIsConfirmed}
							title={'Supprimer la ligne de devis ?'}
						/>
					)}
				</ModalFooter>
			)}
			{/* {!isDisabled && (
				<ModalFooter className='px-4 pb-4'>
					{create ? (
						<Button
							color='primary'
							className='w-100'
							type='submit'
							onClick={formik.handleSubmit}
							disabled={!selectProduct?.id}>
							{t('Create')}
						</Button>
					) : (
						<Button
							color='primary'
							className='w-100'
							type='submit'
							onClick={formik.handleSubmit}>
							{t('Save')}
						</Button>
					)}

					{!create && (
						<Button
							color='danger'
							className='w-100'
							onClick={() => setIsConfirmed(true)}>
							{t('Delete')}
						</Button>
					)}
					{isConfirmed && (
						<ConfirmationPopup
							handleConfirm={handelDelete}
							setOpen={setIsConfirmed}
							title={'Supprimer la ligne de devis ?'}
						/>
					)}
				</ModalFooter>
			)} */}

			{!!error && (
				<ErrorPopup
					error={error}
					setOpen={setError}
				/>
			)}
			{createProductModalStatus && (
				<AddProductPopUp
					setCreateProductModalStatus={setCreateProductModalStatus}
					handleSelectedProduct={handleSelectedProduct}
				/>
			)}
		</Modal>
	);
};

PopUpUpdateAndCreateLineDevis.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	// @ts-ignore
	data: PropTypes.object.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	setEditModalLineStatus: PropTypes.func.isRequired,
	create: PropTypes.bool,
};

export default PopUpUpdateAndCreateLineDevis;
