import React from "react";
import Input from "../bootstrap/forms/Input";
import Modal, { ModalBody, ModalHeader } from "../bootstrap/Modal";
import Icon from "../icon/Icon";
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
    

const SearchPopUp = ({ setSearchModalStatus, filterResult, valueInput, curentInput, handelChange, handelSelect, children }) => {
    
    const { t } = useTranslation(['translation', 'menu']);
    const handelSelectLocal = (value) => {
        handelSelect(value);
        setSearchModalStatus();
    }

    return (
        <Modal
            setIsOpen={() => { }}
            isOpen={true}
            isStaticBackdrop
            isScrollable
            data-tour='search-modal'
            classNameMd="nv-3"
        >
            <ModalHeader setIsOpen={setSearchModalStatus}>
                <label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
                    <Icon icon='Search' size='2x' color='primary' />
                </label>
                <Input

                    name={curentInput?.inputName || "search"}
                    className='border-0 shadow-none bg-transparent'
                    placeholder={t('Search')}
                    value={valueInput[curentInput?.inputName]}
                    onChange={handelChange}
                    autoComplete="off"
                />
            </ModalHeader>
            <ModalBody>
                <table className='table table-hover table-modern caption-top mb-0'>

                    <caption>{t('Results')}: {filterResult[curentInput?.inputName]?.length}</caption>
                    <thead className='position-sticky' style={{ top: -13 }}>

                        <tr>
                            <th scope='col'>{curentInput?.inputLabel ||t('Results')}</th>
                            {
                                children &&
                                <th scope='col'>
                                    {children}
                                </th>
                            }

                        </tr>
                    </thead>
                    <tbody>
                        {filterResult[curentInput?.inputName]?.length ? (
                            filterResult[curentInput?.inputName]?.map((item) => (
                                <tr
                                    key={item?.id}
                                    className='cursor-pointer'
                                    onClick={() => {
                                        handelSelectLocal({ value: { [curentInput?.inputName]: item?.name || item?.display_name }, selected: { [curentInput?.inputName]: item } });
                                    }}
                                >
                                    <td className="col">
                                        {/* {item.id && (
                                            <Icon
                                                icon={item.id}
                                                size='lg'
                                                className='me-2'
                                                color='primary'
                                            />
                                        )} */}
                                        <span >
                                            {item?.id}
                                        </span>

                                    </td>
                                    <td className="col">
                                        <span >
                                            {item?.name || item?.display_name}
                                        </span>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr className='table-active'>
                                <td>{t('No result found for query')} </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </ModalBody>
        </Modal>
    )
};

SearchPopUp.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    // @ts-ignore
    setSearchModalStatus: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    filterResult: PropTypes.object.isRequired,
    valueInput: PropTypes.object,
    curentInput: PropTypes.object,
    handelChange: PropTypes.func,
    handelSelect: PropTypes.func,
    children: PropTypes.any
    /* selectedValue:PropTypes.object */
};

export default SearchPopUp;