import formInputsTypes from '../../../simulatorConstant';
import TypeMesureLarg from './manuel';
import TypeMesureLargMot from './motorise';

export const MIN_WIDTH = 0;
export const PRODUCT_ID_AXE_MANUEL_TRADI = 202;
export const PRODUCT_ID_AXE_MANUEL = 202;
export const PRODUCT_ID_AXE_MOTORISE_TRADI = 118;
export const PRODUCT_ID_EQUERRE = 198;
export const PRODUCT_ID_JAMBE = 199;
export const PRODUCT_ID_COUSSINET = 200;
export const PRODUCT_ID_PLAQUE = 201;
export const CATEG_ID_TANDEM = 100;
export const CATEG_ID_AXE = 20;

const typeAxeVoletRoulant = {
	id: 'VR-TR-AXE',
	type: formInputsTypes?.RADIO_INPUT,
	label: "Selectionez Un Type d'Axe",
	name: 'typeAxe',
	nextStep: false,
	options: [
		{
			id: 'VR-TR-AXE-MTR',
			label: 'Axe Motorisé',
			value: 'axe motorisé volet roulant tradi',
			nextStep: TypeMesureLargMot,
		},
		{
			id: 'VR-TR-AXE-MN',
			label: 'Axe Manuel',
			value: 'axe manuel volet roulant tradi',
			nextStep: TypeMesureLarg,
		},
	],
};

export default typeAxeVoletRoulant;
