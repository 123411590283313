import axios from 'axios';

const BASIC_API_URL = '/odoo',
	API_URL = process.env.REACT_APP_BASIC_API_URL,
	API_URL_WEB_ADMIN = process.env.REACT_APP_API_URL_WEB_ADMIN,
	SEARCH_METHOD_API = process.env.REACT_APP_SEARCH_METHOD_API,
	VOITURE_API_URI = '/fleet.vehicle',
	CONTROLE_TECHNIQUE_API_URI = '/fleet.vehicle.control',
	ASSURANCE_API_URI = '/fleet.vehicle.assurance',
	VIDANGE_API_URI = '/fleet.vehicle.vidange',
	ENTRETIEN_API_URI = '/fleet.vehicle.entretien',
	LIMIT = 0;

export const searchVoiture = (
	tokenUserCurent,
	filters,
	fnSuccess,
	fnError = () => {},
	page = 1,
	limit = LIMIT,
) => {
	var dataFilter = JSON.stringify({
		kwargs: {
			domain: filters,
			limit: limit,
			page: page,
		},
	});

	var config = {
		method: 'patch',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + VOITURE_API_URI + SEARCH_METHOD_API}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: dataFilter,
	};

	axios(config)
		.then(function (response) {
			fnSuccess(response?.data);
		})
		.catch(function (error) {
			fnError(error);
		});
};

export const getAllVoiture = (tokenUserCurent, fnSuccess, fnError = () => {}) => {
	var config = {
		method: 'get',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + VOITURE_API_URI}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
	};

	axios(config)
		.then(function (response) {
			fnSuccess(response?.data?.elements);
		})
		.catch(function (error) {
			console.log(error);
			fnError(error);
		});
};

export const createVoiture = (tokenUserCurent, newVoiture, fntSucces) => {
	var data = JSON.stringify(newVoiture);

	var config = {
		method: 'post',
		url: BASIC_API_URL + API_URL_WEB_ADMIN + VOITURE_API_URI,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: data,
	};

	axios(config)
		.then(function (response) {
			fntSucces(response.data);
		})
		.catch(function (error) {
			console.log(error);
		});
};

export const updateVoiture = (tokenUserCurent, idVoiture, body, fnSuccess) => {
	var data = JSON.stringify(body);
	//console.log("data",data)
	var config = {
		method: 'put',
		url: BASIC_API_URL + API_URL_WEB_ADMIN + VOITURE_API_URI + '/' + idVoiture,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: data,
	};

	axios(config)
		.then(function (response) {
			fnSuccess(response.data);
		})
		.catch(function (error) {
			console.log(error);
		});
};

export const imageVoiture = (tokenUserCurent, idVoiture) => {
	return (
		BASIC_API_URL +
		'/api/digital/image' +
		VOITURE_API_URI +
		'/' +
		idVoiture +
		'?token=' +
		tokenUserCurent
	);
};

export const getSingleVoitureId = (tokenUserCurent, voitureId, fnSucces) => {
	const config = {
		method: 'get',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + VOITURE_API_URI + '/' + voitureId}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
	};

	axios(config)
		.then(function (result) {
			fnSucces(result?.data);
		})
		.catch(function (error) {
			console.log(error);
		});
};

export const uploadImageVoiture = (tokenUserCurent, idVoiture, file, fnc) => {
	let data = new FormData();
	data.append('image_128', file);

	let config = {
		method: 'put',
		url:
			BASIC_API_URL +
			API_URL_WEB_ADMIN +
			'/upload' +
			VOITURE_API_URI +
			'/' +
			idVoiture +
			'?token=' +
			tokenUserCurent,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': file?.type,
		},
		data: data,
	};

	axios
		.request(config)
		.then((response) => {
			fnc(response);
		})
		.catch((error) => {
			console.log('error', error);
		});
};

export const uploadCarteGriseVoiture = (tokenUserCurent, idVoiture, file, fnc) => {
	let data = new FormData();

	data.append('carte_Grise', file);

	let config = {
		method: 'put',
		url:
			BASIC_API_URL +
			API_URL_WEB_ADMIN +
			'/upload_attachments' +
			VOITURE_API_URI +
			'/' +
			idVoiture +
			'?token=' +
			tokenUserCurent,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': file?.type,
		},
		data: data,
	};

	axios
		.request(config)
		.then((response) => {
			fnc(response.data);
		})
		.catch((error) => {
			console.log('error', error);
		});
};

export const uploadAttachementsVoiture = (tokenUserCurent, idVoiture, attachements, fnc) => {
	let data = new FormData();
	//data.append('image_1920', file);
	Object.keys(attachements)?.map((key) => {
		data.append('attachment', attachements[key]);
	});
	let config = {
		method: 'put',
		url:
			BASIC_API_URL +
			API_URL_WEB_ADMIN +
			'/upload_attachments' +
			VOITURE_API_URI +
			'/' +
			idVoiture +
			'?token=' +
			tokenUserCurent,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
		},
		data: data,
	};

	axios
		.request(config)
		.then((response) => {
			fnc(response);
		})
		.catch((error) => {
			console.log('error', error);
		});
};

export const uploadAttachementsControleTechnique = (
	tokenUserCurent,
	idcontrole,
	attachements,
	fnc,
) => {
	let data = new FormData();
	//data.append('image_1920', file);

	data.append('attachment', attachements);

	let config = {
		method: 'put',
		url:
			BASIC_API_URL +
			API_URL_WEB_ADMIN +
			'/upload_attachments' +
			CONTROLE_TECHNIQUE_API_URI +
			'/' +
			idcontrole +
			'?token=' +
			tokenUserCurent,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
		},
		data: data,
	};

	axios
		.request(config)
		.then((response) => {
			fnc(response?.data);
		})
		.catch((error) => {
			console.log('error', error);
		});
};
export const updateControleTechnique = (tokenUserCurent, idcontrole, body, fnSuccess) => {
	var data = JSON.stringify(body);
	//console.log("data",data)
	var config = {
		method: 'put',
		url: BASIC_API_URL + API_URL_WEB_ADMIN + CONTROLE_TECHNIQUE_API_URI + '/' + idcontrole,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: data,
	};
	axios(config)
		.then(function (response) {
			fnSuccess(response.data);
		})
		.catch(function (error) {
			console.log(error);
		});
};

export const uploadAttachementsAssurance = (tokenUserCurent, idAssurance, attachements, fnc) => {
	let data = new FormData();
	//data.append('image_1920', file);

	data.append('assurance', attachements);

	let config = {
		method: 'put',
		url:
			BASIC_API_URL +
			API_URL_WEB_ADMIN +
			'/upload_attachments' +
			ASSURANCE_API_URI +
			'/' +
			idAssurance +
			'?token=' +
			tokenUserCurent,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
		},
		data: data,
	};

	axios
		.request(config)
		.then((response) => {
			fnc(response?.data);
		})
		.catch((error) => {
			console.log('error', error);
		});
};

export const uploadAttachementsEntretien = (tokenUserCurent, idEntretien, attachements, fnc) => {
	let data = new FormData();
	//data.append('image_1920', file);

	data.append('facture', attachements);

	let config = {
		method: 'put',
		url:
			BASIC_API_URL +
			API_URL_WEB_ADMIN +
			'/upload_attachments' +
			ENTRETIEN_API_URI +
			'/' +
			idEntretien +
			'?token=' +
			tokenUserCurent,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
		},
		data: data,
	};

	axios
		.request(config)
		.then((response) => {
			fnc(response?.data);
		})
		.catch((error) => {
			console.log('error', error);
		});
};

export const uploadAttachementsVidange = (tokenUserCurent, idVidange, attachements, fnc) => {
	let data = new FormData();
	//data.append('image_1920', file);

	data.append('facture_vidange', attachements);

	let config = {
		method: 'put',
		url:
			BASIC_API_URL +
			API_URL_WEB_ADMIN +
			'/upload_attachments' +
			VIDANGE_API_URI +
			'/' +
			idVidange +
			'?token=' +
			tokenUserCurent,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
		},
		data: data,
	};

	axios
		.request(config)
		.then((response) => {
			fnc(response?.data);
		})
		.catch((error) => {
			console.log('error', error);
		});
};
export const createControleTechnique = (tokenUserCurent, newControleTechnique, fntSucces) => {
	var data = JSON.stringify(newControleTechnique);

	var config = {
		method: 'post',
		url: BASIC_API_URL + API_URL_WEB_ADMIN + CONTROLE_TECHNIQUE_API_URI,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: data,
	};

	axios(config)
		.then(function (response) {
			fntSucces(response?.data);
		})
		.catch(function (error) {
			console.log(error);
		});
};

export const createAssurance = (tokenUserCurent, newAssurance, fntSucces) => {
	var data = JSON.stringify(newAssurance);

	var config = {
		method: 'post',
		url: BASIC_API_URL + API_URL_WEB_ADMIN + ASSURANCE_API_URI,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: data,
	};

	axios(config)
		.then(function (response) {
			fntSucces(response?.data);
		})
		.catch(function (error) {
			console.log(error);
		});
};

export const createEntretien = (tokenUserCurent, newEntretien, fntSucces) => {
	var data = JSON.stringify(newEntretien);

	var config = {
		method: 'post',
		url: BASIC_API_URL + API_URL_WEB_ADMIN + ENTRETIEN_API_URI,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: data,
	};

	axios(config)
		.then(function (response) {
			fntSucces(response?.data);
		})
		.catch(function (error) {
			console.log(error);
		});
};

export const createVidange = (tokenUserCurent, newVidange, fntSucces) => {
	var data = JSON.stringify(newVidange);

	var config = {
		method: 'post',
		url: BASIC_API_URL + API_URL_WEB_ADMIN + VIDANGE_API_URI,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: data,
	};

	axios(config)
		.then(function (response) {
			fntSucces(response?.data);
		})
		.catch(function (error) {
			console.log(error);
		});
};

export const updateAssurance = (tokenUserCurent, idAssurance, body, fnSuccess) => {
	var data = JSON.stringify(body);
	//console.log("data",data)
	var config = {
		method: 'put',
		url: BASIC_API_URL + API_URL_WEB_ADMIN + ASSURANCE_API_URI + '/' + idAssurance,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: data,
	};
	axios(config)
		.then(function (response) {
			fnSuccess(response.data);
		})
		.catch(function (error) {
			console.log(error);
		});
};
export const updateEntretien = (tokenUserCurent, idEntretien, body, fnSuccess) => {
	var data = JSON.stringify(body);
	//console.log("data",data)
	var config = {
		method: 'put',
		url: BASIC_API_URL + API_URL_WEB_ADMIN + ENTRETIEN_API_URI + '/' + idEntretien,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: data,
	};
	axios(config)
		.then(function (response) {
			fnSuccess(response.data);
		})
		.catch(function (error) {
			console.log(error);
		});
};

export const updateVidange = (tokenUserCurent, idVidange, body, fnSuccess) => {
	var data = JSON.stringify(body);
	//console.log("data",data)
	var config = {
		method: 'put',
		url: BASIC_API_URL + API_URL_WEB_ADMIN + VIDANGE_API_URI + '/' + idVidange,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: data,
	};
	axios(config)
		.then(function (response) {
			fnSuccess(response.data);
		})
		.catch(function (error) {
			console.log(error);
		});
};

export const getAssuranceById = (tokenUserCurent, voitureId, fnSucces) => {
	const bodyData = JSON.stringify({
		kwargs: {
			domain: [['voiture', '=', voitureId]],
		},
	});
	var config = {
		method: 'patch',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + ASSURANCE_API_URI + SEARCH_METHOD_API}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: bodyData,
	};

	axios(config)
		.then(function (response) {
			fnSucces(response?.data);
		})
		.catch(function (error) {
			console.log(error);
		});
};

export const getVidangeById = (tokenUserCurent, voitureId, fnSucces) => {
	const bodyData = JSON.stringify({
		kwargs: {
			domain: [['voiture', '=', voitureId]],
		},
	});
	var config = {
		method: 'patch',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + VIDANGE_API_URI + SEARCH_METHOD_API}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: bodyData,
	};

	axios(config)
		.then(function (response) {
			fnSucces(response?.data);
		})
		.catch(function (error) {
			console.log(error);
		});
};

export const getEntretienById = (tokenUserCurent, voitureId, fnSucces) => {
	const bodyData = JSON.stringify({
		kwargs: {
			domain: [['voiture', '=', voitureId]],
		},
	});
	var config = {
		method: 'patch',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + ENTRETIEN_API_URI + SEARCH_METHOD_API}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: bodyData,
	};

	axios(config)
		.then(function (response) {
			fnSucces(response?.data);
		})
		.catch(function (error) {
			console.log(error);
		});
};

export const getControlById = (tokenUserCurent, voitureId, fnSucces) => {
	const bodyData = JSON.stringify({
		kwargs: {
			domain: [['voiture', '=', voitureId]],
		},
	});
	var config = {
		method: 'patch',
		url: `${
			BASIC_API_URL + API_URL_WEB_ADMIN + CONTROLE_TECHNIQUE_API_URI + SEARCH_METHOD_API
		}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: bodyData,
	};

	axios(config)
		.then(function (response) {
			fnSucces(response?.data);
		})
		.catch(function (error) {
			console.log(error);
		});
};

export const getAttachment = (tokenUserCurent, idAttachment) => {
	return (
		BASIC_API_URL + API_URL_WEB_ADMIN + '/common/' + idAttachment + '?token=' + tokenUserCurent
	);
};

export const deleteVoiture = async (tokenUserCurent, idLine, fnSucces) => {
	const config = {
		method: 'delete',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + VOITURE_API_URI + '/' + idLine}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
	};

	axios(config)
		.then(function () {
			fnSucces();
		})
		.catch(function (error) {
			console.log(error);
		});
};
