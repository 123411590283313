import { useFormik } from 'formik';
import { useContext, useEffect, useRef, useState } from 'react';
import { capitalizeWords } from '../../common/function/functionsUtils';
import AuthContext from '../../contexts/authContext';
import { AutoComplete, getPlaceDetails } from '../../googleApi/placeAutoComplete';
import {
	createClientPartner,
	searchClientPartner,
	updateSinglePartner,
} from '../../odooApi/clientPartner';
import { searchCountryState } from '../../odooApi/country';
import StatusPopup from '../PlanningCalendar/StatusPopup';
import Button from '../bootstrap/Button';
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from '../bootstrap/Card';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../bootstrap/Modal';
import Spinner from '../bootstrap/Spinner';
import Checks from '../bootstrap/forms/Checks';
import FormGroup from '../bootstrap/forms/FormGroup';
import Input from '../bootstrap/forms/Input';
import Label from '../bootstrap/forms/Label';
import SearchAutoComplete from '../customComponents/SearchAutoComplete';
import useOutsideClick from '../customComponents/SearchAutoComplete/useOutsideClick';
import ErrorPopup from '../extras/ErrorPopup';
import Icon from '../icon/Icon';

const SearchClient = ({
	data,
	setCreateClientModalStatus,
	handelSelect,
	onChange,
	curentInput,
	handleUpdate,
	newClient = false,
}) => {
	const [resultSearch, setResultSearch] = useState([]);
	const [selectItem, setSelectItem] = useState();
	const { userData } = useContext(AuthContext);
	const [spinner, setSpinner] = useState(false);
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(false);
	const [openList, setOpenList] = useState(false);
	const [clientIsLoading, setClientIsLoading] = useState(false);
	const [clientData, setClientData] = useState(data);
	const [filterResult, setfilterResult] = useState({});
	const inputRef = useRef(null);
	const [clientValues, setClientValues] = useState({
		name: data?.name || '',
		fullName: data?.name || '',
		phone: data?.phone || '',
		email: data?.email || '',
		mobile: data?.mobile || '',
		adrsFact: (data?.street || '') + ' ' + (data?.zip || '') + ' ' + (data?.city || ''),
		adrsLivr: '',
		street: data?.street || '',
		city: data?.city || '',
		zip: data?.zip || '',
		x_code: data?.x_code || '',
		function: data?.function || '',
		adrLivraison: data?.partner_delivery || '',
	});
	const [isFournisseur, setIsFournisseur] = useState(false);
	const [resultSearchLivraison, setResultSearchLivraison] = useState([]);

	const handleClickOutside = () => {
		setOpenList(false);
	};
	const ref = useOutsideClick(handleClickOutside);
	console.log(
		'selectItem',
		selectItem?.geometry?.location?.lat,
		selectItem?.geometry?.location?.lng,
	);
	const createClient = (values) => {
		if (userData && userData?.id && userData?.password) {
			setSpinner(true);
			setSuccess(false);
			setError(false);
			if (
				!!selectItem?.address_components?.filter((item) =>
					item?.types.includes('administrative_area_level_1'),
				)[0]?.long_name
			) {
				searchCountryState(
					userData?.password,
					[
						[
							'name',
							'=',
							selectItem?.address_components?.filter((item) =>
								item?.types.includes('administrative_area_level_1'),
							)[0]?.long_name,
						],
					],
					({ elements }) => {
						const newUser = {
							name: values?.fullName,
							phone: values?.phone || '',
							mobile: values?.mobile || '',
							email: values?.email || '',
							city: values?.city || '',
							zip: values?.zip || '',
							street: values?.street || '',
							street2: values?.street2 || '',
							places: selectItem?.url,
							country_id: 75,
							state_id: (!!elements?.length && elements[0]?.id) || '',
							x_code: values?.x_code || '',
							partner_latitude: selectItem?.geometry?.location?.lat || 0.0,
							partner_longitude: selectItem?.geometry?.location?.lng,
						};

						createClientPartner(
							userData?.password,
							newUser,
							(data) => {
								handelSelect({
									selected: { client: data },
									value: { client: data?.name },
								});
								setCreateClientModalStatus(false);
								setSpinner(false);
								setSuccess('Contact est créer avec success.');
								setError(false);
							},
							(error) => {
								setSpinner(false);
								setSuccess(false);
								setError(error);
							},
						);
					},
					(error) => {
						setSpinner(false);
						setSuccess(false);
						setError(error);
					},
				);
			} else {
				const newUser = {
					name: values?.fullName,
					phone: values?.phone || '',
					mobile: values?.mobile || '',
					email: values?.email || '',
					city: values?.city || '',
					zip: values?.zip || '',
					street: values?.street || '',
					street2: values?.street2 || '',
					places: selectItem?.url,
					country_id: 75,
					x_code: values?.x_code || '',
					partner_latitude: selectItem?.geometry?.location?.lat || 0.0,
					partner_longitude: selectItem?.geometry?.location?.lng,
				};

				createClientPartner(
					userData?.password,
					newUser,
					(data) => {
						handelSelect({
							selected: { client: data },
							value: { client: data?.name },
						});
						setCreateClientModalStatus(false);
						setSpinner(false);
						setSuccess('Contact est créer avec success.');
						setError(false);
					},
					(error) => {
						setSpinner(false);
						setSuccess(false);
						setError(error);
					},
				);
			}
		}
	};

	const formik = useFormik({
		initialValues: {
			name: data?.name || '',
			fullName: data?.name || '',
			phone: data?.phone || '',
			email: data?.email || '',
			mobile: data?.mobile || '',
			adrsFact: (data?.street || '') + ' ' + (data?.zip || '') + ' ' + (data?.city || ''),
			adrsLivr: '',
			street: data?.street || '',
			city: data?.city || '',
			zip: data?.zip || '',
			x_code: data?.x_code || '',
			function: data?.function || '',
			adrLivraison: data?.partner_delivery || '',
		},
		validate: (values) => {
			const errors = {};

			if (!values.fullName) {
				errors.fullName = 'Obligatoire !';
			}
			if (!!values.email) {
				if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
					errors.email = 'Adresse e-mail invalide';
				}
			}

			if (!values.phone) {
				errors.phone = 'Obligatoire !';
			}
			if (!values.adrsFact) {
				errors.adrsFact = 'Obligatoire !';
			}
			if (!values.street) {
				errors.street = 'Obligatoire !';
			}
			if (!values.zip) {
				errors.zip = 'Obligatoire !';
			}
			if (!values.city) {
				errors.city = 'Obligatoire !';
			}

			return errors;
		},
		onSubmit: (values) => {
			createClient(values);
		},
	});

	const handeklSelectAdrLivraison = (item) => {
		formik.setFieldValue('adrLivraison', item?.label);
	};

	const handelSelectClient = (item) => {
		setOpenList(false);

		// setSelectedClient(item);
		formik?.setFieldValue('name', item.name);
		formik?.setFieldValue('fullName', item.name);
		formik?.setFieldValue('email', item?.email);
		formik?.setFieldValue('phone', item.phone);
		formik?.setFieldValue('mobile', item.mobile);
		formik?.setFieldValue('x_code', item?.x_code);
		formik?.setFieldValue('adrLivraison', item?.partner_delivery);
		formik.setFieldValue(
			'adrsFact',
			item?.street +
				' ' +
				item?.zip +
				' ' +
				item?.city +
				' ' +
				item?.country_id?.display_name,
		);
		formik.setFieldValue(
			'adrsFact',
			item?.street +
				' ' +
				item?.zip +
				' ' +
				item?.city +
				' ' +
				item?.country_id?.display_name,
		);
		formik.setFieldValue('zip', item?.zip);
		formik.setFieldValue('city', item?.city);
		formik.setFieldValue('street', item?.street);
		setfilterResult([]);
		setClientData(item);
		setClientValues({
			name: item.name,
			fullName: item.name,
			email: item?.email,
			phone: item.phone,
			mobile: item.mobile,
			x_code: item?.x_code,
			adrsFact: (data?.street || '') + ' ' + (data?.zip || '') + ' ' + (data?.city || ''),

			zip: item?.zip,
			city: item?.city,
			street: item?.street,
			adrLivraison: item?.partner_delivery,
		}); // Make a copy of initial values
	};
	const handleChangeClient = (e, signal) => {
		// handelSelect({
		// 	selected: { client: {} },
		// 	value: { client: e },
		// });
		formik.handleChange(e);
		setOpenList(true);
		if (e == '') {
			setfilterResult([]);
			formik?.setFieldValue('fullName', '');
			formik?.setFieldValue('mobile', '');
			formik.setFieldValue('adrsFact', '');
			formik.setFieldValue('phone', '');
			formik.setFieldValue('email', '');
			formik?.setFieldValue('x_code', '');
			formik?.setFieldValue('adrLivraison', '');
			setClientData({});
		} else {
			if (userData && userData?.id && userData?.password) {
				setClientIsLoading(true);
				searchClientPartner(
					userData?.password,

					[
						'|',
						['name', 'like', e],
						'|',
						['name', 'like', capitalizeWords(e)],
						'|',
						['name', 'like', e.charAt(0).toUpperCase() + e.substring(1).toLowerCase()],
						'|',
						['name', 'like', e.toUpperCase()],
						'|',
						['email', 'like', e],
						'|',
						['mobile', 'like', e],
						['phone', 'like', e],
					],
					({ elements }) => {
						setfilterResult(elements);
						setClientIsLoading(false);
					},
					(error) => {
						!(error?.code == 'ERR_CANCELED') && setError(error);
						setClientIsLoading(false);
					},
					0,
					0,
					signal,
				);
			}
		}
		// AutoComplete(e, (result) => {
		// 	const newLocations = [];
		// 	result?.predictions?.map((item) => {
		// 		newLocations.push({
		// 			id: item?.place_id,
		// 			label: item?.description,
		// 			icon: '',
		// 		});
		// 	});
		// 	setResultSearch(newLocations);
		// });
	};
	const handleChange = (e) => {
		formik.handleChange(e);
		AutoComplete(
			e.target.value,
			(result) => {
				const newLocations = [];
				result?.predictions?.map((item) => {
					newLocations.push({
						id: item?.place_id,
						label: item?.description,
						icon: '',
					});
				});
				setResultSearch(newLocations);
			},
			(error) => {
				setError(error);
			},
		);
	};

	const handelSelectItem = (item) => {
		formik.setFieldValue('adrsFact', item?.label);
		getPlaceDetails(item?.id, (result) => {
			setSelectItem(result?.result);
			formik.setFieldValue(
				'city',
				result?.result?.address_components.filter((item) =>
					item?.types.includes('locality'),
				)[0]?.long_name ||
					result?.result?.address_components.filter((item) =>
						item?.types.includes('administrative_area_level_2'),
					)[0]?.long_name ||
					'',
			);
			formik.setFieldValue(
				'zip',
				result?.result?.address_components.filter((item) =>
					item?.types.includes('postal_code'),
				)[0]?.long_name || '',
			);
			formik.setFieldValue(
				'street',
				(result?.result?.address_components.filter((item) =>
					item?.types.includes('street_number'),
				)[0]?.long_name || '') +
					' ' +
					(result?.result?.address_components.filter((item) =>
						item?.types.includes('route'),
					)[0]?.long_name || ''),
			);
			formik.setFieldValue(
				'street2',
				result?.result?.address_components.filter((item) =>
					item?.types.includes('administrative_area_level_3'),
				)[0]?.long_name ||
					result?.result?.address_components.filter((item) =>
						item?.types.includes('administrative_area_level_4'),
					)[0]?.long_name ||
					result?.result?.address_components.filter((item) =>
						item?.types.includes('administrative_area_level_5'),
					)[0]?.long_name ||
					'',
			);
		});
	};

	const handleUpdateSubmit = () => {
		if (userData && userData?.id && userData?.password) {
			setSpinner(true);
			setSuccess(false);
			setError(false);
			if (
				!!Object.keys(clientValues).some((key) => clientValues[key] !== formik.values[key])
			) {
				if (!!selectItem) {
					searchCountryState(
						userData?.password,
						[
							[
								'name',
								'=',
								selectItem.address_components.filter((item) =>
									item?.types.includes('administrative_area_level_1'),
								)[0]?.long_name,
							],
						],
						({ elements }) => {
							const newUser = {
								id: clientData?.id,
								name: formik.values.fullName,
								phone: formik.values.phone || '',
								mobile: formik.values.mobile || '',
								email: formik.values.email || '',
								city: formik.values.city || '',
								zip: formik.values.zip || '',
								street: formik.values.street || '',
								places: selectItem?.url,
								country_id: 75,
								state_id: (!!elements?.length && elements[0]?.id) || '',
								x_code: formik.values.x_code || '',
								partner_delivery: formik.values?.adrLivraison || '',
								partner_latitude: selectItem?.geometry?.location?.lat || 0.0,
								partner_longitude: selectItem?.geometry?.location?.lng,
							};
							// updateClient(
							// 	userData?.tokenJava,
							// 	clientData?.id,
							// 	newUser,
							// 	(result) => {
							// 		handelSelect({
							// 			selected: { client: result },
							// 			value: { client: result?.name },
							// 		});
							// 		!!handleUpdate && handleUpdate(result);
							// 		setCreateClientModalStatus(false);
							// 		setSpinner(false);
							// 		setSuccess('Contact est créer avec success.');
							// 		setError(false);
							// 	},
							// 	(error) => {
							// 		setSpinner(false);
							// 		setSuccess(false);
							// 		setError(error);
							// 	},
							// );
							updateSinglePartner(
								userData?.password,
								newUser,
								(result) => {
									handelSelect({
										selected: { client: newUser },
										value: { client: newUser?.name },
									});
									!!handleUpdate && handleUpdate(newUser);
									setCreateClientModalStatus(false);
									setSpinner(false);
									setSuccess('Contact est créer avec success.');
									setError(false);
								},
								(error) => {
									setSpinner(false);
									setSuccess(false);
									!(error?.code == 'ERR_CANCELED') && setError(error);
								},
							);
						},
						(error) => {
							setSpinner(false);
							setSuccess(false);
							setError(error);
						},
					);
				} else {
					const newUser = {
						id: clientData?.id,
						name: formik.values.fullName,
						phone: formik.values.phone || '',
						mobile: formik.values.mobile || '',
						email: formik.values.email || '',
						city: formik.values.city || '',
						zip: formik.values.zip || '',
						street: formik.values.street || '',
						x_code: formik.values.x_code || '',
						partner_delivery: formik.values?.adrLivraison || '',
						partner_latitude: selectItem?.geometry?.location?.lat || 0.0,
						partner_longitude: selectItem?.geometry?.location?.lng,
					};
					updateSinglePartner(
						userData?.password,
						newUser,
						(result) => {
							handelSelect({
								selected: { client: newUser },
								value: { client: newUser?.name },
							});
							!!handleUpdate && handleUpdate(newUser);
							setCreateClientModalStatus(false);
							setSpinner(false);
							setSuccess('Contact est créer avec success.');
							setError(false);
						},
						(error) => {
							setSpinner(false);
							setSuccess(false);
							!(error?.code == 'ERR_CANCELED') && setError(error);
						},
					);
					// updateClient(
					// 	userData?.tokenJava,
					// 	clientData?.id,
					// 	newUser,
					// 	(result) => {
					// 		handelSelect({
					// 			selected: { client: result },
					// 			value: { client: result?.name },
					// 		});
					// 		!!handleUpdate && handleUpdate(result);
					// 		setCreateClientModalStatus(false);
					// 		setSpinner(false);
					// 		setSuccess('Contact est créer avec success.');
					// 		setError(false);
					// 	},
					// 	(error) => {
					// 		setSpinner(false);
					// 		setSuccess(false);
					// 		setError(error);
					// 	},
					// );
				}
			} else {
				const newUser = {
					id: clientData?.id,
					name: formik.values.fullName,
					phone: formik.values.phone || '',
					mobile: formik.values.mobile || '',
					email: formik.values.email || '',
					city: formik.values.city || '',
					zip: formik.values.zip || '',
					street: formik.values.street || '',
					places: selectItem?.url,
					country_id: 75,
					x_code: formik.values.x_code || '',
					partner_delivery: formik.values?.adrLivraison || '',
				};
				handelSelect({
					selected: {
						client: newUser,
					},
					value: { client: newUser?.name },
				});
				!!handleUpdate && handleUpdate(newUser);
				setCreateClientModalStatus(false);
				setSpinner(false);
				setSuccess('Contact est créer avec success.');
				setError(false);
			}
		}
	};

	const handleChangeAdrLivraison = (e) => {
		formik.handleChange(e);
		AutoComplete(e.target.value, (result) => {
			const newLocations = [];
			result?.predictions?.map((item) => {
				newLocations.push({
					id: item?.place_id,
					label: item?.description,
					icon: '',
				});
			});
			setResultSearchLivraison(newLocations);
		});
	};

	useEffect(() => {
		if (!!formik.values.name) {
			const abortController = new AbortController();
			const signal = abortController.signal;

			handleChangeClient(formik.values.name, signal);

			return () => {
				abortController.abort();
			};
		}
	}, [formik.values.name]);

	return (
		<Modal
			setIsOpen={() => {}}
			isOpen={true}
			isStaticBackdrop
			isScrollable
			classNameMd='nv-3'
			size={'lg'}>
			<ModalHeader setIsOpen={setCreateClientModalStatus} className='pb-0'></ModalHeader>
			<ModalBody className='pt-0'>
				<Card shadow='none'>
					<CardHeader className='pt-0'>
						{!!newClient ? (
							<CardLabel icon='PersonAddAlt' iconColor='primary'>
								<CardTitle>Nouveau Contact</CardTitle>
							</CardLabel>
						) : (
							<CardLabel icon='ManageAccounts' iconColor='primary'>
								<CardTitle>Contact Client</CardTitle>
							</CardLabel>
						)}

						{!newClient && (
							<CardActions>
								<div ref={ref} className='row justify-content-end'>
									<FormGroup
										className='autocomplete'
										//id='name'
									>
										<div className='d-flex align-items-center bg-l10-brand-two rounded-2'>
											<label
												className='border-0 ms-2 me-0'
												htmlFor='searchInput'>
												<Icon icon='Search' size='2x' color='primary' />
											</label>

											<Input
												ref={inputRef}
												onChange={(e) => {
													formik?.setFieldValue('name', e?.target?.value);
												}}
												//onFocus={handleChangeClient}
												value={formik.values.name}
												placeholder='Rechercher...'
												type='text'
												autoComplete='off'
												className='border-0 bg-transparent'
											/>
										</div>

										{openList && (
											<div
												id='myInputautocomplete-list'
												className='autocomplete-items position-absolute'>
												<div className='body'>
													{clientIsLoading ? (
														<div>
															<Spinner isSmall isGrow />{' '}
															<Spinner isSmall isGrow />{' '}
															<Spinner isSmall isGrow />
														</div>
													) : (
														!!filterResult?.length &&
														filterResult?.map((item) => (
															<div
																key={item?.id}
																onClick={() => {
																	handelSelectClient(item);
																}}>
																<Icon
																	color={'primary'}
																	isLight
																	icon={'Person'}
																	size='lg'
																/>
																{item?.name}{' '}
																<Icon
																	color={'primary'}
																	isLight
																	icon={'Place'}
																	size='lg'
																/>
																{item?.street +
																	' ' +
																	item?.zip +
																	' ' +
																	item?.city}
															</div>
														))
													)}
												</div>
											</div>
										)}
									</FormGroup>
								</div>
							</CardActions>
						)}
					</CardHeader>
					<CardBody>
						<div className='row g-4'>
							<FormGroup className='col-lg-6 ' id='fullName' label='Nom Complet'>
								<Input
									onChange={formik.handleChange}
									value={formik.values.fullName}
									onBlur={formik.handleBlur}
									isValid={formik.isValid}
									isTouched={formik.touched.fullName}
									invalidFeedback={formik.errors.fullName}
									name='fullName'
									type='name'
									autoComplete='off'
								/>
							</FormGroup>
							<FormGroup className='col-lg-6 ' id='email' label='Adresse e-mail'>
								<Input
									onChange={formik.handleChange}
									value={formik.values.email}
									onBlur={formik.handleBlur}
									isValid={formik.isValid}
									isTouched={formik.touched.email}
									invalidFeedback={formik.errors.email}
									name='email'
									type='email'
									autoComplete='off'
								/>
							</FormGroup>

							<FormGroup className='col-lg-6' id='phone' label='Tél'>
								<Input
									onChange={formik.handleChange}
									value={formik.values.phone}
									isTouched={formik.touched.phone}
									isValid={formik.isValid}
									onBlur={formik.handleBlur}
									invalidFeedback={formik.errors.phone}
									// validFeedback='Bien!'
									name='phone'
									type='tel'
								/>
							</FormGroup>
							<FormGroup className='col-lg-6' id='mobile' label='Mobile'>
								<Input
									onChange={formik.handleChange}
									value={formik.values.mobile}
									name='mobile'
									type='tel'
								/>
							</FormGroup>

							<SearchAutoComplete
								label='Adresse Facturation'
								className='col-12'
								value={formik.values.adrsFact}
								onChange={handleChange}
								options={resultSearch}
								setSelectItem={handelSelectItem}
								isTouched={formik.touched.adrsFact}
								isValid={formik.isValid}
								onBlur={formik.handleBlur}
								invalidFeedback={formik.errors.adrsFact}
							/>

							<FormGroup className='col-lg-6' id='street' label='Rue'>
								<Input
									type='text'
									name='street'
									value={formik?.values?.street}
									onChange={formik?.handleChange}
									isTouched={formik.touched.street}
									isValid={formik.isValid}
									onBlur={formik.handleBlur}
									invalidFeedback={formik.errors.street}
									validFeedback='Bien!'
								/>
							</FormGroup>
							<FormGroup id='zip' className='col-lg-3' label='Code postal'>
								<Input
									type='number'
									name='zip'
									value={formik?.values?.zip}
									onChange={formik?.handleChange}
									isTouched={formik.touched.zip}
									isValid={formik.isValid}
									onBlur={formik.handleBlur}
									invalidFeedback={formik.errors.zip}
									validFeedback='Bien!'
								/>
							</FormGroup>

							<FormGroup id='city' className='col-lg-3' label='Ville'>
								<Input
									type='text'
									name='city'
									value={formik?.values?.city}
									onChange={formik?.handleChange}
									isTouched={formik.touched.city}
									isValid={formik.isValid}
									onBlur={formik.handleBlur}
									invalidFeedback={formik.errors.city}
									validFeedback='Bien!'
								/>
							</FormGroup>

							<SearchAutoComplete
								label='Adresse Livraison'
								className='col-12'
								value={formik?.values.adrLivraison}
								name='adrLivraison'
								onChange={handleChangeAdrLivraison}
								options={resultSearchLivraison}
								setSelectItem={handeklSelectAdrLivraison}
							/>
							<FormGroup className='col-lg-6' id='x_code' label="Code d'accès">
								<Input
									onChange={formik.handleChange}
									value={formik.values.x_code}
									name='x_code'
									type='text'
									autoComplete='off'
								/>
							</FormGroup>

							{!!newClient && (
								<FormGroup id='function'>
									<div className='d-flex'>
										<Checks
											type='checkbox'
											name='function'
											id='function'
											value={isFournisseur}
											onChange={() => setIsFournisseur(!isFournisseur)}
											checked={!!isFournisseur}
										/>
										<Label>Fournisseur</Label>
									</div>
								</FormGroup>
							)}

							{/* <FormGroup
										className='col-6'
										id='adrsLivr'
										label='Addresse Livraison'>
										<Input
											onChange={formik.handleChange}
											value={formik.values.adrsLivr}
											name='adrsLivr'
											type='text'
										/>
									</FormGroup> */}
						</div>
					</CardBody>
				</Card>
			</ModalBody>
			<ModalFooter className='px-4 pb-4'>
				{!!clientData?.id ? (
					<Button
						color='primary'
						className='w-100'
						isDisable={spinner}
						onClick={handleUpdateSubmit}>
						{spinner && <Spinner isSmall inButton />}
						{spinner
							? 'En cours'
							: `${
									!!Object.keys(clientValues).some(
										(key) => clientValues[key] !== formik.values[key],
									)
										? 'Sauvegarder'
										: 'Sélectionner'
							  }`}
					</Button>
				) : (
					<Button
						color='primary'
						className='w-100'
						type='submit'
						isDisable={spinner}
						onClick={formik.handleSubmit}>
						{spinner && <Spinner isSmall inButton />}
						{spinner ? 'En cours' : 'Créer'}
					</Button>
				)}
			</ModalFooter>

			{!!success && (
				<StatusPopup
					icon='Verified'
					color='success'
					title={'Super!'}
					label={success}
					setOpen={setSuccess}
				/>
			)}
			{!!error && <ErrorPopup error={error} setOpen={setError} />}
		</Modal>
	);
};

export default SearchClient;
