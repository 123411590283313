import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../bootstrap/Card";
import React, { useContext, useState } from "react";
import Button from "../bootstrap/Button";
import FormGroup from "../bootstrap/forms/FormGroup";
import Input from '../bootstrap/forms/Input';
import AuthContext from "../../contexts/authContext";
import { searchCategory } from "../../odooApi/categoryProdut";
import SearchPopUp from "./SearchPopUp";
import Alert from "../bootstrap/Alert";
import { searchProductTemplate } from "../../odooApi/productTemplate";
import { searchProduct } from "../../odooApi/product";
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';



const CardAddProdectLineDevis = ({setSelectProduct}) => {
    const [openCardBody,setOpenCardBody] = useState(false);
    const { userData } = useContext(AuthContext);
    const [filterResult, setfilterResult] = useState({});
    const [searchModalStatus,setSearchModalStatus] = useState(false);
    const [formState,setFormState] = useState({});
    const [selectedValues, setSelectedValues] = useState({});
    const [curentInput,setCurentInput] = useState({inputName:"",inputLabel:""});

    const { t } = useTranslation(['translation', 'menu']);
    
    const handleChange = (e) => {
        
        setFormState({...formState,[e.target.name]:e.target.value});
        setSelectedValues({...selectedValues,[e.target.name]:{}});
        setSearchModalStatus(true);
        setSelectProduct({});
        if(!filterResult[e.target.name]?.length){setfilterResult({})};
       
        if(e.target.name=="category"){
            setCurentInput({inputName:e.target.name,inputLabel:"Categories"});
            
            if(userData&&userData?.id&&userData?.password){
                
                searchCategory(
                    userData?.password,
                    [
                        "|",
                        ["name","like",e.target.value],
                        "|",
                        ["name","like",e.target.value.charAt(0).toUpperCase()+e.target.value.substring(1).toLowerCase()],
                        ["name","like",e.target.value.toUpperCase()]
                    ],
                    (categories)=>{
                        setfilterResult({[e.target.name]:categories?.elements});
                    }
                );
                
            }

            
            
        }else if(e.target.name=="productTemplate"){
            setCurentInput({inputName:e.target.name,inputLabel:"Product Template"});
            
            if(userData&&userData?.id&&userData?.password){
                searchProductTemplate(
                    userData?.password,
                    [   "&",
                        ["categ_id","=",selectedValues?.category?.id],
                        "|",
                        ["name","like",e.target.value],
                        "|",
                        ["name","like",e.target.value.charAt(0).toUpperCase()+e.target.value.substring(1).toLowerCase()],
                        ["name","like",e.target.value.toUpperCase()]
                    ],
                    ({elements})=>{
                        setfilterResult({[e.target.name]:elements});
                    }
                );
                
            }

            
            
        }else if(e.target.name=="product"){
            setCurentInput({inputName:e.target.name,inputLabel:"Product"});
            
            if(userData&&userData?.id&&userData?.password){
                searchProduct(
                    userData?.password,
                    [   "&",
                        ["product_tmpl_id","=",selectedValues?.productTemplate?.id],
                        "|",
                        ["display_name","like",e.target.value],
                        "|",
                        ["display_name","like",e.target.value.charAt(0).toUpperCase()+e.target.value.substring(1).toLowerCase()],
                        ["display_name","like",e.target.value.toUpperCase()]
                    ],
                    (categories)=>{
                        setfilterResult({[e.target.name]:categories});
                    }
                );
                
            }

            
            
        }else{
            setCurentInput({inputName:[e.target.name]});
        }
        
        
    }

    const handelSelect = ({value,selected}) => {
        if(!!selected.category){
            setSelectedValues({...selected});
            setFormState({...value});
        }else if (!!selected.productTemplate){
            setSelectedValues({...selectedValues,product:{},...selected});
            setFormState({...formState,product:"",...value});

        }else if (!!selected.product){
            setSelectedValues({...selectedValues,...selected});
            setFormState({...formState,...value});
            setSelectProduct({...selected?.product});
        }
    
        setSearchModalStatus(false);
        
    }

    const handelCancel = () => {
        setSelectProduct({});
        setOpenCardBody(false);
    }
    const handelCreate = () => {
        setSelectProduct(selectedValues?.product);
        setOpenCardBody(true);
    }
    return (
        <>
            <Card shadow='sm'>
                <CardHeader>
                    <CardLabel icon='BoxSeam' iconColor='success'>
                                        
                        <CardTitle>{t('Product')}</CardTitle>
                    </CardLabel>
                    {
                        !openCardBody&&
                        <CardActions>
                            <Button color='primary' isOutline onClick={handelCreate}>
                                {t('Add')}
                            </Button>
                        </CardActions>
                    }
                    
                </CardHeader>
                {
                    openCardBody&&
                    <CardBody>
                        <div className='row g-4'>
                            <FormGroup
                                className='col-12'
                                id='category'
                                label='Category'
                            >
                                <Input
                                    onChange={handleChange}
                                    onFocus={handleChange}
                                    value={formState?.category}
                                    name="category"
                                    autoComplete="off"
                                />
                            </FormGroup>
                            {
                                selectedValues?.category?.id?
                                <>
                                    <FormGroup
                                        className='col-12'
                                        id='product-template'
                                        label={t('Product Template')}>
                                        <Input
                                            onChange={handleChange}
                                            onFocus={handleChange}
                                            value={formState?.productTemplate}
                                            name="productTemplate"
                                            autoComplete="off"
                                        />
                                    </FormGroup>
                                    {
                                        selectedValues?.productTemplate?.id?
                                        <>
                                            <FormGroup
                                                className='col-12'
                                                id='product'
                                                label={t('Product')}>
                                                <Input
                                                    onChange={handleChange}
                                                    onFocus={handleChange}
                                                    value={formState?.product}
                                                    name="product"
                                                    autoComplete="off"
                                                />
                                            </FormGroup>
                                            {
                                                !selectedValues?.product?.id&&
                                                <Alert
                                                    className="col-12"
                                                    color="warning"
                                                    icon='Info'
                                                >
                                                {t('You must select an existing Product Template')}
                                            
                                                </Alert>
                                            }
                                        </>
                                        :
                                        <Alert
                                            className="col-12"
                                            color="warning"
                                            icon='Info'
                                        >
                                        {t('You must select an existing Product Template')}
                                    
                                        </Alert>
                                        
                                    }
                                </>
                                :
                                <Alert
                                    className="col-12"
                                    color="warning"
                                    icon='Info'
                                >

                                {t('You must select an existing category')}
                                    
                               
                                </Alert>
                                
                            }
                            
                        </div>
                        <Button
                            color='secondary'
                            className='w-100 mt-5'
                            onClick={handelCancel}>
                            {t('Cancel')}
                        </Button>
                    </CardBody>
                }
                
            </Card>
            {
                searchModalStatus&&
                <SearchPopUp 
                    setSearchModalStatus={()=>setSearchModalStatus(false)} 
                    filterResult={filterResult||{}}
                    valueInput={formState}
                    curentInput={curentInput}
                    handelChange={handleChange}
                    handelSelect={handelSelect}
                />
            }
            
        </>
        
    )
}

CardAddProdectLineDevis.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	// @ts-ignore
	setSelectProduct: PropTypes.func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
};

export default CardAddProdectLineDevis;