import { getSingleProduct, searchProduct } from '../../../../../../../odooApi/product';
import { prix_VRCManuelTradi } from '../../../../../CalculSimulateurData.js/VRCompletManuel';
import formInputsTypes from '../../../../simulatorConstant';
import {
	CATEG_ID_AXE,
	CATEG_ID_TANDEM,
	PRODUCT_ID_AXE_MANUEL_TRADI,
	PRODUCT_ID_COUSSINET,
	PRODUCT_ID_EQUERRE,
	PRODUCT_ID_JAMBE,
	PRODUCT_ID_PLAQUE,
} from '../../axe';

const Tandem = {
	id: 'tandem',
	type: formInputsTypes?.SELECT_INPUT,
	label: 'Tandem',
	name: 'tandem',
	nextStepData: false,
	nextStep: false,
	options: null,
	fetchData: (tokenUserCurent, currentFormValue, fnSuccess) => {
		let price = 0;

		const m2 = (currentFormValue?.largeur * currentFormValue?.hauteur) / 1000;
		if (m2 <= prix_VRCManuelTradi[0]?.id) {
			price = prix_VRCManuelTradi[0]?.price;
		} else if (m2 > prix_VRCManuelTradi?.length?.id) {
			price = prix_VRCManuelTradi[prix_VRCManuelTradi?.length].price;
		} else {
			for (var i = 0; i < prix_VRCManuelTradi.length - 1; i++) {
				// console.log('prix', prix[i]);
				if (m2 > prix_VRCManuelTradi[i]?.id && m2 <= prix_VRCManuelTradi[i + 1]?.id) {
					price = prix_VRCManuelTradi[i + 1]?.price;
				}
			}
		}
		const generateFilter = () => {
			const filters = [
				'&',
				['categ_id', '=', CATEG_ID_TANDEM],
				['diametre', '=', currentFormValue?.axe?.diametre],
			];

			return filters;
		};
		searchProduct(tokenUserCurent, generateFilter(), ({ elements }) => {
			getSingleProduct(PRODUCT_ID_EQUERRE, tokenUserCurent, (dataEquerre) => {
				getSingleProduct(PRODUCT_ID_JAMBE, tokenUserCurent, (dataJambe) => {
					getSingleProduct(PRODUCT_ID_COUSSINET, tokenUserCurent, (dataCoussinet) => {
						getSingleProduct(PRODUCT_ID_PLAQUE, tokenUserCurent, (dataPlaque) => {
							const generateDescription = (item) => {
								return (
									`${currentFormValue?.product?.value} \n Volet roulant fabriqué en aluminium de haute qualité injecté avec précision pour garantir une solidité exceptionnelle et résistance aux intempéries et aux agressions extérieures. \n Ce volet roulant manuel est équipé d'un mécanisme de commande convivial qui permet de l'ouvrir et de le fermer en un seul geste. \n Vous pouvez compter sur sa simplicité d'utilisation au quotidien. \n \n` +
									`\n- Tablier: ${currentFormValue?.lames?.label} ` +
									`\n- Lame Finale: ${currentFormValue?.lameFinal?.label || ''}` +
									`\n- Coulisse: ${currentFormValue?.coulisse?.label || ''} ` +
									`\n- Axe: ${currentFormValue?.axe?.label} ` +
									`\n- Manoeuvre Manuel: ${currentFormValue?.manoeuvreManuel?.label}` +
									`\n- Accessoires ` +
									`\n- Dimensions L: ${currentFormValue?.largeur}mm (${currentFormValue?.typeMesureLargeur?.value})` +
									` x H: ${currentFormValue?.hauteur}mm (${currentFormValue?.typeMesureHauteur?.value})` +
									`${currentFormValue?.isWithAgraffe?.value == 'oui'
										? '\n- Verrou: ' +
										(Math.ceil(currentFormValue?.largeur / 1000) + 1) +
										' ' +
										currentFormValue?.agraffe?.label
										: ''
									}` +
									`\nConforme à la norme NF EN 13241-1 `
								);
							};

							const options = elements?.length
								? elements.map((item) => ({
									id: item?.id,
									product_id: 263,
									description: generateDescription(item),
									production: [
										currentFormValue?.lames?.id,
										currentFormValue?.coulisse?.id,
										currentFormValue?.axe?.id,
										currentFormValue?.manoeuvreManuel?.id,
										currentFormValue?.treuil?.id,
										item?.id,
									],
									largeur: currentFormValue?.largeur,
									hauteur: currentFormValue?.hauteur,
									verrou: [
										currentFormValue?.agraffe?.id || 0,
										Math.ceil(currentFormValue?.largeur / 1000) + 1,
									],
									label: item?.display_name,
									value: item?.id,
									lst_price: price,
									// lst_price: parseFloat(
									// 	(
									// 		item?.lst_price +
									// 		(Math.ceil(currentFormValue?.largeur / 1000) + 1) *
									// 			(currentFormValue?.agraffe?.lst_price || 0) +
									// 		currentFormValue?.lames?.lst_price +
									// 		currentFormValue?.lameFinal?.lst_price +
									// 		currentFormValue?.coulisse?.lst_price +
									// 		(currentFormValue?.axe?.lst_price *
									// 			currentFormValue?.largeur) /
									// 			1000 +
									// 		currentFormValue?.manoeuvreManuel?.lst_price +
									// 		currentFormValue?.treuil?.lst_price +
									// 		dataEquerre.lst_price +
									// 		dataJambe.lst_price +
									// 		dataCoussinet.lst_price +
									// 		dataPlaque.lst_price
									// 	).toFixed(2),
									// ),
								}))
								: [];

							fnSuccess(options);
						});
					});
				});
			});
		});
	},
};

const axeTreuil = {
	id: 'treuil',
	type: formInputsTypes?.SELECT_INPUT,
	label: 'Treuil',
	name: 'treuil',
	nextStepData: Tandem,
	nextStep: false,
	options: null,
	fetchData: (tokenUserCurent, currentFormValue, fnSuccess) => {
		const generateFilter = () => {
			const filters = [['categ_id', '=', 97]];

			return filters;
		};
		searchProduct(tokenUserCurent, generateFilter(currentFormValue), async ({ elements }) => {
			const options = elements?.length
				? elements.map((item) => ({
					id: item?.id,
					product_id: PRODUCT_ID_AXE_MANUEL_TRADI,
					label: item?.display_name,
					value: item?.id,
					lst_price: item?.lst_price,
				}))
				: [];
			fnSuccess(options);
		});
	},
};

const manoeuvreManuel = {
	id: 'manoeuvreManuel',
	type: formInputsTypes?.SELECT_INPUT,
	label: 'Manœuvre manuel',
	name: 'manoeuvreManuel',
	nextStepData: false,
	nextStep: false,
	options: null,
	fetchData: (tokenUserCurent, currentFormValue, fnSuccess) => {
		let price = 0;

		const m2 = (currentFormValue?.largeur * currentFormValue?.hauteur) / 1000;
		if (m2 <= prix_VRCManuelTradi[0]?.id) {
			price = prix_VRCManuelTradi[0]?.price;
		} else if (m2 > prix_VRCManuelTradi?.length?.id) {
			price = prix_VRCManuelTradi[prix_VRCManuelTradi?.length].price;
		} else {
			for (var i = 0; i < prix_VRCManuelTradi.length - 1; i++) {
				// console.log('prix', prix[i]);
				if (m2 > prix_VRCManuelTradi[i]?.id && m2 <= prix_VRCManuelTradi[i + 1]?.id) {
					price = prix_VRCManuelTradi[i + 1]?.price;
				}
			}
		}
		const generateFilter = () => {
			const filters = [['categ_id', '=', 21]];

			return filters;
		};
		searchProduct(tokenUserCurent, generateFilter(currentFormValue), ({ elements }) => {
			const generateDescription = (item) => {
				return (
					`${currentFormValue?.product?.value} \n Volet roulant fabriqué en aluminium de haute qualité injecté avec précision pour garantir une solidité exceptionnelle et résistance aux intempéries et aux agressions extérieures. \n Ce volet roulant manuel est équipé d'un mécanisme de commande convivial qui permet de l'ouvrir et de le fermer en un seul geste. \n Vous pouvez compter sur sa simplicité d'utilisation au quotidien. \n \n` +
					`\n- Tablier: ${currentFormValue?.lames?.label} ` +
					`\n- ${currentFormValue?.coulisse?.label || ''} ` +
					`\n- Axe: ${currentFormValue?.axe?.label} ` +
					`\n- Manoeuvre Manuel: ${item?.display_name}` +
					`\n- Accessoires ` +
					`\n- Dimensions L: ${currentFormValue?.largeur}mm (${currentFormValue?.typeMesureLargeur?.value})` +
					` x H: ${currentFormValue?.hauteur}mm (${currentFormValue?.typeMesureHauteur?.value})` +
					`${currentFormValue?.isWithAgraffe?.value == 'oui'
						? '\n- ' +
						(Math.ceil(currentFormValue?.largeur / 1000) + 1) +
						' verrous'

						: ''
					}` +
					`\nConforme à la norme NF EN 13241-1 `
				);
			};

			const options = elements?.length
				? elements.map((item) => ({
					id: item?.id,
					product_id: 263,
					description: generateDescription(item),
					production: [
						currentFormValue?.lames?.id,
						currentFormValue?.coulisse?.id,
						currentFormValue?.axe?.id,
						item?.id,
					],
					largeur: currentFormValue?.largeur,
					hauteur: currentFormValue?.hauteur,
					verrou: [
						currentFormValue?.agraffe?.id || 0,
						Math.ceil(currentFormValue?.largeur / 1000) + 1,
					],
					label: item?.display_name,
					value: item?.id,
					lst_price: price,
					// lst_price: parseFloat(
					// 	(
					// 		item?.lst_price +
					// 		(Math.ceil(currentFormValue?.largeur / 1000) + 1) *
					// 			(currentFormValue?.agraffe?.lst_price || 0) +
					// 		currentFormValue?.lames?.lst_price +
					// 		currentFormValue?.lameFinal?.lst_price +
					// 		currentFormValue?.coulisse?.lst_price +
					// 		(currentFormValue?.axe?.lst_price *
					// 			currentFormValue?.largeur) /
					// 			1000 +
					// 		currentFormValue?.manoeuvreManuel?.lst_price +
					// 		currentFormValue?.treuil?.lst_price +
					// 		dataEquerre.lst_price +
					// 		dataJambe.lst_price +
					// 		dataCoussinet.lst_price +
					// 		dataPlaque.lst_price
					// 	).toFixed(2),
					// ),
				}))
				: [];

			fnSuccess(options);
			// const options = elements?.length
			// 	? elements.map((item) => ({
			// 			id: item?.id,
			// 			label: item?.display_name,
			// 			value: item?.id,
			// 			lst_price: item?.lst_price,
			// 	  }))
			// 	: [];
			// fnSuccess(options);
		});
	},
};

/* const axeVoletRoulant = {
	id:"VR-TR-AXE-API",
	type:formInputsTypes?.SELECT_INPUT,
	label:"Sélectionner un axe",
	name:"axe",
	nextStepData:manoeuvreManuel,
	nextStep:false,
	options:null,
	fetchData: (tokenUserCurent,currentFormValue,fnSuccess) => {
		const generateFilter = () => {
			const filters = [
				["categ_id","=",CATEG_ID_AXE]
			]
		    
			return(
				filters
			)
		}
		searchProduct(
			tokenUserCurent,
			generateFilter(currentFormValue),
			({elements})=>{
				const options = elements?.length? elements.map((item=>({
					id:item?.id,
					label:item?.display_name,
					value:item?.id,
					lst_price:item?.lst_price,
					diametre:item?.diametre
				}))):
				[];
				fnSuccess(options);
			}
		)
	}
    
} */

export default manoeuvreManuel;
