import {
  CATEG_ID_TANDEM,
  MIN_WIDTH,
  PRODUCT_ID_AXE_MANUEL_TRADI,
  PRODUCT_ID_COUSSINET,
  PRODUCT_ID_EQUERRE,
  PRODUCT_ID_JAMBE,
  PRODUCT_ID_PLAQUE,
} from "..";
import {
  getSingleProduct,
  searchProduct,
} from "../../../../../../../odooApi/product";
import { prix_AxeManuelReno } from "../../../../../CalculSimulateurData.js/Axe_Manuel";
import formInputsTypes from "../../../../simulatorConstant";
import { CATEG_ID_AXE, PRODUCT_ID_AXE_MANUEL } from "../../../tradi/axe";

export const CATEG_ID_VERROU_SOUPLE = 151;
export const CATEG_ID_VERROU_REGIDE = 152;

const agraffe = {
  id: "agraffe",
  type: formInputsTypes?.SELECT_INPUT,
  label: "Agraffe",
  name: "agraffe",
  nextStepData: false,
  nextStep: false,
  fetchData: (tokenUserCurent, currentFormValue, fnSuccess) => {
    let price = 0;

    const m2 = (currentFormValue?.largeur * currentFormValue?.hauteur) / 1000;
    if (m2 <= prix_AxeManuelReno[0]?.id) {
      price = prix_AxeManuelReno[0]?.price;
    } else if (m2 > prix_AxeManuelReno?.length?.id) {
      price = prix_AxeManuelReno[prix_AxeManuelReno?.length].price;
    } else {
      for (var i = 0; i < prix_AxeManuelReno.length - 1; i++) {
        if (
          m2 > prix_AxeManuelReno[i]?.id &&
          m2 <= prix_AxeManuelReno[i + 1]?.id
        ) {
          price = prix_AxeManuelReno[i + 1]?.price;
        }
      }
    }
    const generateFilter = () => {
      const filters = [["categ_id", "=", currentFormValue?.typeAgraffe?.value]];

      return filters;
    };
    searchProduct(tokenUserCurent, generateFilter(), ({ elements }) => {
      getSingleProduct(PRODUCT_ID_EQUERRE, tokenUserCurent, (dataEquerre) => {
        getSingleProduct(PRODUCT_ID_JAMBE, tokenUserCurent, (dataJambe) => {
          getSingleProduct(
            PRODUCT_ID_COUSSINET,
            tokenUserCurent,
            (dataCoussinet) => {
              getSingleProduct(
                PRODUCT_ID_PLAQUE,
                tokenUserCurent,
                (dataPlaque) => {
                  const generateDescription = (item) => {
                    return (
                      `${currentFormValue?.typeAxe?.value} équipé de:` +
                      `\n- Axe: ${currentFormValue?.axe?.label}` +
                      `\n- Manoeuvre Manuel: ${currentFormValue?.manoeuvreManuel?.label}` +
                      // `\n- Treuil: ${currentFormValue?.treuil?.label}` +
                      // `\n- Tandem: ${currentFormValue?.tandem?.label}` +
                      // `\n- Equerre: ${dataEquerre?.display_name}` +
                      // `\n- Jambe de force: ${dataJambe?.display_name}` +
                      `\n- Verrou: ${Math.ceil(currentFormValue?.largeur / 1000) +
                      1 +
                      " " +
                      item?.display_name
                      }
                                                            ` +
                      `\n Largeur: ${currentFormValue?.largeur}mm (${currentFormValue?.typeMesureLargeur?.value})`
                    );
                  };
                  const options = elements?.length
                    ? elements.map((item) => ({
                      id: item?.id,
                      product_id: PRODUCT_ID_AXE_MANUEL,
                      description: generateDescription(item),
                      production: [
                        currentFormValue?.treuil?.id,
                        currentFormValue?.tandem?.id,
                        currentFormValue?.manoeuvreManuel?.id,
                      ],
                      largeur: currentFormValue?.largeur,
                      label: item?.display_name,
                      verrou: [
                        item?.id,
                        Math.ceil(currentFormValue?.largeur / 1000) + 1,
                      ],
                      value: item?.id,
                      lst_price: price,
                      // lst_price: parseFloat(
                      // 	(
                      // 		item?.lst_price *
                      // 			(Math.ceil(currentFormValue?.largeur / 1000) +
                      // 				1) +
                      // 		(currentFormValue?.axe?.lst_price *
                      // 			currentFormValue?.largeur) /
                      // 			1000 +
                      // 		currentFormValue?.manoeuvreManuel?.lst_price +
                      // 		currentFormValue?.treuil?.lst_price +
                      // 		dataEquerre.lst_price +
                      // 		dataJambe.lst_price +
                      // 		dataCoussinet.lst_price +
                      // 		dataPlaque.lst_price +
                      // 		currentFormValue?.tandem?.lst_price
                      // 	).toFixed(2),
                      // ),
                    }))
                    : [];
                  fnSuccess(options);
                }
              );
            }
          );
        });
      });
    });
  },
};

const typeAgraffe = {
  id: "typeAgraffe",
  type: formInputsTypes?.RADIO_INPUT,
  label: "Type Verrou",
  name: "typeAgraffe",
  className: "col-6",
  nextStepData: agraffe,
  nextStep: false,
  options: [
    {
      id: "regide",
      label: "Regide",
      value: CATEG_ID_VERROU_REGIDE,
      nextStep: false,
    },
    {
      id: "souple",
      label: "Souple",
      value: CATEG_ID_VERROU_SOUPLE,
      nextStep: false,
    },
  ],
};

const isWithAgraffe = {
  id: "isWithAgraffe",
  type: formInputsTypes?.RADIO_INPUT,
  label: "Avec Verrou ?",
  name: "isWithAgraffe",
  className: "col-6 d-none",
  nextStepData: false,
  nextStep: false,
  fetchData: (tokenUserCurent, currentFormValue, fnSuccess) => {
    let price = 0;

    const m2 = (currentFormValue?.largeur * currentFormValue?.hauteur) / 1000;
    if (m2 <= prix_AxeManuelReno[0]?.id) {
      price = prix_AxeManuelReno[0]?.price;
    } else if (m2 > prix_AxeManuelReno?.length?.id) {
      price = prix_AxeManuelReno[prix_AxeManuelReno?.length].price;
    } else {
      for (var i = 0; i < prix_AxeManuelReno.length - 1; i++) {
        if (
          m2 > prix_AxeManuelReno[i]?.id &&
          m2 <= prix_AxeManuelReno[i + 1]?.id
        ) {
          price = prix_AxeManuelReno[i + 1]?.price;
        }
      }
    }
    getSingleProduct(PRODUCT_ID_EQUERRE, tokenUserCurent, (dataEquerre) => {
      getSingleProduct(PRODUCT_ID_JAMBE, tokenUserCurent, (dataJambe) => {
        getSingleProduct(
          PRODUCT_ID_COUSSINET,
          tokenUserCurent,
          (dataCoussinet) => {
            getSingleProduct(
              PRODUCT_ID_PLAQUE,
              tokenUserCurent,
              (dataPlaque) => {
                let data = "";
                const generateDescription = (data) => {
                  return (
                    `${currentFormValue?.typeAxe?.value} équipé de:` +
                    `\n- Axe: ${currentFormValue?.axe?.label}` +
                    `\n- Manoeuvre Manuel: ${currentFormValue?.manoeuvreManuel?.label}` +
                    `\n- Côté manivelle ${currentFormValue?.cote_manivelle?.value}` +
                    `${currentFormValue?.ressort?.id == "ressort-Oui"
                      ? "\n- Avec ressort"
                      : ""
                    }` +
                    `${data == "oui"
                      ? "\n- " +
                      (Math.ceil(currentFormValue?.largeur / 1000) + 1) +
                      " verrous"
                      : ""
                    }` +
                    // `\n- Treuil: ${currentFormValue?.treuil?.label}` +
                    // `\n- Tandem: ${currentFormValue?.tandem?.label}` +
                    // `\n- Equerre: ${dataEquerre?.display_name}` +
                    // `\n- Jambe de force: ${dataJambe?.display_name}` +
                    `\n Largeur: ${currentFormValue?.largeur}mm (${currentFormValue?.typeMesureLargeur?.value})`
                  );
                };

                const options = [
                  {
                    id: "with-agraffe",
                    label: "Oui",
                    value: "oui",
                    checked: true,
                    nextStep: false,
                    product_id: PRODUCT_ID_AXE_MANUEL,
                    description: generateDescription((data = "oui")),
                    production: [currentFormValue?.manoeuvreManuel?.id],
                    largeur: currentFormValue?.largeur,
                    lst_price: price,
                  },
                  // {
                  //   id: "with-out-agraffe",
                  //   product_id: PRODUCT_ID_AXE_MANUEL,
                  //   label: "Non",
                  //   value: "non",
                  //   description: generateDescription((data = "non")),
                  //   production: [currentFormValue?.manoeuvreManuel?.id],
                  //   largeur: currentFormValue?.largeur,
                  //   lst_price: price,

                  //   nextStep: false,
                  // },
                ];
                fnSuccess(options);
              }
            );
          }
        );
      });
    });
  },
};

const Tandem = {
  id: "tandem",
  type: formInputsTypes?.SELECT_INPUT,
  label: "Tandem",
  name: "tandem",
  nextStepData: isWithAgraffe,
  nextStep: false,
  options: null,
  fetchData: (tokenUserCurent, currentFormValue, fnSuccess) => {
    const generateFilter = () => {
      const filters = [
        "&",
        ["categ_id", "=", CATEG_ID_TANDEM],
        ["diametre", "=", currentFormValue?.axe?.diametre],
      ];

      return filters;
    };
    searchProduct(tokenUserCurent, generateFilter(), async ({ elements }) => {
      const options = elements?.length
        ? elements.map((item) => ({
          id: item?.id,
          label: item?.display_name,
          value: item?.id,
          lst_price: item?.lst_price,
        }))
        : [];
      fnSuccess(options);
    });
  },
};

const ressort = {
  id: "ressort",
  type: formInputsTypes?.RADIO_INPUT,
  label: "Ressort",
  name: "ressort",
  nextStepData: isWithAgraffe,
  className: "col-6",
  nextStep: false,
  options: [
    {
      id: "ressort-Non",
      label: "Sans ressort",
      value: "Sans ressort",
      nextStep: false,
    },
    {
      id: "ressort-Oui",
      label: "Avec ressort",
      value: "Avec ressort",
      nextStep: false,
    },
  ],
};

const cote_manivelle = {
  id: "cote_manivelle",
  type: formInputsTypes?.RADIO_INPUT,
  label: "côté manivelle",
  name: "cote_manivelle",
  className: "col-6",
  nextStepData: ressort,
  nextStep: false,
  options: [
    {
      id: "DM",
      label: "Droite",
      value: "droite",
      nextStep: false,
    },
    {
      id: "Gm",
      label: "Gauche",
      value: "gauche",
      nextStep: false,
    },
  ],
};

const axeTreuil = {
  id: "treuil",
  type: formInputsTypes?.SELECT_INPUT,
  label: "Treuil",
  name: "treuil",
  nextStepData: Tandem,
  nextStep: false,
  options: null,
  fetchData: (tokenUserCurent, currentFormValue, fnSuccess) => {
    const generateFilter = () => {
      const filters = [["categ_id", "=", 97]];

      return filters;
    };
    searchProduct(
      tokenUserCurent,
      generateFilter(currentFormValue),
      async ({ elements }) => {
        const options = elements?.length
          ? elements.map((item) => ({
            id: item?.id,
            product_id: PRODUCT_ID_AXE_MANUEL_TRADI,
            label: item?.display_name,
            value: item?.id,
            lst_price: item?.lst_price,
          }))
          : [];
        fnSuccess(options);
      }
    );
  },
};

const manoeuvreManuel = {
  id: "manoeuvreManuel",
  type: formInputsTypes?.SELECT_INPUT,
  label: "Manœuvre manuel",
  name: "manoeuvreManuel",
  nextStepData: cote_manivelle,
  nextStep: false,
  options: null,
  fetchData: (tokenUserCurent, currentFormValue, fnSuccess) => {
    const generateFilter = () => {
      const filters = [["categ_id", "=", 21]];

      return filters;
    };
    searchProduct(
      tokenUserCurent,
      generateFilter(currentFormValue),
      ({ elements }) => {
        const options = elements?.length
          ? elements.map((item) => ({
            id: item?.id,
            label: item?.display_name,
            value: item?.id,
            lst_price: item?.lst_price,
          }))
          : [];
        fnSuccess(options);
      }
    );
  },
};

export const axeVoletRoulant = {
  id: "VR-TR-AXE-API",
  type: formInputsTypes?.SELECT_INPUT,
  label: "Sélectionner un Axe",
  name: "axe",
  nextStepData: manoeuvreManuel,
  nextStep: false,
  options: null,
  fetchData: (tokenUserCurent, currentFormValue, fnSuccess) => {
    const generateFilter = () => {
      const filters = [["categ_id", "=", CATEG_ID_AXE]];

      return filters;
    };
    searchProduct(
      tokenUserCurent,
      generateFilter(currentFormValue),
      ({ elements }) => {
        const options = elements?.length
          ? elements.map((item) => ({
            id: item?.id,
            label: item?.name,
            value: item?.id,
            lst_price: item?.lst_price,
            diametre: item?.diametre,
          }))
          : [];
        fnSuccess(options);
      }
    );
  },
};

const widthAxe = {
  id: "VR-TR-AXE-W",
  type: formInputsTypes?.INPUT_NUMBER,
  name: "largeur",
  className: "col-6",
  label: "Largeur de la lame(mm)",
  defaultValue: MIN_WIDTH,
  nextStepData: axeVoletRoulant,
  nextStep: false,
  validation: (value) => {
    return value > 0;
  },
};

const TypeMesureLarg = {
  id: "VR-TR-AXE-TMW",
  type: formInputsTypes?.RADIO_INPUT,
  name: "typeMesureLargeur",
  className: "col-6",
  label: "Mesure selon largeur",
  nextStepData: widthAxe,
  nextStep: false,
  options: [
    {
      id: "VR-TR-AXE-TMW-FN",
      label: "FINI",
      value: "FINI",
      nextStep: false,
    },
    {
      id: "VR-TR-AXE-TMW-DC",
      label: "D.C",
      value: "D.C",
      nextStep: false,
    },
  ],
};

export default TypeMesureLarg;
