import React, { useContext, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { motion, MotionStyle } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import Brand from '../Brand/Brand';
import Navigation, { NavigationLine, NavigationTitle } from '../Navigation/Navigation';
import User from '../User/User';
import { componentsMenu, dashboardMenu } from '../../menu';

import ThemeContext from '../../contexts/themeContext';
import Card, { CardBody } from '../../components/bootstrap/Card';

import Hand from '../../assets/img/hand.png';
import HandWebp from '../../assets/img/hand.webp';
import Icon from '../../components/icon/Icon';
import Button from '../../components/bootstrap/Button';
import Tooltips from '../../components/bootstrap/Tooltips';
import useDarkMode from '../../hooks/useDarkMode';
import useAsideTouch from '../../hooks/useAsideTouch';
import AuthContext from '../../contexts/authContext';
import { modules } from '../../gestioneerMenu';
import { useMediaQuery } from 'react-responsive'
import { getSingleCompanyId, searchCompanies } from '../../odooApi/Company';
import Badge from '../../components/bootstrap/Badge';
import CompanieContext from '../../contexts/companieContext';
import Select from '../../components/bootstrap/forms/Select';
import Option from '../../components/bootstrap/Option';

const Aside = () => {
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);

	const { asideStyle, touchStatus, hasTouchButton, asideWidthWithSpace, x } = useAsideTouch();

	const isModernDesign = process.env.REACT_APP_MODERN_DESGIN === 'true';
	const isMobile = useMediaQuery({ query: '(max-width: 991.98px)' })
	const isDesktop = !isMobile

	const constraintsRef = useRef(null);

	const [doc, setDoc] = useState(false);

	const { t } = useTranslation(['translation', 'menu']);

	const { darkModeStatus } = useDarkMode();

	const { userData } = useContext(AuthContext);
	const role = userData?.role;

	const [societes, setSocietes] = useState([])
	const [selectedName, setSelectedName] = useState("");
	const { companie, setCompanie } = useContext(CompanieContext);



	const onToggle = React.useCallback(() => {
		setAsideStatus(prev => !prev)
	}, [setAsideStatus])

	const updateCompanieContext = (values) => {
		setCompanie({
			...values
		});
	};

	useEffect(() => {
		searchCompanies(
			userData?.password,
			[['id', 'in', userData?.company_ids]],
			({ elements }) => {
				setSocietes(elements);
				const userCompany = elements.find((company) => company.id == userData?.company_id);
				updateCompanieContext(userCompany)
			},
			() => { },
			null,
			1,
			0
		);
	}, [userData?.company_ids]);

	const handleChange = (e) => {
		const selectedCompanyId = e.target.value;
		setSelectedName(selectedCompanyId);
		const userCompany = societes.find((company) => company.id == selectedCompanyId);
		updateCompanieContext(userCompany)
	};


	console.log("companie", companie)
	return (
		<>
			<motion.aside
				style={asideStyle}
				className={classNames(
					'aside',
					{ open: isDesktop || (isMobile && asideStatus) },
					{
						'aside-touch-bar': hasTouchButton && isModernDesign,
						'aside-touch-bar-close': !touchStatus && hasTouchButton && isModernDesign,
						'aside-touch-bar-open': touchStatus && hasTouchButton && isModernDesign,
					},
				)}>
				<div className='aside-head'>

					<Brand hideToggle={isDesktop} onToggle={onToggle} />
					{role?.toLowerCase() != 'technicien' && role?.toLowerCase() != 'achat' &&
						<Select
							id='company'
							size='md'
							ariaLabel='company'
							value={selectedName}
							onChange={handleChange}
							className={'bg-l10-info rounded-1'}

						>
							{societes?.map((societe) => (
								<Option key={societe?.id} value={societe?.id}>
									{societe?.name}
								</Option>
							))}
						</Select>
					}
				</div>
				<div className='aside-body'>
					{!doc && (
						<>
							{Object.entries(modules).map(([moduleId, moduleItems]) => {
								const hasModulePermission = !!moduleItems.permissions?.includes(
									userData?.role?.toLocaleLowerCase(),
								);

								if (hasModulePermission) {
									return (
										<>
											<NavigationLine />
											<ul className='navigation'>
												<NavigationTitle>
													{moduleId.replace(/_/g, ' ')}
												</NavigationTitle>
												{Object.entries(moduleItems).map(
													([submoduleId, submodule]) => {
														const hasSubmodulePermission =
															!!submodule?.permissions?.includes(
																userData?.role?.toLocaleLowerCase(),
															);

														if (hasSubmodulePermission) {
															return (
																<Navigation
																	key={submodule.id}
																	menu={[submoduleId, submodule]}
																	id='aside-demo-pages'
																/>
															);
														}

														//return null; // User doesn't have permission for this submodule
													},
												)}
											</ul>
										</>
									);
								}

								return null; // User doesn't have permission for this module
							})}

							{/* test */}

							{/* 						
							{userData?.role == 'SuperAdmin' && (
								<>
									<NavigationLine />
									<ul className='navigation'>
										<NavigationTitle>Tableau de bord</NavigationTitle>
										<Navigation menu={superAdminMenu} id='super-admin-pages' />
									</ul>
								</>
							)}
							<NavigationLine />
							<ul className='navigation'>
								<NavigationTitle>Projets & Clients</NavigationTitle>
								{(userData?.role?.toLowerCase().includes('admin') ||
									userData?.role?.toLowerCase() == 'technicien' ||
									userData?.role?.toLowerCase() == 'commercial' ||
									userData?.role?.toLowerCase() == 'achat') && (
									<Navigation menu={planningMenu} id='aside-demo-pages' />
								)}
								{(userData?.role?.toLowerCase().includes('admin') ||
									userData?.role?.toLowerCase() == 'technicien' ||
									userData?.role?.toLowerCase() == 'comptabilite' ||
									userData?.role?.toLowerCase() == 'achat' ||
									userData?.role?.toLowerCase() == 'commercial') && (
									<Navigation menu={devisMenu} id='aside-demo-pages' />
								)}
								{(userData?.role?.toLowerCase().includes('admin') ||
									userData?.role?.toLocaleLowerCase() == 'commercial') && (
									<Navigation menu={RelanceMenu} id='aside-demo-pages' />
								)}
								<Navigation
									menu={demoPages?.clientTable.subMenu}
									id='aside-demo-pages'
								/>
								<Navigation
									menu={demoPages?.ProductsPage2.subMenu}
									id='aside-demo-pages'
								/>
								{(userData?.role?.toLowerCase().includes('admin') ||
									userData?.role?.toLocaleLowerCase() == 'achat') && (
									<Navigation menu={productionMenu} id='aside-demo-pages' />
								)}
							</ul>

							<NavigationLine />
							<ul className='navigation'>
								{(userData?.role?.toLowerCase().includes('admin') ||
									userData?.role?.toLocaleLowerCase() == 'achat') && (
									<>
										<NavigationTitle>Administration & Finance</NavigationTitle>

										{userData?.role?.toLowerCase().includes('admin') && (
											<Navigation
												menu={demoPages?.EmployeeTable.subMenu}
												id='aside-demo-pages'
											/>
										)}
										{(userData?.role?.toLowerCase().includes('admin') ||
											userData?.role?.toLocaleLowerCase() == 'achat') && (
											<Navigation
												menu={demoPages?.voitureTable.subMenu}
												id='aside-demo-pages'
											/>
										)}
									</>
								)}
								<NavigationTitle>Achats</NavigationTitle>
								{(userData?.role?.toLowerCase().includes('admin') ||
									userData?.role?.toLowerCase() == 'achat') && (
									<Navigation menu={AchatsMenu} id='aside-demo-pages' />
								)}
								<Navigation menu={NoteFraisMenu} id='aside-demo-pages' />
								{(userData?.role?.toLowerCase().includes('admin') ||
									userData?.role?.toLowerCase() == 'technicien' ||
									userData?.role?.toLowerCase() == 'comptabilite') && (
									<Navigation
										menu={facturationTableMenu}
										id='facturationTableMenu'
									/>
								)}
								{(userData?.role?.toLowerCase().includes('admin') ||
									userData?.role?.toLowerCase() == 'comptabilite') && (
									<Navigation menu={relanceFactureMenu} id='relanceFactureMenu' />
								)}
								{(userData?.role?.toLowerCase().includes('admin') ||
									userData?.role?.toLowerCase() == 'achat' ||
									userData?.role?.toLowerCase() == 'comptabilite') && (
									<>
										<NavigationTitle>Charge</NavigationTitle>
										{userData?.role?.toLowerCase().includes('admin') && (
											<Navigation menu={ChargeMenu} id='aside-demo-pages' />
										)}
										{(userData?.role?.toLowerCase().includes('admin') ||
											userData?.role?.toLowerCase() == 'achat' ||
											userData?.role?.toLowerCase() == 'comptabilite') && (
											<Navigation
												menu={facturationAchatTableMenu}
												id='facturationAchatTableMenu'
											/>
										)}
									</>
								)}
							</ul>

							{(userData?.role?.toLowerCase().includes('admin') ||
								userData?.role == 'Commercial') && (
								<>
									<NavigationLine />
									<ul className='navigation'>
										<NavigationTitle>Téléphonie</NavigationTitle>

										{userData?.role?.toLowerCase().includes('admin') && (
											<Navigation
												menu={
													adminPages.telephony.subMenu.dashboardTelephony
														.subMenu
												}
												id='aside-demo-pages'
											/>
										)}

										<Navigation
											menu={adminPages.telephony.subMenu.callHistory.subMenu}
											id='aside-demo-pages'
										/>

										<Navigation
											menu={adminPages.telephony.subMenu.lines.subMenu}
											id='aside-demo-pages'
										/>

										{userData?.role?.toLowerCase().includes('admin') && (
											<Navigation
												menu={adminPages.telephony.subMenu.services.subMenu}
												id='aside-demo-pages'
											/>
										)}
									</ul>
								</>
							)} */}
						</>
					)}

					{doc && (
						<>
							<NavigationLine />
							<Navigation menu={componentsMenu} id='aside-menu-two' />
							<NavigationLine />
						</>
					)}

					{asideStatus && doc && (
						<Card className='m-3 '>
							<CardBody className='pt-0'>
								<img
									srcSet={HandWebp}
									src={Hand}
									alt='Hand'
									width={130}
									height={130}
								/>
								<p
									className={classNames('h4', {
										'text-dark': !darkModeStatus,
										'text-light': darkModeStatus,
									})}>
									{t('Everything is ready!')}
								</p>
								<Button
									color='info'
									isLight
									className='w-100'
									onClick={() => setDoc(false)}>
									{t('Demo Pages')}
								</Button>
							</CardBody>
						</Card>
					)}
				</div>
				<div className='aside-foot'>
					<nav aria-label='aside-bottom-menu'>
						<div className='navigation'>
							<div
								role='presentation'
								className='navigation-item cursor-pointer'
								onClick={() => {
									setDoc(!doc);
								}}
								data-tour='documentation'>
								{/* <span className='navigation-link navigation-link-pill'>
									<span className='navigation-link-info'>
										<Icon
											icon={doc ? 'ToggleOn' : 'ToggleOff'}
											color={doc ? 'success' : undefined}
											className='navigation-icon'
										/>
										<span className='navigation-text'>
											{t('menu:Documentation')}
										</span>
									</span>
									<span className='navigation-link-extra'>
										<Icon
											icon='Circle'
											className={classNames(
												'navigation-notification',
												'text-success',
												'animate__animated animate__heartBeat animate__infinite animate__slower',
											)}
										/>
									</span>
								</span> */}
							</div>
						</div>
					</nav>
					<User />
				</div>
			</motion.aside>
			{asideStatus && hasTouchButton && isModernDesign && (
				<>
					<motion.div className='aside-drag-area' ref={constraintsRef} />
					<Tooltips title='Toggle Aside' flip={['top', 'right']}>
						<motion.div
							className='aside-touch'
							drag='x'
							whileDrag={{ scale: 1.2 }}
							whileHover={{ scale: 1.1 }}
							dragConstraints={constraintsRef}
							// onDrag={(event, info) => console.log(info.point.x, info.point.y)}
							dragElastic={0.1}
							style={{ x, zIndex: 1039 }}
							onClick={() => x.set(x.get() === 0 ? asideWidthWithSpace : 0)}
						/>
					</Tooltips>
				</>
			)}
		</>
	);
};

export default Aside;
