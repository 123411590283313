import axios from 'axios';

const BASIC_API_URL = '/odoo',
	AUTH_BY_USER_PASSWORD_URL = process.env.REACT_APP_AUTH_BY_USER_PASSWORD_URL,
	DB = process.env.REACT_APP_AUTH_OPEN_API_DB;
const getUserToken = async (username, password, fnSuccess, fnError) => {
	var data = JSON.stringify({
		jsonrpc: '2.0',
		params: {
			db: DB,
			login: username,
			password: password,
		},
	});

	var config = {
		method: 'post',
		url: BASIC_API_URL + AUTH_BY_USER_PASSWORD_URL,
		headers: {
			'Content-Type': 'application/json',
		},
		data: data,
	};

	await axios(config)
		.then((response) => {
			fnSuccess(response.data.result[0]);
		})
		.catch((error) => {
			fnError(error);
		});
};

export default getUserToken;
