import { useContext, useEffect, useState } from 'react';

import Button from '../bootstrap/Button';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../bootstrap/Card';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../bootstrap/Modal';
import FormGroup from '../bootstrap/forms/FormGroup';
import Input from '../bootstrap/forms/Input';

import { useFormik } from 'formik';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-use';
import { getReport } from '../../common/function/functionsUtils';
import AuthContext from '../../contexts/authContext';
import DevisContext from '../../contexts/devisContext';
import DevisLinesContext, { DevisLinesContextProvider } from '../../contexts/devisLinesContext';
import {
	createDevis,
	deleteSingleDevis,
	sendDevisByMail,
	updateSingleDevis,
} from '../../odooApi/devis';
import { createDevisLine, updateDevisLine } from '../../odooApi/lineDevis';
import NavigatePopup from '../Achats/Appel/navigatePopup';
import SearchClient from '../Clients/SearchClient';
import ConfirmationPopup from '../PlanningCalendar/ConfirmationPopup';
import StatusPopup from '../PlanningCalendar/StatusPopup';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../bootstrap/Dropdown';
import Spinner from '../bootstrap/Spinner';
import ErrorPopup from '../extras/ErrorPopup';
import Icon from '../icon/Icon';
import DevisTable from './DevisTable';
import SignaturePopup from './SignaturePopup';

const DevisPopUp = ({ card, setEditModalStatus, loadData = () => {} }) => {
	const { t } = useTranslation(['translation', 'menu']);
	const { userData } = useContext(AuthContext);
	const { devis, setDevis } = useContext(DevisContext);
	const { devisLines } = useContext(DevisLinesContext);
	const [employee, setemployee] = useState([]);
	const [spinner, setSpinner] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	//const [isConfirmed, setIsConfirmed] = useState(false);
	const [confirmDelete, setConfirmDelete] = useState(false);
	const [confirmSend, setConfirmSend] = useState(false);
	const [confirmCancel, setConfirmCancel] = useState(false);
	const [remarque, setRemarque] = useState(card?.remarque);
	const [choiceOpen, setChoiceOpen] = useState(false);
	const [error, setError] = useState();
	const [errorMail, setErrorMail] = useState(false);
	const [totalPriceRemise, settotalPriceRemise] = useState(card.amount_total);
	const [remiseMontant, setRemiseMontant] = useState(card?.amount_discount);
	const [remise, setRemise] = useState(card?.discount_rate);
	const [editModalSignatureStatus, setEditModalSignatureStatus] = useState(false);
	const [dupliquerDevis, setDupliquerDevis] = useState(false);
	const [dupliquerData, setDupliquerData] = useState();
	const [devisValues, setDevisValues] = useState({});
	const [updateClientModal, setUpdateClientModal] = useState(false);
	const [searchModalStatus, setSearchModalStatus] = useState(false);
	const [selectedClient, setSelectedClient] = useState({
		nom_client: card?.nom_client,
		email_client: card?.email_client,
		mobile_client: card?.mobile_client,
		phone_client: card?.phone_client,
		street: card?.partner_id?.street,
		zip: card?.partner_id?.zip,
		city: card?.partner_id?.city,
	});
	// const [editModalDecharge, setEditModalDecharge] = useState(false);
	const [success, setSuccess] = useState(false);
	const updateDevisContext = (values) => {
		setDevis((prev) => [
			...prev.map((group) => {
				if (group.state === values.state) {
					let cardIndex = group.cards.findIndex((card) => card.id === values.id);
					if (cardIndex !== -1) {
						let newCards = [...group.cards];
						newCards[cardIndex] = values;
						return {
							...group,
							cards: newCards,
						};
					} else {
						return {
							...group,
							cards: [values, ...group.cards],
						};
					}
				}

				return {
					...group,
					cards: group.cards.filter((card) => card.id !== values.id),
				};
			}),
		]);
	};
	const handelDuplicate = () => {
		const newDevis = {
			project_title: card?.project_title || '',
			partner_id: card?.partner_id?.id,
			user_id: userData?.id,
			project_title: formik?.values?.project_title,
			techniciens: [parseInt(formik?.values?.techniciens)],
			remarque: remarque,
			amount_discount: totalPriceRemise,
			discount_rate: remise,
		};
		createDevis(
			userData?.password,
			newDevis,
			(devisData) => {
				devisLines.length > 0 &&
					devisLines?.map((item) => {
						const newLine = {
							order_id: devisData?.id,
							name: item?.name,
							product_id: item?.product_id,
							price_unit: item?.price_unit,
						};
						createDevisLine(userData?.password, newLine, () => {});
					});
				setDupliquerData(devisData);
				setDupliquerDevis(true);
			},
			(error) => {
				setError(error);
				setIsLoading(false);
			},
		);
	};
	// useEffect(
	// 	() => {
	// 		setIsLoading(true);
	// 		if (userData && userData?.id && userData?.password) {
	// 			let filter = [['job_title', 'in', ['Technicien', 'Chargé affaire', 'achat']]];
	// 			searchEmployee(
	// 				userData?.password,
	// 				filter,
	// 				({ elements }) => {
	// 					setemployee(elements);
	// 					setIsLoading(false);
	// 				},
	// 				(error) => {
	// 					setError(error);
	// 					setIsLoading(false);
	// 				},
	// 			);
	// 		}
	// 	},
	// 	() => {
	// 		setIsLoading(false);
	// 	},
	// 	[],
	// );
	const handleUpdateLine = (id, fnSuccess = () => {}) => {
		updateDevisLine(
			userData?.password,
			{
				id: id,
				discount: parseInt(remise),
			},
			() => {
				fnSuccess();
			},
			(error) => {
				setError(error);
			},
		);
	};
	const handelUpdate = (values) => {
		if (userData && userData?.id && userData?.password) {
			const newDevis = {
				id: card.id,
				project_title: values?.project_title,
				techniciens: [parseInt(values?.techniciens)],
				remarque: remarque,
				amount_discount: remiseMontant,
				discount_rate: parseInt(remise),
				amount_total: totalPriceRemise,
			};
			setSpinner(true);
			updateSingleDevis(
				userData?.password,
				newDevis,
				() => {
					updateDevisContext({
						...card,
						project_title: formik?.values?.project_title,
						remarque: remarque,
						amount_discount: remiseMontant,
						discount_rate: parseInt(remise),
						amount_total: totalPriceRemise,
					});
					if (!!remise) {
						if (devisLines?.length > 0) {
							devisLines?.map((item) => {
								handleUpdateLine(item?.id, () => {
									setEditModalStatus(false);
								});
							});
						} else {
							setEditModalStatus(false);
						}
					} else {
						loadData();
						setEditModalStatus(false);
					}
				},
				(error) => {
					setError(error);
					setSpinner(false);
				},
			);
		}
	};
	const handelDelete = () => {
		if (userData && userData?.id && userData?.password) {
			deleteSingleDevis(
				userData?.password,
				card.id,
				() => {
					// var FilterDevis = [];
					// if (userData?.role?.toLowerCase().includes('admin') || userData?.role == '') {
					// 	FilterDevis = [
					// 		'&',
					// 		[
					// 			'date_order',
					// 			'>=',
					// 			`${moment(new Date())
					// 				.startOf('month')
					// 				.format('YYYY-MM-D')} 00:00:00`,
					// 		],
					// 		[
					// 			'date_order',
					// 			'<',
					// 			`${moment(new Date()).endOf('month').format('YYYY-MM-D')} 23:59:00`,
					// 		],
					// 	];
					// } else {
					// 	FilterDevis = [
					// 		'&',
					// 		[
					// 			'date_order',
					// 			'>=',
					// 			`${moment(new Date())
					// 				.startOf('month')
					// 				.format('YYYY-MM-D')} 00:00:00`,
					// 		],
					// 		'&',
					// 		[
					// 			'date_order',
					// 			'<',
					// 			`${moment(new Date()).endOf('month').format('YYYY-MM-D')} 23:59:00`,
					// 		],
					// 		['user_id', '=', userData?.id],
					// 	];
					// }
					// searchDevis(
					// 	userData?.password,
					// 	FilterDevis,
					// 	({ elements }) => {
					// 		setDevis(organizationData(elements));
					// 		setEditModalStatus(false);
					// 		loadData();
					// 	},
					// 	(error) => {
					// 		setError(error);
					// 	},
					// );
					setEditModalStatus(false);
				},
				(error) => {
					setError(error);
				},
			);
		}
	};

	const handelChangeState = (state, fnSuccess = () => {}) => {
		if (
			state == 'sale' ||
			state == 'draft' ||
			state == 'sent' ||
			state == 'done' ||
			state == 'cancel'
		) {
			const newDevis = {
				id: card.id,
				state: state,
				remarque: remarque,
				project_title: formik?.values?.project_title,
				amount_discount: remiseMontant,
				discount_rate: remise,
				amount_total: totalPriceRemise,
			};
			if (userData && userData?.id && userData?.password) {
				updateSingleDevis(
					userData?.password,
					newDevis,
					() => {
						fnSuccess();
						updateDevisContext({
							...card,
							state: state,
							project_title: formik?.values?.project_title,
							remarque: remarque,
							amount_discount: remiseMontant,
							discount_rate: parseInt(remise),
							amount_total: totalPriceRemise,
						});
						//loadData();
						// setEditModalStatus(false);
					},
					(error) => {
						setError(error);
					},
				);
			}
		}
	};

	const formik = useFormik({
		initialValues: {
			id: card?.id,
			cardName: card.name || '',
			state: card?.state || '',
			project_title: card.project_title || '',
			partner_id: card?.partner_id,
			nom_utilisateur: card?.nom_utilisateur,
			techniciens: card?.techniciens[0]?.id || userData?.employee,
			remarque: remarque,
			amount_discount: remiseMontant,
			discount_rate: parseInt(remise),
		},
		onSubmit: (values) => {
			handelUpdate(values);
		},
	});

	useEffect(() => {
		setDevisValues({ discount_rate: parseInt(remise), remarque: remarque });
	}, [card, remarque]);

	const handelSelect = ({ value, selected }) => {
		const newClient = selected?.client;

		setSelectedClient({
			nom_client: newClient?.name,
			email_client: newClient?.email,
			mobile_client: newClient?.mobile,
			phone_client: newClient?.phone,
			street: newClient?.street,
			zip: newClient?.zip,
			city: newClient?.city,
		});
		//setDevisValues({ ...devisValues, ...value });
		//setSearchModalStatus(false);
	};

	const handleUpdate = (result) => {
		card.partner_id = result;
	};

	const handelUpdateAuto = () => {
		try {
			if (
				!!(
					devisValues?.discount_rate != parseInt(remise) ||
					card?.remarque != remarque ||
					card?.project_title != formik?.values?.project_title
				)
			) {
				const newDevis = {
					id: card?.id,
					project_title: formik?.values?.project_title,
					remarque: remarque,
					amount_discount: remiseMontant,
					discount_rate: parseInt(remise),
					amount_total: totalPriceRemise,
				};

				updateSingleDevis(userData?.password, newDevis, () => {
					updateDevisContext({
						...card,
						project_title: formik?.values?.project_title,
						remarque: remarque,
						amount_discount: remiseMontant,
						discount_rate: parseInt(remise),
						amount_total: totalPriceRemise,
					});
					if (!!remise) {
						if (devisLines?.length > 0) {
							devisLines?.map((item) => {
								handleUpdateLine(item?.id, () => {
									setEditModalStatus(false);
								});
							});
						} else {
							setEditModalStatus(false);
						}
					} else {
						setEditModalStatus(false);
					}

					// Trigger the download after the potential update
				});
			}
		} catch (error) {
			setError(error);
		}
	};

	const isValidEmail = (email) => {
		// Regular expression for a basic email format check
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	};

	const [spinnerSend, setSpinnerSend] = useState(false);
	const handelSend = async () => {
		setSpinnerSend(true);

		try {
			// Check if email address is not empty
			if (!card?.email_client) {
				setSpinnerSend(false);
				setErrorMail('E-mail est vide.');
				return;
			}

			// Check if the email address is in a valid format
			if (!isValidEmail(card.email_client)) {
				setSpinnerSend(false);
				setErrorMail("Format d'email non valide.");
				return;
			}

			if (
				!!(
					devisValues?.discount_rate != parseInt(remise) ||
					card?.remarque != remarque ||
					card?.project_title != formik?.values?.project_title
				)
			) {
				const newDevis = {
					id: card?.id,
					project_title: formik?.values?.project_title,
					remarque: remarque,
					amount_discount: remiseMontant,
					discount_rate: parseInt(remise),
					amount_total: totalPriceRemise,
				};

				setSpinner(true);
				updateSingleDevis(userData?.password, newDevis, () => {
					if (!!remise) {
						if (devisLines?.length > 0) {
							devisLines?.map((item) => {
								handleUpdateLine(item?.id);
							});
						}
					}
				});
				updateDevisContext({
					...card,
					project_title: formik?.values?.project_title,
					remarque: remarque,
					amount_discount: remiseMontant,
					discount_rate: parseInt(remise),
					amount_total: totalPriceRemise,
				});
			}

			sendDevisByMail(userData?.password, card?.id);

			if (card?.state !== 'done') {
				handelChangeState('sent');
			}

			setSuccess('E-mail envoyé à ' + card?.email_client);
			setSpinnerSend(false);
		} catch (error) {
			setSpinnerSend(false);
			setError(error);
		}
	};

	// const handelSendMailOnly = () => {
	// 	if (userData && userData?.id && userData?.password) {
	// 		setSpinnerSend(true);
	// 		sendDevisByMail(
	// 			userData?.password,
	// 			card?.id,
	// 			() => {
	// 				setSuccess('E-mail envoyé avec succès');
	// 				setSpinnerSend(false);
	// 			},
	// 			(error) => {
	// 				setError(error);
	// 				setSpinnerSend(false);
	// 			},
	// 		);
	// 	}
	// };
	const isSmall = useMedia('(max-width : 768px)');

	return (
		<>
			<Modal setIsOpen={() => {}} isOpen={true} size='xl' isScrollable classNameMd='nv-2'>
				<ModalHeader
					className='px-4'
					setIsOpen={() => {
						setEditModalStatus(false);
						handelUpdateAuto();
					}}>
					<div className='d-flex justify-content-between w-100'>
						<ModalTitle id='project-edit'>
							{card.name} {' - '} {t(card?.state)}
						</ModalTitle>

						<Button
							icon='AddBox'
							isLight
							color='success'
							onClick={() => handelDuplicate()}>
							Dupliquer ce devis
						</Button>
					</div>
				</ModalHeader>
				<ModalBody className='px-4'>
					<div className='row'>
						<div className='col-12'>
							<div className='col-lg-6'></div>

							<Card shadow='sm' isCompact>
								<CardHeader>
									<CardLabel icon='Info' iconColor='primary'>
										<CardTitle>Informations</CardTitle>
									</CardLabel>
								</CardHeader>
								<CardBody>
									<div className='row g-4'>
										<div className='col-lg-6 g-4 '>
											{card?.state == 'done' ? (
												<>
													<div className='d-flex align-items-center mt-3'>
														<div className='flex-shrink-0'>
															<Icon
																icon='Assistant'
																size='2x'
																color='primary'
															/>
														</div>
														<div className='flex-grow-1 ms-3'>
															<div className='text-muted'>
																Titre de devis
															</div>
															<div className='fw-bold fs-6 mb-0'>
																{card?.project_title}
															</div>
														</div>
													</div>
													{/* {!!card?.remarque && (
														<div className='d-flex align-items-center mt-3'>
															<div className='flex-shrink-0'>
																<Icon
																	icon='StickyNote2'
																	size='2x'
																	color='primary'
																/>
															</div>
															<div className='flex-grow-1 ms-3'>
																<div className='text-muted'>
																	Notes
																</div>
																<div className='fw-bold fs-6 mb-0'>
																	{card?.remarque}
																</div>
															</div>
														</div>
													)} */}

													{/* <div className='d-flex align-items-center mt-3'>
														<div className='flex-shrink-0'>
															<Icon
																icon='AppRegistration'
																size='2x'
																color='primary'
															/>
														</div>
														<div className='flex-grow-1 ms-3'>
															<span className='fw-bold fs-6 mb-0'>
																{card?.decharge ? 'Avec' : 'Sans'}
															</span>
															<span className='ms-2 text-muted'>
																décharge
															</span>
														</div>
													</div> */}
												</>
											) : (
												<>
													<FormGroup id='project_title' className='mb-2'>
														<div className='text-muted ms-2'>
															Titre de devis
														</div>
														<Input
															onChange={formik.handleChange}
															value={formik.values.project_title}
															name='project_title'
															disabled={card?.state == 'done'}
														/>
													</FormGroup>

													{/* <Checks
														className='fw-bold fs-6'
														type='switch'
														id='decharge'
														label='Décharge'
														name='decharge'
														onChange={formik.handleChange}
														checked={formik.values.decharge}
													/> */}
												</>
											)}
											<div className='py-2 d-flex align-items-center'>
												<div className='flex-shrink-0'>
													<Icon
														icon='AssignmentInd'
														size='2x'
														color='primary'
													/>
												</div>
												<div className='flex-grow-1 ms-3'>
													<div className='text-muted'>Responsable</div>
													<div className='fw-bold fs-6 mb-0'>
														{formik.values.nom_utilisateur}
													</div>
												</div>
											</div>
											<div className='py-2 d-flex align-items-center'>
												<div className='flex-shrink-0'>
													<Icon icon='Today' size='2x' color='primary' />
												</div>
												<div className='flex-grow-1 ms-3'>
													<div className='text-muted'>Date</div>
													<div className='fw-bold fs-6 mb-0'>
														{moment(card?.date_order).format(
															'DD-MM-YYYY',
														)}
													</div>
												</div>
											</div>
										</div>
										<div className='col-lg-6 g-4'>
											{!!selectedClient?.nom_client && (
												<div className='row py-2'>
													<div className='col-lg-8 col-md-8'>
														<div className='d-flex align-items-center'>
															<div className='flex-shrink-0'>
																<Icon
																	icon='AccountCircle'
																	size='2x'
																	color='primary'
																/>
															</div>
															<div className='flex-grow-1 ms-3'>
																<div className='text-muted'>
																	Nom du client
																</div>
																<div className='fw-bold fs-6 mb-0'>
																	{selectedClient?.nom_client}
																</div>
															</div>
														</div>
													</div>
													<div className='col-lg-4 col-md-4 text-md-end'>
														<Button
															icon='Edit'
															isLight
															color='success'
															onClick={() =>
																setUpdateClientModal(true)
															}>
															Modifier client
														</Button>
													</div>
												</div>
											)}
											{!!selectedClient?.email_client && (
												<div className='py-2 d-flex align-items-center'>
													<div className='flex-shrink-0'>
														<Icon
															icon='Mail'
															size='2x'
															color='primary'
															isLight
														/>
													</div>
													<div className='flex-grow-1 ms-3'>
														<div className='text-muted'>
															E-mail client
														</div>
														<div className='fw-bold fs-6 mb-0'>
															{selectedClient?.email_client}
														</div>
													</div>
												</div>
											)}
											{!!selectedClient?.mobile_client && (
												<div className='py-2 d-flex align-items-center'>
													<div className='flex-shrink-0'>
														<Icon
															icon='PhoneAndroid'
															size='2x'
															color='primary'
														/>
													</div>

													<div className='flex-grow-1 ms-3'>
														<div className='text-muted'>
															N° Tél client
														</div>
														<div className='fw-bold fs-6 mb-0'>
															{selectedClient?.mobile_client}
														</div>
													</div>
												</div>
											)}

											{!!selectedClient?.phone_client && (
												<div className='py-2 d-flex align-items-center'>
													<div className='flex-shrink-0'>
														<Icon
															icon='Phone'
															size='2x'
															color='primary'
														/>
													</div>
													<div className='flex-grow-1 ms-3'>
														<div className='text-muted'>
															N° Tél client
														</div>
														<div className='fw-bold fs-6 mb-0'>
															{selectedClient?.phone_client}
														</div>
													</div>
												</div>
											)}
											{!!selectedClient?.street && (
												<div className='py-2 d-flex align-items-center'>
													<div className='flex-shrink-0'>
														<Icon
															icon='Home'
															size='2x'
															color='primary'
														/>
													</div>

													<div className='flex-grow-1 ms-3'>
														<div className='text-muted'>Adresse</div>
														<div className='fw-bold fs-6 mb-0'>
															{selectedClient?.street}{' '}
															{selectedClient?.zip}{' '}
															{selectedClient?.city}
														</div>
													</div>
												</div>
											)}
										</div>

										{/* <FormGroup className='col-6' id='cardName' label='Nom'>
											<Input
												onChange={formik.handleChange}
												value={formik.values.cardName}
												disabled
											/>
										</FormGroup> */}
										{/* <div className='col-lg-8 row g-4'>
											<div className='col-lg-6 col-12'>
												
											</div>
											<div className='col-lg-6 col-12'>
												
											</div>
											
											
										</div>
										<div className='col-lg-4'>
											
											
										</div> */}

										{/* <FormGroup className='col-6' id='groupId' label='Statut'>
											<Input value={t(formik.values.state)} disabled />
										</FormGroup> */}
									</div>
								</CardBody>
							</Card>
						</div>
						{/* <div className='col-lg-8 col-12'>
							<Card className='sm'>
								<CardHeader>
									<CardLabel icon='AccountCircle' iconColor='primary'>
										<CardTitle>{t('Client Information')}</CardTitle>
									</CardLabel>
								</CardHeader>
								<CardBody>
									<div className='row g-3'>
										{card?.nom_client && (
											<div className='col-12 text-center'>
												<h2>{card?.nom_client}</h2>
											</div>
										)}
										<div className='col-auto'>
											<div className='d-flex align-items-center'>
												<div className='flex-shrink-0'>
													<Icon icon='Mail' size='2x' color='primary' />
												</div>
												<div className='flex-grow-1 ms-3'>
													<div className='fw-bold fs-6 mb-0'>
														{card?.email_client || 'xxxxxx@xxxx.xx'}
													</div>
													<div className='text-muted'>
														{t('Email Address')}
													</div>
												</div>
											</div>
										</div>
										{!!card?.mobile_client && (
											<div className='col-auto'>
												<div className='d-flex align-items-center'>
													<div className='flex-shrink-0'>
														<Icon
															icon='PhoneAndroid'
															size='2x'
															color='primary'
														/>
													</div>

													<div className='flex-grow-1 ms-3'>
														<div className='fw-bold fs-6 mb-0'>
															{card?.mobile_client}
														</div>
														<div className='text-muted'>
															{t('Client mobile')}
														</div>
													</div>
												</div>
											</div>
										)}
										{!!card?.phone_client && (
											<div className='col-auto'>
												<div className='d-flex align-items-center'>
													<div className='flex-shrink-0'>
														<Icon
															icon='Phone'
															size='2x'
															color='primary'
														/>
													</div>
													<div className='flex-grow-1 ms-3'>
														<div className='fw-bold fs-6 mb-0'>
															{card?.phone_client}
														</div>
														<div className='text-muted'>
															{t('Client phone')}
														</div>
													</div>
												</div>
											</div>
										)}

										<div className='col-auto'>
											<img
												src={
													card?.signature &&
													userData?.password &&
													imageSignature(userData?.password, card.id)
												}
												width={400}
											/>
										</div>
									</div>
								</CardBody>
							</Card>
						</div> */}
						<div className='col-md-12'>
							{card?.id && (
								<DevisTable
									cardId={card?.id}
									card={card}
									totalPriceRemise={totalPriceRemise}
									settotalPriceRemise={settotalPriceRemise}
									remise={remise}
									setRemise={setRemise}
									remarque={remarque}
									setRemarque={setRemarque}
									remiseMontant={remiseMontant}
									setRemiseMontant={setRemiseMontant}
									isDisabled={card?.state != 'draft' && card?.state != 'sent'}
								/>
							)}
						</div>
					</div>
				</ModalBody>

				<ModalFooter className='px-4 pb-4'>
					{!!devisLines?.length && (
						<Dropdown>
							<DropdownToggle>
								<Button color='success' isLight>
									Option
								</Button>
							</DropdownToggle>
							<DropdownMenu>
								{(card?.state == 'draft' || card?.state == 'sent') && (
									<DropdownItem>
										<Button
											icon='save'
											className='text-nowrap'
											type='submit'
											onClick={formik.handleSubmit}
											isDisable={spinner}>
											{spinner && <Spinner isSmall inButton />}
											{spinner ? 'En cours' : 'Enregistrer'}
										</Button>
									</DropdownItem>
								)}
								{card?.state != 'cancel' && !!devisLines?.length && (
									<DropdownItem>
										<Button
											icon='ScheduleSend'
											className='text-nowrap'
											isDisable={spinnerSend}
											onClick={handelSend}>
											{spinnerSend && <Spinner isSmall inButton />}
											{spinnerSend
												? 'En cours'
												: `${
														!!(
															devisValues?.discount_rate !=
																parseInt(remise) ||
															devisValues?.remarque != remarque
														)
															? 'Envoyer'
															: 'Envoyer'
												  }`}
										</Button>
										{/* <Button
											icon='ScheduleSend'
											className='text-nowrap'
											onClick={handelSend}
											isDisable={spinnerSend}>
											{spinnerSend && <Spinner isSmall inButton />}
											{spinnerSend ? 'En cours' : 'Envoyer'}
										</Button> */}
									</DropdownItem>
								)}
								{!!devisLines?.length && (
									<DropdownItem>
										<Button
											tag='a'
											icon='FileDownload'
											className='text-nowrap'
											onClick={() => {
												handelUpdateAuto();
												window.open(
													getReport(
														userData?.password,
														card?.id,
														'sale.order',
													),
													'_blank',
												);
											}}>
											{t('Print')}
										</Button>
										{/* <Button
											tag='a'
											icon='FileDownload'
											className='text-nowrap'
											//onClick={() => handelClose();}
											href={getReport(
												userData?.password,
												card?.id,
												'sale.order',
											)}
											target='_blank'>
											{t('Print')}
										</Button> */}
									</DropdownItem>
								)}

								{card?.state != 'cancel' && card?.state != 'done' && (
									<>
										{card?.state != 'sale' && !!devisLines?.length && (
											<DropdownItem>
												<Button
													icon='Verified'
													className='text-nowrap'
													onClick={() => {
														handelUpdateAuto();
														setEditModalSignatureStatus(true);
													}}
													//onClick={() => setChoiceOpen(true)}
												>
													{t('Confirm')}
												</Button>
											</DropdownItem>
										)}

										{card?.state == 'sale' && (
											<DropdownItem>
												<Button
													icon='DoneAll'
													className='text-nowrap'
													onClick={() => handelChangeState('done')}>
													{t('Done')}
												</Button>
											</DropdownItem>
										)}

										<DropdownItem>
											<Button
												icon='Cancel'
												iconColor='danger'
												className='text-nowrap'
												onClick={() => {
													setConfirmCancel(true);
												}}>
												{t('Cancel')}
											</Button>
										</DropdownItem>
									</>
								)}
							</DropdownMenu>
						</Dropdown>
					)}
					{/* {(card?.state == 'draft' || card?.state == 'sent') && (
						<Button
							color='primary'
							icon='save'
							className='text-nowrap'
							type='submit'
							onClick={formik.handleSubmit}
							isDisable={spinner}>
							{spinner && <Spinner isSmall inButton />}
							{spinner ? 'En cours' : 'Enregistrer'}
						</Button>
					)}
					{card?.state != 'cancel' && !!devisLines?.length && (
						<Button
							color='success'
							icon='ScheduleSend'
							className='text-nowrap'
							onClick={handelSend}
							isDisable={spinnerSend}>
							{spinnerSend && <Spinner isSmall inButton />}
							{spinnerSend ? 'En cours' : 'Envoyer'}
						</Button>
					)} */}
					{/* {card?.state == 'done' && (
						<Button
							color='success'
							icon='ScheduleSend'
							className='text-nowrap'
							onClick={handelSendMailOnly}
							isDisable={spinnerSend}>
							{spinnerSend && <Spinner isSmall inButton />}
							{spinnerSend ? 'En cours' : 'Envoyer'}
						</Button>
					)} */}
					{/* {!!devisLines?.length && (
						<>
							<Button
								tag='a'
								color='primary'
								icon='FileDownload'
								className='text-nowrap'
								href={getReport(userData?.password, card?.id, 'sale.order')}
								target='_blank'>
								{t('Print')}
							</Button>
						</>
					)}

					{card?.state != 'cancel' && card?.state != 'done' && (
						<>
							{card?.state != 'sale' && !!devisLines?.length && (
								<Button
									color='success'
									icon='Verified'
									className='text-nowrap'
									onClick={() => setEditModalSignatureStatus(true)}
									//onClick={() => setChoiceOpen(true)}
								>
									{t('Confirm')}
								</Button>
							)}

							{card?.state == 'sale' && (
								<Button
									color='info'
									icon='DoneAll'
									className='text-nowrap'
									onClick={() => handelChangeState('done')}>
									{t('Done')}
								</Button>
							)}
							<Button
								color='warning'
								icon='Cancel'
								className='text-nowrap'
								onClick={() => {
									setConfirmCancel(true);
								}}>
								{t('Cancel')}
							</Button>
						</>
					)} */}
					{/* <Button
							color='danger'
							className='w-100'
							onClick={() => {
								setConfirmDelete(true);
							}}>
							{t('Delete')}
						</Button> */}
				</ModalFooter>
			</Modal>
			{!!confirmCancel && (
				<ConfirmationPopup
					handleConfirm={() =>
						handelChangeState('cancel', () => {
							setEditModalStatus(false);
						})
					}
					setOpen={setConfirmCancel}
					title={'Annuler le devis ?'}
				/>
			)}

			{updateClientModal && (
				<SearchClient
					setCreateClientModalStatus={setUpdateClientModal}
					handelSelect={handelSelect}
					data={card?.partner_id}
					handleUpdate={handleUpdate}
				/>
			)}
			{/* {!!confirmSend && (
				<ConfirmationPopup
					handleConfirm={handelSend}
					setOpen={setConfirmSend}
					title={'Envoyer le devis ?'}
				/>
			)} */}

			{!!confirmDelete && (
				<ConfirmationPopup
					handleConfirm={handelDelete}
					setOpen={setConfirmDelete}
					title='Supprimer le devis ?'
				/>
			)}
			{!!error && <ErrorPopup error={error} setOpen={setError} />}

			{/* {!!spinnerSend && (
				<StatusPopup
					icon='MarkEmailRead'
					color='success'
					label={'E-mail envoyé avec succès!'}
					setOpen={setSpinnerSend}
				/>
			)} */}

			{!!success && (
				<StatusPopup
					icon='MarkEmailRead'
					color='success'
					title='E-mail envoyé avec succès'
					label={success}
					setOpen={(param) => {
						//loadData();
						setSuccess(param);
						setEditModalStatus(param);
					}}
				/>
			)}
			{!!errorMail && (
				<StatusPopup icon='info' color='info' title={errorMail} setOpen={setErrorMail} />
			)}
			{choiceOpen && (
				<NavigatePopup
					icon='ContactSupport'
					color='primary'
					setOpen={setChoiceOpen}
					title='Decharge Client'
					label='Decharge le client ?'
					btn1='Non'
					btn2='Oui'
					fn1={() => {
						setDecharge(false);
						setEditModalSignatureStatus(true);
					}}
					fn2={() => {
						setDecharge(true);
						setEditModalSignatureStatus(true);
					}}
				/>
			)}
			{editModalSignatureStatus && (
				<SignaturePopup
					setEditModalStatus={setEditModalSignatureStatus}
					Devis={card}
					remarque={remarque}
					handleUpdate={() => {
						//loadData();
						setEditModalStatus(false);
					}}
				/>
			)}

			{dupliquerDevis && (
				<DevisLinesContextProvider>
					<DevisPopUp card={dupliquerData} setEditModalStatus={setDupliquerDevis} />
				</DevisLinesContextProvider>
			)}

			{/* {editModalDecharge && (<DechargeDevis setOpen={setEditModalDecharge} devis={card} />)} */}
		</>
	);
};

// DevisPopUp.propTypes = {
// 	// eslint-disable-next-line react/forbid-prop-types
// 	// @ts-ignore
// 	card: PropTypes.object.isRequired,
// 	// eslint-disable-next-line react/forbid-prop-types
// 	setEditModalStatus: PropTypes.func.isRequired,
// };

export default DevisPopUp;
