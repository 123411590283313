import formInputsTypes from '../simulatorConstant';
import forfaits from './data';

const FORFAIT_PRODUCT_ID = 330;

const data = {
	id: 'data_forfait',
	type: formInputsTypes?.RADIO_INPUT,
	label: '',
	name: 'data_forfait',
	className: 'col-6 d-none',
	disabled: true,
	nextStepData: false,
	nextStep: false,
	options: null,
	fetchData: (tokenUserCurent, currentFormValue, fnSuccess) => {
		let desc_data = '';
		let prix = 0;
		if (currentFormValue?.ForfaitsSM?.length >= 1) {
			currentFormValue?.ForfaitsSM?.map((option) => {

				desc_data += '\n - ' + option.label;
				prix += option.lst_price;
			});
		}

		const generateDescription = () => {
			return desc_data;
		};
		const options = [
			{
				id: 'FrC_oui',
				product_id: FORFAIT_PRODUCT_ID,
				description: generateDescription(),
				label: '',
				value: 'oui',
				disabled: true,
				lst_price: prix,
				nextStep: false,
				checked: true,
			},
		];

		fnSuccess(options);
	},
};

const ForfaitsSM = {
	id: 'ForfaitsSM',
	type: formInputsTypes?.CHECKBOX_INPUT,
	label: 'ForfaitsSM',
	name: 'ForfaitsSM',
	className: 'col-12',
	classGroup: 'simulator-check',
	nextStepData: data,
	nextStep: false,
	options: forfaits,
};

export default ForfaitsSM;
