import formInputsTypes from '../../simulatorConstant';
export const CATEG_ID_MOTEUR_RIDEAU = 17;
import { searchProduct } from '../../../../../odooApi/product';
import {
	AxeManuelRM,
	AxeMotoriseRM,
	bobineRM,
	BoiteRM,
	EnroulementRM,
	MesureHauteurRM,
	MesureLargeurRM,
	platBombeRM,
	PoseRM,
	sortieCableRM,
} from '../data';

const ButeeRMData = {
	id: 'ButeeRM',
	type: formInputsTypes?.RADIO_INPUT,
	name: 'ButeeRM',
	className: 'col-6 d-none',
	label: 'Butées',
	nextStepData: false,
	nextStep: false,
	fetchData: (tokenUserCurent, currentFormValue, fnSuccess) => {
		const generateDescription = (data) => {
			let desc_data = '';
			if (currentFormValue?.BoiteRMData?.length >= 1) {
				currentFormValue?.BoiteRMData?.map((option) => {
					desc_data += '\n - ' + option.label;
				});
			}
			return `axe de rideau métallique motorisé ${currentFormValue?.AxeMotoriseRMData
					? '\n \n Axe motorisé compensé par des ressorts  ' +
					'\n Moteur ' +
					currentFormValue?.AxeMotoriseRMData?.value +
					" 220 Volts \n Dépannage de secours par débrayage de l'électro frein \n Câble et poignée de dépannage compris \n \n"
					: ''
				}${currentFormValue?.AxeMRMData
					? '\n - Axe diamètre ' + currentFormValue?.AxeMRMData?.value
					: ''
				}${currentFormValue?.MoteurRMData
					? '\n - ' + currentFormValue?.MoteurRMData?.label
					: ''
				}${currentFormValue?.BoiteRMData ? desc_data : ''}${currentFormValue?.Enroulement
					? '\n - Enroulement ' + currentFormValue?.Enroulement?.value
					: ''
				}${currentFormValue?.platBombe ? '\n - ' + currentFormValue?.platBombe?.value : ''}${currentFormValue?.NbAgrafesRM
					? '\n - ' + currentFormValue?.NbAgrafesRM + ' agrafes'
					: ''
				}${currentFormValue?.bobine
					? '\n - ' + currentFormValue?.bobine?.value + ' bobines'
					: ''
				}${currentFormValue?.PoseRM ? '\n - ' + currentFormValue?.PoseRM?.value : ''}${currentFormValue?.largeurRM
					? '\n - Largeur: ' +
					currentFormValue?.largeurRM +
					'mm (' +
					currentFormValue?.MesureLargeur?.value +
					')'
					: ''
				} ${currentFormValue?.hauteurRM
					? ' x Hauteur: ' +
					currentFormValue?.hauteurRM +
					'mm (' +
					currentFormValue?.MesureHauteur?.value +
					')'
					: ''
				} `;
		};

		const options = [
			{
				id: 'RMSBUTEE',
				label: 'Sans butées',
				value: 'sans butées',
				product_id: currentFormValue?.RMData?.id,
				description: generateDescription('RMSBUTEE'),
				checked: true,
				nextStep: false,
			},
			{
				id: 'RMABUTEE',
				label: 'Avec butées',
				value: 'avec butées',
				product_id: currentFormValue?.RMData?.id,
				description: generateDescription('RMABUTEE'),
				nextStep: false,
			},
		];

		fnSuccess(options);
	},
};

const NbAgrafesRMData = {
	id: 'NbAgrafesRM',
	type: formInputsTypes?.INPUT_NUMBER,
	name: 'NbAgrafesRM',
	className: 'col-6',
	label: "Nombre d'agrafes",
	nextStepData: ButeeRMData,
	nextStep: false,
};

const EnroulementData = {
	id: 'RMENROULEMENT',
	type: formInputsTypes?.RADIO_INPUT,
	name: 'Enroulement',
	className: 'col-6',
	label: 'Enroulement',
	nextStepData: NbAgrafesRMData,
	nextStep: false,
	options: EnroulementRM,
};

const MesureHauteurRMData = {
	id: 'RMH',
	type: formInputsTypes?.RADIO_INPUT,
	name: 'MesureHauteur',
	className: 'col-6',
	label: 'Mesure selon hauteur',
	nextStepData: EnroulementData,
	nextStep: false,
	options: MesureHauteurRM,
};

const hauteurRM = {
	id: 'HRM',
	type: formInputsTypes?.INPUT_NUMBER,
	name: 'hauteurRM',
	className: 'col-6',
	label: "Hauteur de l'axe (mm)",
	nextStepData: MesureHauteurRMData,
	nextStep: false,
	validation: (value) => {
		return value > 0;
	},
};

const MesureLargeurRMData = {
	id: 'RM-TML',
	type: formInputsTypes?.RADIO_INPUT,
	name: 'MesureLargeur',
	className: 'col-6',
	label: 'Mesure selon largeur',
	nextStepData: hauteurRM,
	nextStep: false,
	options: MesureLargeurRM,
};

const largeurRM = {
	id: 'LRM',
	type: formInputsTypes?.INPUT_NUMBER,
	name: 'largeurRM',
	className: 'col-6',
	label: "Largeur de l'axe (mm)",
	nextStepData: MesureLargeurRMData,
	nextStep: false,
	validation: (value) => {
		return value > 0;
	},
};

const PoseRMData = {
	id: 'PoseRM',
	type: formInputsTypes?.SELECT_INPUT,
	label: 'Pose',
	name: 'PoseRM',
	className: 'col-6',
	nextStepData: largeurRM,
	nextStep: false,
	options: PoseRM,
};

const sortieCable = {
	id: 'sortieCable',
	type: formInputsTypes?.SELECT_INPUT,
	label: 'Sortie du câble',
	name: 'sortieCable',
	className: 'col-6',
	nextStepData: PoseRMData,
	nextStep: false,
	options: sortieCableRM,
};

const bobine = {
	id: 'bobines',
	type: formInputsTypes?.SELECT_INPUT,
	label: 'Bobines',
	name: 'bobines',
	className: 'col-6',
	nextStepData: PoseRMData,
	nextStep: false,
	options: bobineRM,
};

const platBombe = {
	id: 'platBombe',
	type: formInputsTypes?.RADIO_INPUT,
	label: 'Plat Bombé',
	name: 'platBombe',
	className: 'col-6',
	nextStepData: bobine,
	nextStep: false,
	options: platBombeRM,
};

const AxeManuelData = {
	id: 'AxeMRMData',
	type: formInputsTypes?.SELECT_INPUT,
	label: "Type d'axe",
	name: 'AxeMRMData',
	className: 'col-6',
	nextStepData: platBombe,
	nextStep: false,
	options: AxeManuelRM,
};

const BoiteRMData = {
	id: 'BoiteRMData',
	type: formInputsTypes?.CHECKBOX_INPUT,
	label: 'Boite',
	name: 'BoiteRMData',
	className: 'col-12',
	nextStepData: AxeManuelData,
	nextStep: false,
	options: BoiteRM,
};

const MoteurRMData = {
	id: 'MoteurRMData',
	type: formInputsTypes?.SELECT_INPUT,
	label: 'Moteur',
	name: 'MoteurRMData',
	className: 'col-6',
	nextStepData: BoiteRMData,
	nextStep: false,
	options: null,
	fetchData: (tokenUserCurent, currentFormValue, fnSuccess) => {
		const generateFilter = () => {
			const filters = [['categ_id', '=', CATEG_ID_MOTEUR_RIDEAU]];

			return filters;
		};
		searchProduct(tokenUserCurent, generateFilter(), async ({ elements }) => {
			const options = elements?.length
				? elements.map((item) => {
					const { product_template_attribute_value_ids } = item;
					let name = item?.display_name + ' ';
					product_template_attribute_value_ids.map((i) => (name += i.name + ' '));
					console.log('name', name);
					return {
						id: item?.id,
						label: name,
						value: item?.id,
					};
				})
				: [];
			fnSuccess(options);
		});
	},
};

const AxeMotoriseRMData = {
	id: 'AxeMotoriseRMData',
	type: formInputsTypes?.SELECT_INPUT,
	label: 'Motorisation',
	name: 'AxeMotoriseRMData',
	className: 'col-6',
	nextStepData: MoteurRMData,
	nextStep: false,
	options: AxeMotoriseRM,
};

export default AxeMotoriseRMData;
