import {
	CATEG_ID_AXE,
	CATEG_ID_TANDEM,
	MIN_WIDTH,
	PRODUCT_ID_AXE_MOTORISE_TRADI,
	PRODUCT_ID_COUSSINET,
	PRODUCT_ID_EQUERRE,
	PRODUCT_ID_JAMBE,
} from '..';
import { searchCategory } from '../../../../../../../odooApi/categoryProdut';
import { getSingleProduct, searchProduct } from '../../../../../../../odooApi/product';
import { prix_AxeMotoriseTradi } from '../../../../../CalculSimulateurData.js/Axe_Motorise';
import formInputsTypes from '../../../../simulatorConstant';

export const CATEG_ID_VERROU_SOUPLE = 151;
export const CATEG_ID_VERROU_REGIDE = 152;
export const CATEG_ID_MOTEURS = 202;
export const CATEG_ID_SUPPORT = 22;

import Becker from '../../../../../../../assets/img/stock/becker.jpg';
import a_ok from '../../../../../../../assets/img/stock/A-OK.png';
import Somfy from '../../../../../../../assets/img/stock/Somfy.png';
import Bubendorff from '../../../../../../../assets/img/stock/Bubendorff.png';
import Eckermann from '../../../../../../../assets/img/stock/Eckermann.png';
import Gaposa from '../../../../../../../assets/img/stock/Gaposa.png';


//type moteur
import IO from '../../../../../../../assets/img/stock/IO.png';
import RTS from '../../../../../../../assets/img/stock/RTS.png';
import Radio from '../../../../../../../assets/img/stock/Radio.png';
import Filaire from '../../../../../../../assets/img/stock/Filaire.png';
import Solaire from '../../../../../../../assets/img/stock/Solaire.png';

import avec_telecommande from '../../../../../../../assets/img/stock/telecommandes.png';
import sans_telecommande from '../../../../../../../assets/img/stock/sans_tele.png';

const agraffe = {
	id: 'agraffe',
	type: formInputsTypes?.SELECT_INPUT,
	label: 'Agraffe',
	name: 'agraffe',
	nextStepData: false,
	nextStep: false,
	fetchData: (tokenUserCurent, currentFormValue, fnSuccess) => {
		let price = 0;

		const m2 = (currentFormValue?.largeur * currentFormValue?.hauteur) / 1000;
		if (m2 <= prix_AxeMotoriseTradi[0]?.id) {
			price = prix_AxeMotoriseTradi[0]?.price;
		} else if (m2 > prix_AxeMotoriseTradi?.length?.id) {
			price = prix_AxeMotoriseTradi[prix_AxeMotoriseTradi?.length].price;
		} else {
			for (var i = 0; i < prix_AxeMotoriseTradi.length - 1; i++) {
				// console.log('prix', prix[i]);
				if (m2 > prix_AxeMotoriseTradi[i]?.id && m2 <= prix_AxeMotoriseTradi[i + 1]?.id) {
					price = prix_AxeMotoriseTradi[i + 1]?.price;
				}
			}
		}
		const generateFilter = () => {
			const filters = [['categ_id', '=', currentFormValue?.typeAgraffe?.value]];

			return filters;
		};
		searchProduct(tokenUserCurent, generateFilter(), ({ elements }) => {
			getSingleProduct(PRODUCT_ID_EQUERRE, tokenUserCurent, (dataEquerre) => {
				getSingleProduct(PRODUCT_ID_JAMBE, tokenUserCurent, (dataJambe) => {
					getSingleProduct(PRODUCT_ID_COUSSINET, tokenUserCurent, (dataCoussinet) => {
						const generateDescription = (item) => {
							return (
								`${currentFormValue?.typeAxe?.value} équipé d'un moteur fiable et silencieux qui permet de l'ouvrir et de le fermer en douceur et en silence. \n Fabriqué en aluminium de haute qualité conformement à la norme européenne EN 13659 \n \n` +
								`\n- Axe: ${currentFormValue?.axe?.label}` +
								`\n- Moteur: ${currentFormValue?.motors?.label}` +
								`\n${!!currentFormValue?.telecommande?.label
									? '- Telecommande:' + currentFormValue?.telecommande?.label
									: ''
								}` +
								// `\n- Tandem: ${currentFormValue?.tandem?.label}` +
								// `\n- Support: ${currentFormValue?.supportMotors?.label}` +
								// `\n- Equerre: ${dataEquerre?.display_name}` +
								// `\n- Coussinet: ${dataCoussinet?.display_name}` +
								// `\n- Jambe de force: ${dataJambe?.display_name}` +
								`\n- Verrou: ${Math.ceil(currentFormValue?.largeur / 1000) +
								1 +
								' ' +
								item?.display_name
								}` +
								`\n Largeur: ${currentFormValue?.largeur}mm (${currentFormValue?.typeMesureLargeur?.value})`
							);
						};
						const options = elements?.length
							? elements.map((item) => ({
								id: item?.id,
								product_id: PRODUCT_ID_AXE_MOTORISE_TRADI,
								description: generateDescription(item),
								production: [
									currentFormValue?.tandem?.id,
									currentFormValue?.motors?.id,
									currentFormValue?.supportMotors?.id,
								],
								verrou: [
									item?.id,
									Math.ceil(currentFormValue?.largeur / 1000) + 1,
								],
								largeur: currentFormValue?.largeur,
								label: item?.display_name,
								value: item?.id,
								lst_price: price,
								// lst_price: parseFloat(
								// 	(
								// 		item?.lst_price *
								// 			(Math.ceil(currentFormValue?.largeur / 1000) + 1) +
								// 		currentFormValue?.tandem?.lst_price +
								// 		currentFormValue?.axe?.lst_price +
								// 		currentFormValue?.motors?.lst_price +
								// 		(currentFormValue?.telecommande?.lst_price || 0) +
								// 		dataEquerre?.lst_price +
								// 		dataCoussinet?.lst_price +
								// 		dataJambe?.lst_price
								// 	).toFixed(2),
								// ),
							}))
							: [];
						fnSuccess(options);
					});
				});
			});
		});
	},
};

const typeAgraffe = {
	id: 'typeAgraffe',
	type: formInputsTypes?.RADIO_INPUT,
	label: 'Type de verrou',
	name: 'typeAgraffe',
	className: 'col-6',
	nextStepData: agraffe,
	nextStep: false,
	options: [
		{
			id: 'regide',
			label: 'Regide',
			value: CATEG_ID_VERROU_REGIDE,
			nextStep: false,
		},
		{
			id: 'souple',
			label: 'Souple',
			value: CATEG_ID_VERROU_SOUPLE,
			nextStep: false,
		},
	],
};

const isWithAgraffe = {
	id: 'isWithAgraffe',
	type: formInputsTypes?.RADIO_INPUT,
	label: 'Avec verrou?',
	name: 'isWithAgraffe',
	className: 'col-6',
	nextStepData: false,
	nextStep: false,
	fetchData: (tokenUserCurent, currentFormValue, fnSuccess) => {
		let price = 0;

		const m2 = (currentFormValue?.largeur * currentFormValue?.hauteur) / 1000;
		if (m2 <= prix_AxeMotoriseTradi[0]?.id) {
			price = prix_AxeMotoriseTradi[0]?.price;
		} else if (m2 > prix_AxeMotoriseTradi?.length?.id) {
			price = prix_AxeMotoriseTradi[prix_AxeMotoriseTradi?.length].price;
		} else {
			for (var i = 0; i < prix_AxeMotoriseTradi.length - 1; i++) {
				// console.log('prix', prix[i]);
				if (m2 > prix_AxeMotoriseTradi[i]?.id && m2 <= prix_AxeMotoriseTradi[i + 1]?.id) {
					price = prix_AxeMotoriseTradi[i + 1]?.price;
				}
			}
		}
		var equerre = {},
			jambe = {},
			coussinet = {};
		getSingleProduct(PRODUCT_ID_EQUERRE, tokenUserCurent, (dataEquerre) => {
			getSingleProduct(PRODUCT_ID_JAMBE, tokenUserCurent, (dataJambe) => {
				getSingleProduct(PRODUCT_ID_COUSSINET, tokenUserCurent, (dataCoussinet) => {
					const generateDescription = () => {
						return (
							`${currentFormValue?.typeAxe?.value} équipé de: ` +
							`\n- Axe: ${currentFormValue?.axe?.label}` +
							`\n- Moteur: ${currentFormValue?.motors?.label}` +
							`\n${!!currentFormValue?.telecommande?.label
								? '- Telecommande:' + currentFormValue?.telecommande?.label
								: ''
							}` +
							// `\n- Tandem: ${currentFormValue?.tandem?.label}` +
							// `\n- Support: ${currentFormValue?.supportMotors?.label}` +
							// `\n- Equerre: ${dataEquerre?.display_name}` +
							// `\n- Coussinet: ${dataCoussinet?.display_name}` +
							// `\n- Jambe de force: ${dataJambe?.display_name}` +
							`\n Largeur: ${currentFormValue?.largeur}mm (${currentFormValue?.typeMesureLargeur?.value})`
						);
					};
					const options = [
						{
							id: 'with-agraffe',
							label: 'Oui',
							value: 'oui',
							nextStep: typeAgraffe,
						},
						{
							id: 'with-out-agraffe',
							product_id: PRODUCT_ID_AXE_MOTORISE_TRADI,
							label: 'Non',
							value: 'non',
							description: generateDescription(),
							production: [
								currentFormValue?.tandem?.id,
								currentFormValue?.motors?.id,
								currentFormValue?.supportMotors?.id,
							],
							largeur: currentFormValue?.largeur,
							lst_price: price,
							// lst_price: parseFloat(
							// 	(
							// 		currentFormValue?.tandem?.lst_price +
							// 		currentFormValue?.axe?.lst_price +
							// 		currentFormValue?.motors?.lst_price +
							// 		(currentFormValue?.telecommande?.lst_price || 0) +
							// 		dataEquerre?.lst_price +
							// 		dataCoussinet?.lst_price +
							// 		dataJambe?.lst_price
							// 	).toFixed(2),
							// ),
							nextStep: false,
						},
					];
					fnSuccess(options);
				});
			});
		});
	},
};

const Tandem = {
	id: 'tandem',
	type: formInputsTypes?.SELECT_INPUT,
	label: 'Tandem',
	name: 'tandem',
	nextStepData: isWithAgraffe,
	nextStep: false,
	options: null,
	fetchData: (tokenUserCurent, currentFormValue, fnSuccess) => {
		const generateFilter = () => {
			const filters = [
				'&',
				['categ_id', '=', CATEG_ID_TANDEM],
				['diametre', '=', currentFormValue?.axe?.diametre],
			];

			return filters;
		};
		searchProduct(tokenUserCurent, generateFilter(), async ({ elements }) => {
			const options = elements?.length
				? elements.map((item) => ({
					id: item?.id,
					label: item?.display_name,
					value: item?.id,
					lst_price: item?.lst_price,
				}))
				: [];
			fnSuccess(options);
		});
	},
};
const supportMotors = {
	id: 'VR-TR-AXE-SM-API',
	type: formInputsTypes?.RADIO_INPUT,
	label: 'Sélectionner un support moteur',
	name: 'supportMotors',
	className: 'd-none',
	nextStepData: false,
	nextStep: false,
	options: null,
	fetchData: (tokenUserCurent, currentFormValue, fnSuccess) => {
		let price = 0;

		const m2 = (currentFormValue?.largeur * currentFormValue?.hauteur) / 1000;
		if (m2 <= prix_AxeMotoriseTradi[0]?.id) {
			price = prix_AxeMotoriseTradi[0]?.price;
		} else if (m2 > prix_AxeMotoriseTradi?.length?.id) {
			price = prix_AxeMotoriseTradi[prix_AxeMotoriseTradi?.length].price;
		} else {
			for (var i = 0; i < prix_AxeMotoriseTradi.length - 1; i++) {
				// console.log('prix', prix[i]);
				if (m2 > prix_AxeMotoriseTradi[i]?.id && m2 <= prix_AxeMotoriseTradi[i + 1]?.id) {
					price = prix_AxeMotoriseTradi[i + 1]?.price;
				}
			}
		}
		searchProduct(
			tokenUserCurent,
			[
				'&',
				['categ_id', '=', CATEG_ID_SUPPORT],
				['brand_id', '=', currentFormValue?.Moteur?.value],
			],
			async ({ elements }) => {
				const generateDescription = () => {
					return (
						`${currentFormValue?.typeAxe?.value} équipé d'un moteur fiable et silencieux qui permet de l'ouvrir et de le fermer en douceur et en silence. \n Fabriqué en aluminium de haute qualité conformement à la norme européenne EN 13659 \n \n` +
						`\n- Axe: ${currentFormValue?.axe?.label}` +
						`\n- Moteur: ${currentFormValue?.Moteur?.id} ${currentFormValue?.typeMotor?.id} ${currentFormValue?.puissance?.value}`
						+
						// `\n- Tandem: ${currentFormValue?.tandem?.label}` +
						// `\n- Support: ${currentFormValue?.supportMotors?.label}` +
						// `\n- Equerre: ${dataEquerre?.display_name}` +
						// `\n- Coussinet: ${dataCoussinet?.display_name}` +
						// `\n- Jambe de force: ${dataJambe?.display_name}` +
						`\n-  ${Math.ceil(currentFormValue?.largeur / 1000) +
						1 + 'verrous '
						}` +
						`\n Largeur: ${currentFormValue?.largeur}mm (${currentFormValue?.typeMesureLargeur?.value})`
					);
				};

				const options = elements?.length
					? elements.map((item) => ({
						id: item?.id,
						product_id: PRODUCT_ID_AXE_MOTORISE_TRADI,
						description: generateDescription(),
						production: [

							//currentFormValue?.motors?.id,
							item?.id,
						],
						verrou: [
							4503,
							Math.ceil(currentFormValue?.largeur / 1000) + 1,
						],
						largeur: currentFormValue?.largeur,
						label: item?.display_name,
						value: item?.id,
						lst_price: price,
						checked: true
						// lst_price: parseFloat(
						// 	(
						// 		item?.lst_price +
						// 		(Math.ceil(currentFormValue?.largeur / 1000) + 1) *
						// 			(currentFormValue?.agraffe?.lst_price || 0) +
						// 		currentFormValue?.lames?.lst_price +
						// 		currentFormValue?.lameFinal?.lst_price +
						// 		currentFormValue?.coulisse?.lst_price +
						// 		(currentFormValue?.axe?.lst_price *
						// 			currentFormValue?.largeur) /
						// 			1000 +
						// 		currentFormValue?.motors?.lst_price +
						// 		(currentFormValue?.telecommande?.lst_price || 0) +
						// 		currentFormValue?.supportMotors?.lst_price +
						// 		dataEquerre?.lst_price +
						// 		dataCoussinet?.lst_price +
						// 		dataJampe?.lst_price
						// 	).toFixed(2),
						// ),
					}))
					: [];
				fnSuccess(options);
				// const options = elements?.length
				// 	? elements.map((item) => ({
				// 			id: item?.id,
				// 			label: item?.display_name,
				// 			product_id: PRODUCT_ID_AXE_MOTORISE_TRADI,

				// 			value: item?.id,
				// 			lst_price: item?.lst_price,
				// 	  }))
				// 	: [];
				// fnSuccess(options);
			},
		);
	},
};
const telecommande = {
	id: 'MD',
	type: formInputsTypes?.RADIO_INPUT,
	label: 'Avec / sans télécommande',
	name: 'telecommande',
	className: 'col-6',
	classGroup: "SProduit",
	nextStepData: false,
	nextStep: false,
	options: [
		{
			id: 'telecommande_non',
			value: 'Sans télécommande',
			label: '',
			imageUrl: sans_telecommande,
			nextStep: supportMotors,
		},
		{
			id: 'telecommande_oui',
			value: 'Avec télécommande',
			label: '',
			imageUrl: avec_telecommande,
			nextStep: supportMotors,
		},
	],
};

const puissanceMoteur = {
	id: "puissance",
	type: formInputsTypes?.RADIO_INPUT,
	label: "Sélectionner la puissance de moteur",
	name: "puissance",
	nextStepData: telecommande,
	nextStep: false,
	options: [
		{
			id: "6",
			label: "6 Nm",
			value: "6 Nm",
		},
		{
			id: "9",
			label: "9 Nm",
			value: "9 Nm",
		},
		{
			id: "12",
			label: "12 Nm",
			value: "12 Nm",
		},
		{
			id: "20",
			label: "20 Nm",
			value: "20 Nm",
		},
		{
			id: "30",
			label: "30 Nm",
			value: "30 Nm",
		},
		{
			id: "40",
			label: "40 Nm",
			value: "40 Nm",
		},
		{
			id: "50",
			label: "50 Nm",
			value: "50 Nm",
		},

	],
}

const motorsradio = {
	id: 'VR-TR-AXE-MI',
	type: formInputsTypes?.SELECT_INPUT,
	label: 'Sélectionner un moteur',
	name: 'motors',
	nextStepData: supportMotors,
	nextStep: false,
	options: null,
	fetchData: (tokenUserCurent, currentFormValue, fnSuccess) => {
		const generateFilter = () => {
			const filters = ['&', ['categ_id.name', 'like', currentFormValue?.Moteur?.value + ' ' + currentFormValue?.typeMotor?.label], ['tag_ids', '=', currentFormValue?.typeMotor?.value]];

			return filters;
		};

		searchProduct(
			tokenUserCurent,
			generateFilter(currentFormValue?.marqueMoteur?.id),
			({ elements }) => {
				const options = elements?.length
					? elements.map((item) => {
						const { product_template_attribute_value_ids } = item;
						let name = item?.display_name + ' ';
						product_template_attribute_value_ids.map(
							(i) => (name += '( ' + i.name + ' )'),
						);
						return {
							id: item?.id,
							label: name,
							value: item?.id,
							lst_price: item?.lst_price,
							brand_id: item?.brand_id,
						};
					})
					: // "'const options = elements?.length
					// 	? elements.map((item) => ({
					// 			id: item?.id,
					// 			label: item?.display_name,
					// 			value: item?.id,
					// 			lst_price: item?.lst_price,
					// 			brand_id: item?.brand_id,
					// 	  }))'"
					[];
				fnSuccess(options);
			},
		);
	},
};
const motors = {
	id: 'VR-TR-AXE-M-API',
	type: formInputsTypes?.SELECT_INPUT,
	label: 'Sélectionner un moteur',
	name: 'motors',
	nextStepData: supportMotors,
	nextStep: false,
	options: null,
	fetchData: (tokenUserCurent, currentFormValue, fnSuccess) => {
		const generateFilter = () => {
			const filters = ['&', ['categ_id.name', 'like', currentFormValue?.Moteur?.value + ' ' + currentFormValue?.typeMotor?.label], ['tag_ids', '=', currentFormValue?.typeMotor?.value]];

			return filters;
		};

		searchProduct(
			tokenUserCurent,
			generateFilter(currentFormValue?.marqueMoteur?.id),
			({ elements }) => {
				const options = elements?.length
					? elements.map((item) => {
						const { product_template_attribute_value_ids } = item;
						let name = item?.name + ' ';
						product_template_attribute_value_ids.map(
							(i) => (name += '( ' + i?.name + ' )'),
						);
						return {
							id: item?.id,
							label: name,
							value: item?.id,
							lst_price: item?.lst_price,
							brand_id: item?.brand_id,
						};
					})
					: // "'const options = elements?.length
					// 	? elements.map((item) => ({
					// 			id: item?.id,
					// 			label: item?.display_name,
					// 			value: item?.id,
					// 			lst_price: item?.lst_price,
					// 			brand_id: item?.brand_id,
					// 	  }))'"
					[];
				fnSuccess(options);
			},
		);
	},
};

const typeMoteurRadioFilaire = {
	id: "VR-TR-AXE-MM-TM",
	type: formInputsTypes?.RADIO_INPUT,
	label: "Sélectionner un type de moteur",
	name: "typeMotor",
	nextStepData: false,
	classGroup: "SProduit",
	nextStep: false,
	options: [
		{
			id: "Filaire",
			label: "",
			imageUrl: Filaire,
			value: 1,
			nextStep: puissanceMoteur
		},
		{
			id: "Radio",
			label: "",
			imageUrl: Radio,
			value: 2,
			nextStep: puissanceMoteur
		},

	],
}
const typeMoteurRadioFilaireSolaire = {
	id: "VR-TR-AXE-MM-TM",
	type: formInputsTypes?.RADIO_INPUT,
	label: "Sélectionner un type de moteur",
	name: "typeMotor",
	classGroup: "SProduit",
	nextStepData: false,
	nextStep: false,
	options: [
		{
			id: "Filaire",
			label: "",
			imageUrl: Filaire,
			value: 1,
			nextStep: puissanceMoteur
		},
		{
			id: "Radio",
			label: "",
			imageUrl: Radio,
			value: 2,
			nextStep: puissanceMoteur
		},
		{
			id: "Solaire",
			label: "",
			imageUrl: Solaire,
			value: 3,
			nextStep: motors
		}
	],
}
const typeMoteursomfy = {
	id: "VR-TR-AXE-MM-TM",
	type: formInputsTypes?.RADIO_INPUT,
	label: "Sélectionner un type de moteur",
	name: "typeMotor",
	classGroup: "SProduit",
	nextStepData: false,
	nextStep: false,
	options: [
		{
			id: "Radio RTS",
			label: "",
			imageUrl: RTS,
			value: 5,
			nextStep: puissanceMoteur
		},
		{
			id: "Radio IO",
			label: "",
			imageUrl: IO,
			value: 4,
			nextStep: puissanceMoteur
		},
		{
			id: "Filaire",
			label: "",
			imageUrl: Filaire,
			value: 1,
			nextStep: puissanceMoteur
		},
		{
			id: "Solaire",
			label: "",
			imageUrl: Solaire,
			value: 3,
			nextStep: motors
		}
	],
}
const Moteur = {
	id: 'Moteur',
	type: formInputsTypes?.RADIO_INPUT,
	label: 'Sélectionner la marque de moteur',
	name: 'Moteur',
	classGroup: "SProduit",
	nextStepData: false,
	nextStep: false,
	options: [
		{
			id: 'Becker',
			label: '',
			value: 'Becker',
			imageUrl: Becker,
			nextStep: typeMoteurRadioFilaireSolaire,
		},
		{
			id: 'Somfy',
			label: '',
			value: 'Somfy',
			imageUrl: Somfy,
			nextStep: typeMoteursomfy,
		},
		{
			id: 'Bubendorff',
			label: '',
			value: 'Bubendorff',
			imageUrl: Bubendorff,
			nextStep: typeMoteurRadioFilaire,
		},
		{
			id: 'AOK',
			label: '',
			value: 'A-OK',
			imageUrl: a_ok,
			nextStep: typeMoteurRadioFilaireSolaire,
		},
		{
			id: 'Eckermann',
			label: '',
			value: 'Eckermann',
			imageUrl: Eckermann,
			nextStep: typeMoteurRadioFilaire,
		},
		{
			id: 'Gaposa',
			label: '',
			value: 'Gaposa',
			imageUrl: Gaposa,
			nextStep: typeMoteurRadioFilaireSolaire,
		},
	],

};
// const motorsradio = {
// 	id: 'VR-TR-AXE-MI',
// 	type: formInputsTypes?.SELECT_INPUT,
// 	label: 'Sélectionner un moteur',
// 	name: 'motors',
// 	nextStepData: telecommande,
// 	nextStep: false,
// 	options: null,
// 	fetchData: (tokenUserCurent, currentFormValue, fnSuccess) => {
// 		const generateFilter = () => {
// 			const filters = ['&', ['categ_id.name', 'like', currentFormValue?.Moteur?.value + ' ' + currentFormValue?.typeMotor?.label], ['tag_ids', '=', currentFormValue?.typeMotor?.value]];

// 			return filters;
// 		};

// 		searchProduct(
// 			tokenUserCurent,
// 			generateFilter(currentFormValue?.marqueMoteur?.id),
// 			({ elements }) => {
// 				const options = elements?.length
// 					? elements.map((item) => {
// 						const { product_template_attribute_value_ids } = item;
// 						let name = item?.display_name + ' ';
// 						product_template_attribute_value_ids.map(
// 							(i) => (name += '( ' + i.name + ' )'),
// 						);
// 						return {
// 							id: item?.id,
// 							label: name,
// 							value: item?.id,
// 							lst_price: item?.lst_price,
// 							brand_id: item?.brand_id,
// 						};
// 					})
// 					: // "'const options = elements?.length
// 					// 	? elements.map((item) => ({
// 					// 			id: item?.id,
// 					// 			label: item?.display_name,
// 					// 			value: item?.id,
// 					// 			lst_price: item?.lst_price,
// 					// 			brand_id: item?.brand_id,
// 					// 	  }))'"
// 					[];
// 				fnSuccess(options);
// 			},
// 		);
// 	},
// };


// const supportMotors = {
// 	id: 'VR-TR-AXE-SM-API',
// 	type: formInputsTypes?.SELECT_INPUT,
// 	label: 'Sélectionner un support moteur',
// 	name: 'supportMotors',
// 	nextStepData: Tandem,
// 	nextStep: false,
// 	options: null,
// 	fetchData: (tokenUserCurent, currentFormValue, fnSuccess) => {
// 		searchProduct(
// 			tokenUserCurent,
// 			[
// 				'&',
// 				['categ_id', '=', CATEG_ID_SUPPORT],
// 				['brand_id', '=', currentFormValue?.motors?.brand_id?.id],
// 			],
// 			async ({ elements }) => {
// 				const options = elements?.length
// 					? elements.map((item) => ({
// 							id: item?.id,
// 							label: item?.display_name,
// 							product_id: PRODUCT_ID_AXE_MOTORISE_TRADI,

// 							value: item?.id,
// 							lst_price: item?.lst_price,
// 					  }))
// 					: [];
// 				fnSuccess(options);
// 			},
// 		);
// 	},
// };
const mural = {
	id: 'MD',
	type: formInputsTypes?.RADIO_INPUT,
	label: 'Avec / sans bouton mural',
	name: 'telecommande',
	className: 'col-6',
	classGroup: "SProduit",
	nextStepData: false,
	nextStep: false,
	options: [
		{
			id: 'telecommande_non',
			value: 'Sans bouton mural',
			label: '',
			imageUrl: sans_telecommande,
			nextStep: supportMotors,
		},
		{
			id: 'telecommande_oui',
			value: 'Avec bouton mural',
			label: '',
			imageUrl: avec_telecommande,
			nextStep: supportMotors,
		},
	],
};


/* const telecommandeRadio = {
	id:"VR-TR-AXE-M-API",
	type:formInputsTypes?.SELECT_INPUT,
	label:"Sélectionner une télécommande",
	name:"telecommande",
	nextStepData:supportMotors,
	nextStep:false,
	options:null,
	fetchData: (tokenUserCurent,currentFormValue,fnSuccess) => {
		const generateFilter = (idCat) => {
			const filters = [["categ_id","=",idCat]]
			return(
				filters
			)
		}
	    
		searchCategory(
			tokenUserCurent,
			["&",["parent_id","=",currentFormValue?.marqueMoteur?.id],["name","=","Télécommande"]],
			(results)=>{
				searchProduct(
					tokenUserCurent,
					generateFilter(results[0].id),
					(motorsData)=>{
						const options = motorsData?.length? motorsData.map((item=>({
							id:item?.id,
							label:item?.display_name,
							value:item?.id,
							lst_price:(item?.lst_price+currentFormValue?.axe?.lst_price+10+10+10)
						   
						}))):
						[];
						fnSuccess(options);
					}
				)
			    
			}
		)
	}
} */

/* const motorsRadio = {
	id:"VR-TR-AXE-M-API",
	type:formInputsTypes?.SELECT_INPUT,
	label:"Sélectionner un moteur",
	name:"motors",
	nextStepData:telecommandeRadio,
	nextStep:false,
	options:null,
	fetchData: (tokenUserCurent,currentFormValue,fnSuccess) => {
		const generateFilter = (idCat) => {
			const filters = ["&",["categ_id","=",idCat],["tag_ids","=",parseInt(currentFormValue?.typeMotor?.value)]]
			return(
				filters
			)
		}
	    
		searchCategory(
			tokenUserCurent,
			["&",["parent_id","=",currentFormValue?.marqueMoteur?.id],["name","=","Moteur"]],
			(results)=>{
				searchProduct(
					tokenUserCurent,
					generateFilter(results[0].id),
					(motorsData)=>{
						const options = motorsData?.length? motorsData.map((item=>({
							id:item?.id,
							label:item?.display_name,
							value:item?.id,
							lst_price:item?.lst_price
						   
						}))):
						[];
						fnSuccess(options);
					}
				)
			    
			}
		)
	}
} */

// const motors = {
// 	id: 'VR-TR-AXE-M-API',
// 	type: formInputsTypes?.SELECT_INPUT,
// 	label: 'Sélectionner un moteur',
// 	name: 'motors',
// 	nextStepData: supportMotors,
// 	nextStep: false,
// 	options: null,
// 	fetchData: (tokenUserCurent, currentFormValue, fnSuccess) => {
// 		const generateFilter = (idCat) => {
// 			const filters = [['categ_id', '=', idCat]];

// 			return filters;
// 		};

// 		searchProduct(
// 			tokenUserCurent,
// 			generateFilter(currentFormValue?.marqueMoteur?.id),
// 			({ elements }) => {
// 				const options = elements?.length
// 					? elements.map((item) => {
// 							const { product_template_attribute_value_ids } = item;
// 							let name = item?.display_name + ' ';
// 							product_template_attribute_value_ids.map(
// 								(i) => (name += '( ' + i.name + ' )'),
// 							);
// 							return {
// 								id: item?.id,
// 								label: name,
// 								value: item?.id,
// 								lst_price: item?.lst_price,
// 								brand_id: item?.brand_id,
// 							};
// 					  })
// 					: // const options = elements?.length
// 					  // 	? elements.map((item) => ({
// 					  // 			id: item?.id,
// 					  // 			label: item?.display_name,
// 					  // 			value: item?.id,
// 					  // 			lst_price: item?.lst_price,
// 					  // 			brand_id: item?.brand_id,
// 					  // 	  }))
// 					  [];
// 				fnSuccess(options);
// 			},
// 		);
// 	},
// };

/* const typeMoteur = {
	id:"VR-TR-AXE-MM-TM",
	type:formInputsTypes?.RADIO_INPUT,
	label:"Sélectionner le type de moteur",
	name:"typeMotor",
	nextStepData:motors,
	nextStep:false,
	options:[
		{
			id:"VR-TR-AXE-MM-TM-FL",
			label:"Filaire",
			value:"1",
			nextStep:motors
		},
		{
			id:"VR-TR-AXE-MM-R",
			label:"Radio",
			value:"2",
			nextStep:motorsRadio
		},
		{
			id:"VR-TR-AXE-MM-TM-S",
			label:"Solaire",
			value:"3",
			nextStep:motors
		}
	],
} */

/* const typeMoteurBubendorff = {
	id:"VR-TR-AXE-MM-TM",
	type:formInputsTypes?.RADIO_INPUT,
	label:"Sélectionner le type de moteur",
	name:"typeMotor",
	nextStepData:motors,
	nextStep:false,
	options:[
		{
			id:"VR-TR-AXE-MM-TM-FL",
			label:"Filaire",
			value:"1",
			nextStep:motors
		},
		{
			id:"VR-TR-AXE-MM-R",
			label:"Radio",
			value:"2",
			nextStep:motors
		},
		{
			id:"VR-TR-AXE-MM-TM-S",
			label:"Solaire",
			value:"3",
			nextStep:motors
		}
	],
} */

const marqueMoteur = {
	id: 'VR-TR-AXE-MM-API',
	type: formInputsTypes?.SELECT_INPUT,
	label: 'Sélectionner la marque de moteur',
	name: 'marqueMoteur',
	nextStepData: motors,
	nextStep: false,
	options: null,
	fetchData: (tokenUserCurent, currentFormValue, fnSuccess) => {
		const generateFilter = () => {
			const filters = [['parent_id', '=', CATEG_ID_MOTEURS]];

			return filters;
		};
		searchCategory(tokenUserCurent, generateFilter(currentFormValue), ({ elements }) => {
			const options = elements?.length
				? elements?.map((item) => ({
					id: item?.id,
					label: item?.name,
					value: item?.id,
					//nextStep:item?.name=="Bubendorff"?typeMoteurBubendorff:typeMoteur
				}))
				: [];
			fnSuccess(options);
		});
	},
};

const axeVoletRoulantMo = {
	id: 'VR-TR-AXE-API',
	type: formInputsTypes?.SELECT_INPUT,
	label: 'Sélectionner un axe',
	name: 'axe',
	nextStepData: Moteur,
	nextStep: false,
	options: null,
	fetchData: (tokenUserCurent, currentFormValue, fnSuccess) => {
		const generateFilter = () => {
			const filters = [['categ_id', '=', CATEG_ID_AXE]];

			return filters;
		};
		searchProduct(tokenUserCurent, generateFilter(currentFormValue), ({ elements }) => {
			const options = elements?.length
				? elements.map((item) => ({
					id: item?.id,
					label: item?.name,
					value: item?.id,
					lst_price: item?.lst_price,
					diametre: item?.diametre,
				}))
				: [];
			fnSuccess(options);
		});
	},
};

const widthAxeMo = {
	id: 'VR-TR-AXE-W',
	type: formInputsTypes?.INPUT_NUMBER,
	name: 'largeur',
	className: 'col-6',
	label: 'Largeur de la lame(mm)',
	defaultValue: MIN_WIDTH,
	nextStepData: axeVoletRoulantMo,
	nextStep: false,
	validation: (value) => {
		return value > 0;
	},
};

const TypeMesureLargMot = {
	id: 'VR-TR-AXE-TMOW',
	type: formInputsTypes?.RADIO_INPUT,
	name: 'typeMesureLargeur',
	className: 'col-6',
	label: 'Mesure selon largeur',
	nextStepData: widthAxeMo,
	nextStep: false,
	options: [
		{
			id: 'VR-TR-AXE-TMOW-FN',
			label: 'FINI',
			value: 'FINI',
			nextStep: false,
		},
		{
			id: 'VR-TR-AXE-TMOW-DC',
			label: 'D.C',
			value: 'D.C',
			nextStep: false,
		},
	],
};

export default TypeMesureLargMot;
