import axios from 'axios';

const BASIC_API_URL = '/odoo',
	API_URL_WEB_ADMIN = process.env.REACT_APP_API_URL_WEB_ADMIN,
	SEARCH_METHOD_API = process.env.REACT_APP_SEARCH_METHOD_API,
	FUNCTION_UTILS = process.env.REACT_APP_API_URL_FUNCTION_UTILS,
	ATELEIR_API_URI = '/atelier',
	HR_ATELEIR_API_URI = '/hr.atelier',
	HR_ATELEIR_MATERIEL_API_URI = '/hr.atelier.materiel',
	HR_SIMULATEUR_API_URI = '/hr.simulateur',
	LIMIT = 0;

export const searchAtelier = (
	tokenUserCurent,
	filters,
	fncSuccess,
	fnError = () => { },
	signal,
	page = 1,
	limit = LIMIT,
) => {
	var dataFilter = JSON.stringify({
		kwargs: {
			domain: filters,
			limit: limit,
			page: page,
		},
	});
	var config = {
		method: 'patch',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + HR_ATELEIR_API_URI + SEARCH_METHOD_API}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: dataFilter,
		signal,
	};

	axios(config)
		.then(function (response) {
			fncSuccess(response.data || []);
			return;
		})
		.catch(function (error) {
			fnError(error);
		});
};

export const createAtelier = (tokenUserCurent, newLine, fnSucces, fnError = () => { }) => {
	const data = JSON.stringify(newLine);

	const config = {
		method: 'post',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + ATELEIR_API_URI}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: data,
	};
	axios(config)
		.then(function (response) {
			//console.log(JSON.stringify(response.data));
			fnSucces(response.data);
		})
		.catch(function (error) {
			fnError(error);
		});
};

export const getAllAteliers = (tokenUserCurent, limit, state, fncSuccess) => {
	var config = {
		method: 'get',
		url:
			`${BASIC_API_URL + API_URL_WEB_ADMIN + ATELEIR_API_URI}` +
			'?limit=' +
			parseInt(limit) + "&state=" + state,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
	};

	axios(config)
		.then(function (response) {
			fncSuccess(response.data);
		})
		.catch(function (error) {
			console.log(error);
		});
};

export const updateAtelier = (tokenUserCurent, atelier, fnSucces, fnError = () => { }) => {
	const devisLineString = JSON.stringify(atelier);

	const config = {
		method: 'put',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + HR_ATELEIR_API_URI + '/' + atelier?.id}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: devisLineString,
	};

	axios(config)
		.then(function () {
			fnSucces();
		})
		.catch(function (error) {
			fnError(error);
		});
};

export const getmaterielsByAtelierId = (tokenUserCurent, atelierId, fncSuccess) => {
	const bodyData = JSON.stringify({
		kwargs: {
			domain: [['atelier', '=', atelierId]],
		},
	});
	var config = {
		method: 'patch',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + HR_ATELEIR_MATERIEL_API_URI + SEARCH_METHOD_API
			}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: bodyData,
	};

	axios(config)
		.then(function (response) {
			fncSuccess(response?.data);
		})
		.catch(function (error) {
			console.log(error);
		});
};

export const getmaterielsByProductionId = (tokenUserCurent, productionId, fncSuccess) => {
	const bodyData = JSON.stringify({
		kwargs: {
			domain: [['production', '=', productionId]],
		},
	});
	var config = {
		method: 'patch',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + HR_ATELEIR_MATERIEL_API_URI + SEARCH_METHOD_API
			}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: bodyData,
	};

	axios(config)
		.then(function (response) {
			fncSuccess(response?.data);
		})
		.catch(function (error) {
			console.log(error);
		});
};

export const getmaterielsByAchatId = (tokenUserCurent, achatId, fncSuccess) => {
	const bodyData = JSON.stringify({
		kwargs: {
			domain: [['achat', '=', achatId]],
		},
	});
	var config = {
		method: 'patch',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + HR_ATELEIR_MATERIEL_API_URI + SEARCH_METHOD_API
			}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: bodyData,
	};

	axios(config)
		.then(function (response) {
			fncSuccess(response?.data);
		})
		.catch(function (error) {
			console.log(error);
		});
};

export const updateatelierLine = async (
	tokenUserCurent,
	atelierLine,
	fnSucces,
	fnError = () => { },
) => {
	const atelierLineString = JSON.stringify(atelierLine);

	const config = {
		method: 'put',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + HR_ATELEIR_MATERIEL_API_URI + '/' + atelierLine?.id
			}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: atelierLineString,
	};
};

export const deleteAtelierLine = async (tokenUserCurent, idLine, fnSucces, fnError = () => { }) => {
	const config = {
		method: 'delete',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + HR_ATELEIR_MATERIEL_API_URI + '/' + idLine}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
	};

	axios(config)
		.then(function () {
			fnSucces();
		})
		.catch(function (error) {
			fnError(error);
		});
};

export const updateSingleMateriel = (tokenUserCurent, materiel, fnSucces, fnError = () => { }) => {
	const devisLineString = JSON.stringify(materiel);

	const config = {
		method: 'put',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + HR_ATELEIR_MATERIEL_API_URI + '/' + materiel?.id
			}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: devisLineString,
	};

	axios(config)
		.then(function () {
			fnSucces();
		})
		.catch(function (error) {
			fnError(error);
		});
};

export const createSimulateur = (tokenUserCurent, newLine, fnSucces, fnError = () => { }) => {
	const data = JSON.stringify(newLine);

	const config = {
		method: 'post',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + HR_SIMULATEUR_API_URI}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: data,
	};
	axios(config)
		.then(function (response) {
			//console.log(JSON.stringify(response.data));
			fnSucces(response.data);
		})
		.catch(function (error) {
			fnError(error);
		});
};
export const searchSimulateur = (
	tokenUserCurent,
	filters,
	fncSuccess,
	fnError = () => { },
	page = 1,
	limit = LIMIT,
) => {
	var dataFilter = JSON.stringify({
		kwargs: {
			domain: filters,
			limit: limit,
			page: page,
		},
	});
	var config = {
		method: 'patch',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + HR_SIMULATEUR_API_URI + SEARCH_METHOD_API}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: dataFilter,
	};

	axios(config)
		.then(function (response) {
			fncSuccess(response.data || []);
			return;
		})
		.catch(function (error) {
			fnError(error);
		});
};
export const getAllMateriels = (tokenUserCurent, fncSuccess) => {
	var config = {
		method: 'get',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + HR_ATELEIR_MATERIEL_API_URI}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
	};

	axios(config)
		.then(function (response) {
			fncSuccess(response.data);
		})
		.catch(function (error) {
			console.log(error);
		});
};
