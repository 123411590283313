import { searchProduct } from '../../../../../../odooApi/product';
import { searchProductAttribute } from '../../../../../../odooApi/productAttribute';
import { getSingleProductTemplate } from '../../../../../../odooApi/productTemplate';
import { prix_Tablier41, prix_Tablier55 } from '../../../../CalculSimulateurData.js/Tablier';
import formInputsTypes from '../../../simulatorConstant';

//const CATEG_ID_LAME = 9
//const CATEG_ID_TABLIER_COLORS = 8;
export const PRODUCT_ID_TABLIER_TRADI = 112;
const PRODUCT_TEMPL_ID_LAME = 110;
export const PRODUCT_TEMPL_ID_LAME_FINAL = 111;
const COMPLETE_LAME_LARGEUR = 6500;
const SEUIL = 1200;
export const CATEG_ID_VERROU_SOUPLE = 151;
export const CATEG_ID_VERROU_REGIDE = 152;
export const PRODUCT_TEMPL_ID_TAB = 24;




const agraffe = {
	id: 'agraffe',
	type: formInputsTypes?.SELECT_INPUT,
	label: 'Agraffe',
	name: 'agraffe',
	nextStepData: false,
	nextStep: false,
	fetchData: (tokenUserCurent, currentFormValue, fnSuccess) => {
		let price = 0;
		const m2 = (currentFormValue?.largeur * currentFormValue?.hauteur) / 1000;
		if (currentFormValue?.lameTaille?.label == 'BP43R') {
			if (m2 <= prix_Tablier41[0]?.id) {
				price = prix_Tablier41[0]?.price;
			} else if (m2 > prix_Tablier41?.length?.id) {
				price = prix_Tablier41[prix_Tablier41?.length].price;
			} else {
				for (var i = 0; i < prix_Tablier41.length - 1; i++) {
					// console.log('prix', prix[i]);
					if (m2 > prix_Tablier41[i]?.id && m2 <= prix_Tablier41[i + 1]?.id) {
						price = prix_Tablier41[i + 1]?.price;
					}
				}
			}
		} else {
			if (m2 <= prix_Tablier55[0]?.id) {
				price = prix_Tablier55[0]?.price;
			} else if (m2 > prix_Tablier55?.length?.id) {
				price = prix_Tablier55[prix_Tablier55?.length].price;
			} else {
				for (var i = 0; i < prix_Tablier55.length - 1; i++) {
					// console.log('prix', prix[i]);
					if (m2 > prix_Tablier55[i]?.id && m2 <= prix_Tablier55[i + 1]?.id) {
						price = prix_Tablier55[i + 1]?.price;
					}
				}
			}
		}
		const generateFilter = () => {
			const filters = [['categ_id', '=', currentFormValue?.typeAgraffe?.value]];

			return filters;
		};
		searchProduct(tokenUserCurent, generateFilter(), async ({ elements }) => {
			const generateDescription = (item) => {
				return (
					`${currentFormValue?.product?.value} \n Tablier fabriqué à partir d'aluminium de haute qualité, injecté avec précision pour garantir une solidité exceptionnelle et une résistance aux intempéries.\n Notre tablier en aluminium contribue à une meilleure isolation thermique et phonique, ce qui peut se traduire par des économies d'énergie et un confort accru. \n Il répond aux exigences de la norme NF EN 13561. \n` +
					`\n \n- Hauteur de la lame: ${currentFormValue?.lameTaille?.label}` +
					`\n- Hauteur de tablier: ${currentFormValue?.hauteur}mm (${currentFormValue?.typeMesureHauteur?.value})` +
					`\n- Largeur de tablier: ${currentFormValue?.largeur}mm (${currentFormValue?.typeMesureLargeur?.value})` +
					`\n- Couleur Tablier: ${currentFormValue?.lameColor?.label}` +
					`\n- Lame: ${currentFormValue?.lames?.label}` +
					`\n- Verrou: ${Math.ceil(currentFormValue?.largeur / 1000) + 1 + ' ' + item?.display_name
					}
                    `
				);
			};
			const options = elements?.length
				? elements.map((item) => {
					return {
						id: item?.id,
						product_id: PRODUCT_ID_TABLIER_TRADI,
						description: generateDescription(item),
						production: [currentFormValue?.lames?.id],
						verrou: [item?.id, Math.ceil(currentFormValue?.largeur / 1000) + 1],
						largeur: currentFormValue?.largeur,
						hauteur: currentFormValue?.hauteur,
						label: item?.display_name,
						value: item?.id,
						item_price:
							(Math.ceil(currentFormValue?.largeur / 1000) + 1) *
							parseFloat(item.lst_price.toFixed(2)),
						lst_price: price,
						// lst_price: parseFloat(
						// 	(
						// 		(Math.ceil(currentFormValue?.largeur / 1000) + 1) *
						// 			parseFloat(item.lst_price.toFixed(2)) +
						// 		currentFormValue?.lameFinal?.lst_price +
						// 		currentFormValue?.lames?.lst_price
						// 	).toFixed(2),
						// ),
					};
				})
				: [];
			fnSuccess(options);
		});
	},
};

const typeAgraffe = {
	id: 'typeAgraffe',
	type: formInputsTypes?.RADIO_INPUT,
	label: 'Type de verrou',
	name: 'typeAgraffe',
	className: 'col-6',
	nextStepData: agraffe,
	nextStep: false,
	options: [
		{
			id: 'regide',
			label: 'Regide',
			value: CATEG_ID_VERROU_REGIDE,
			nextStep: false,
		},
		{
			id: 'souple',
			label: 'Souple',
			value: CATEG_ID_VERROU_SOUPLE,
			nextStep: false,
		},
	],
};

const isWithAgraffe = {
	id: 'isWithAgraffe',
	type: formInputsTypes?.RADIO_INPUT,
	label: 'Avec verrou ?',
	name: 'isWithAgraffe',
	className: 'col-6 d-none',
	nextStepData: false,
	nextStep: false,
	fetchData: async (tokenUserCurent, currentFormValue, fnSuccess) => {
		let price = 0;
		const m2 = (currentFormValue?.largeur * currentFormValue?.hauteur) / 1000;
		if (currentFormValue?.lameTaille?.label == 'BP43R') {
			if (m2 <= prix_Tablier41[0]?.id) {
				price = prix_Tablier41[0]?.price;
			} else if (m2 > prix_Tablier41?.length?.id) {
				price = prix_Tablier41[prix_Tablier41?.length].price;
			} else {
				for (var i = 0; i < prix_Tablier41.length - 1; i++) {
					// console.log('prix', prix[i]);
					if (m2 > prix_Tablier41[i]?.id && m2 <= prix_Tablier41[i + 1]?.id) {
						price = prix_Tablier41[i + 1]?.price;
					}
				}
			}
		} else {
			if (m2 <= prix_Tablier55[0]?.id) {
				price = prix_Tablier55[0]?.price;
			} else if (m2 > prix_Tablier55?.length?.id) {
				price = prix_Tablier55[prix_Tablier55?.length].price;
			} else {
				for (var i = 0; i < prix_Tablier55.length - 1; i++) {
					// console.log('prix', prix[i]);
					if (m2 > prix_Tablier55[i]?.id && m2 <= prix_Tablier55[i + 1]?.id) {
						price = prix_Tablier55[i + 1]?.price;
					}
				}
			}
		}
		let data = ""
		const generateDescription = (data) => {
			return (
				`${currentFormValue?.product?.value} \n Tablier fabriqué à partir d'aluminium de haute qualité, injecté avec précision pour garantir une solidité exceptionnelle et une résistance aux intempéries.\n Notre tablier en aluminium contribue à une meilleure isolation thermique et phonique, ce qui peut se traduire par des économies d'énergie et un confort accru. \n Il répond aux exigences de la norme NF EN 13561. \n` +
				`\n \n- Hauteur de la lame: ${currentFormValue?.lameTaille?.label}` +
				`\n- Hauteur de tablier: ${currentFormValue?.hauteur}mm (${currentFormValue?.typeMesureHauteur?.value})` +
				`\n- Largeur de tablier: ${currentFormValue?.largeur}mm (${currentFormValue?.typeMesureLargeur?.value})` +
				`\n- Couleur Tablier: ${currentFormValue?.lameColor?.label}` +
				`\n- ${currentFormValue?.lames?.label}` +
				`${data == "oui" ? "\n- " + (Math.ceil(currentFormValue?.largeur / 1000) + 1) + " verrous" : ''}`
			);
		};
		const options = [
			{
				id: 'with-agraffe',
				product_id: PRODUCT_ID_TABLIER_TRADI,
				label: 'Oui',
				value: 'oui',
				checked: true,
				description: generateDescription(data = "oui"),
				production: [currentFormValue?.lames?.id],
				largeur: currentFormValue?.largeur,
				hauteur: currentFormValue?.hauteur,
				lst_price: price,
				verrou: [4503, Math.ceil(currentFormValue?.largeur / 1000) + 1],
				// lst_price: parseFloat(
				// 	(
				// 		currentFormValue?.lameFinal?.lst_price + currentFormValue?.lames?.lst_price
				// 	).toFixed(2),
				// ),
				nextStep: false,
			},

		];
		fnSuccess(options);
	},
};

const lameFinal = {
	id: 'VR-TR-TB-LAMEF',
	type: formInputsTypes?.RADIO_INPUT,
	name: 'lameFinal',
	className: 'col-6',
	label: 'Lame Finale',
	defaultValue: 0,
	nextStepData: isWithAgraffe,
	nextStep: false,
	options: null,
	fetchData: (tokenUserCurent, currentFormValue, fnSucces) => {
		const generateFilter = (productTemplateId) => {
			const filters = [
				'&',
				['product_tmpl_id', '=', productTemplateId],
				'&',
				[
					'product_template_attribute_value_ids.name',
					'=',
					currentFormValue?.lameFinalColor?.label,
				],
				[
					'product_template_attribute_value_ids.name',
					'=',
					currentFormValue?.lameTaille?.label == 'BP43R' ? 'BPFIN948' : 'BPFIN304',
				],
			];
			return filters;
		};

		searchProduct(
			tokenUserCurent,
			generateFilter(PRODUCT_TEMPL_ID_LAME_FINAL),
			async ({ elements }) => {
				const options = elements?.length
					? elements.map((item) => {
						const { product_template_attribute_value_ids } = item;
						let name = 'Lame finale ';
						product_template_attribute_value_ids.map((i) => (name += i.name + ' '));
						return {
							id: item?.id,
							label: name,
							value: item?.id,
							weight: item?.weight,
							checked: true,
							lst_price: parseFloat(
								((item?.lst_price * currentFormValue?.largeur) / 1000).toFixed(
									2,
								),
							),
						};
					})
					: [];
				// const options = elements?.length
				// 	? elements.map((item) => {
				// 			return {
				// 				id: item?.id,
				// 				label: item?.display_name,
				// 				checked: true,

				// 				value: item?.id,
				// 				lst_price: parseFloat(
				// 					((item?.lst_price * currentFormValue?.largeur) / 1000).toFixed(
				// 						2,
				// 					),
				// 				),
				// 			};
				// 	  })
				// 	: [];
				fnSucces(options);
			},
		);
	},
};

const lameFinalColor = {
	id: 'VR-TR-TB-LF',
	type: formInputsTypes?.RADIO_INPUT,
	name: 'lameFinalColor',
	label: 'Couleur de la lame finale',
	classGroup: "Simulateur_color",
	defaultValue: 0,
	nextStepData: lameFinal,
	nextStep: false,
	options: null,
	fetchData: (tokenUserCurent, currentFormValue, fnSuccess) => {
		getSingleProductTemplate(tokenUserCurent, PRODUCT_TEMPL_ID_TAB, ({ data }) => {
			const options = data.attribute_line_ids[0]?.value_ids?.length
				? data.attribute_line_ids[0]?.value_ids.map((item) => {
					return {
						id: item?.id,
						label: item?.name,
						product_id: PRODUCT_ID_TABLIER_TRADI,
						value: item?.id,
						color: item?.html_color,

					};
				})
				: [];
			fnSuccess(options);
		});
	},
};

const lames = {
	id: 'VR-TR-TB-LAME',
	type: formInputsTypes?.RADIO_INPUT,
	name: 'lames',
	className: 'col-6',
	label: 'Lames',
	defaultValue: 0,
	nextStepData: lameFinalColor,
	nextStep: false,
	options: null,
	fetchData: (tokenUserCurent, currentFormValue, fnSucces) => {
		const generateFilter = (productTemplateId) => {
			const filters = [
				'&',
				['product_tmpl_id', '=', productTemplateId],
				'&',
				[
					'product_template_attribute_value_ids.name',
					'=',
					currentFormValue?.lameColor?.label,
				],
				[
					'product_template_attribute_value_ids.name',
					'=',
					currentFormValue?.lameTaille?.label,
				],
			];
			return filters;
		};

		searchProduct(
			tokenUserCurent,
			generateFilter(PRODUCT_TEMPL_ID_LAME),
			async ({ elements }) => {
				const calculeTablier = (item) => {
					const restParLame = COMPLETE_LAME_LARGEUR % currentFormValue?.largeur;

					const nbrLameCompolet = Math.ceil(
						Math.ceil(
							currentFormValue?.hauteur /
							(currentFormValue?.lameTaille?.name == 'BP43R' ? 41 : 55),
						) / Math.floor(COMPLETE_LAME_LARGEUR / currentFormValue?.largeur),
					);

					var prixFTablier = 0;
					prixFTablier = parseFloat(
						(nbrLameCompolet * COMPLETE_LAME_LARGEUR * item?.lst_price) / 1000,
					).toFixed(2);

					if (restParLame > SEUIL) {
						prixFTablier = parseFloat(
							prixFTablier - (restParLame * nbrLameCompolet * item?.lst_price) / 1000,
						).toFixed(2);
					}
					return prixFTablier;
				};
				const options = elements?.length
					? elements.map((item) => {
						const { product_template_attribute_value_ids } = item;
						let name = 'Lame aluminium ';
						product_template_attribute_value_ids.map((i) => (name += i.name + ' '));
						return {
							id: item?.id,
							label: name,
							value: item?.id,
							checked: true,
							lst_price: parseFloat(calculeTablier(item))
						};
					})
					: [];

				// const options = elements?.length
				// 	? elements.map((item) => {
				// 			return {
				// 				id: item?.id,
				// 				label: item?.display_name,
				// 				value: item?.id,
				// 				checked: true,
				// 				lst_price: parseFloat(calculeTablier(item)),
				// 			};
				// 	  })
				// 	: [];
				fnSucces(options);
			},
		);
	},
};

const lameColor = {
	id: 'VR-TR-TB-LC',
	type: formInputsTypes?.RADIO_INPUT,
	name: 'lameColor',
	label: 'Couleur de la lame',
	defaultValue: 0,
	nextStepData: lames,
	classGroup: "Simulateur_color",
	nextStep: false,
	options: null,

	fetchData: (tokenUserCurent, currentFormValue, fnSuccess) => {
		getSingleProductTemplate(tokenUserCurent, PRODUCT_TEMPL_ID_TAB, ({ data }) => {
			const options = data.attribute_line_ids[0]?.value_ids?.length
				? data.attribute_line_ids[0]?.value_ids.map((item) => {
					return {
						id: item?.id,
						label: item?.name,
						product_id: PRODUCT_ID_TABLIER_TRADI,
						value: item?.id,
						color: item?.html_color,
					};
				})
				: [];
			fnSuccess(options);
		});
	},
};

const lameTaille = {
	id: 'VR-TR-TB-LT',
	type: formInputsTypes?.RADIO_INPUT,
	name: 'lameTaille',
	className: 'col-12',
	label: 'Taille de la lame',
	defaultValue: 0,
	nextStepData: lameColor,
	nextStep: false,
	options: null,
	fetchData: (tokenUserCurent, currentFormValue, fnSuccess) => {
		const generateFilter = () => {
			const filters = [['name', '=', 'Type Lame']];
			return filters;
		};
		searchProductAttribute(
			tokenUserCurent,
			generateFilter(currentFormValue),
			async ({ elements }) => {
				const options = elements[0]?.value_ids?.length
					? elements[0]?.value_ids.map((item) => ({
						id: item?.id,
						label: item?.name,
						product_id: PRODUCT_ID_TABLIER_TRADI,
						value: item?.id,
					}))
					: [];
				fnSuccess(options);
			},
		);
	},
};

const hieghtTablier = {
	id: 'VR-TR-TB-H',
	type: formInputsTypes?.INPUT_NUMBER,
	name: 'hauteur',
	className: 'col-6',
	label: 'Hauteur de la lame(mm)',
	defaultValue: 0,
	nextStepData: lameTaille,
	nextStep: false,
	validation: (value) => {
		return value > 0;
	},
};

const TypeMesureHaut = {
	id: 'VR-TR-TB-TMH',
	type: formInputsTypes?.RADIO_INPUT,
	name: 'typeMesureHauteur',
	className: 'col-6',
	label: 'Mesure selon Hauteur',
	nextStepData: hieghtTablier,
	nextStep: false,
	options: [
		{
			id: 'VR-TR-TB-TMH-FN',
			label: 'FINI',
			value: 'FINI',
			nextStep: false,
		},
		{
			id: 'VR-TR-TB-TMH-E',
			label: '+E',
			value: '+E',
			nextStep: false,
		},
	],
};

const widthTablier = {
	id: 'VR-TR-TB-W',
	type: formInputsTypes?.INPUT_NUMBER,
	name: 'largeur',
	className: 'col-6',
	label: 'Largeur de la lame(mm)',
	defaultValue: 0,
	nextStepData: TypeMesureHaut,
	nextStep: false,
	validation: (value) => {
		return value > 0;
	},
};

const TypeMesureLarg = {
	id: 'VR-TR-TB-TMW',
	type: formInputsTypes?.RADIO_INPUT,
	name: 'typeMesureLargeur',
	className: 'col-6',
	label: 'Mesure selon largeur',
	nextStepData: widthTablier,
	nextStep: false,
	options: [
		{
			id: 'VR-TR-TB-TMW-FN',
			label: 'FINI',
			value: 'FINI',
			nextStep: false,
		},
		{
			id: 'VR-TR-TB-TMW-DC',
			label: 'D.C',
			value: 'D.C',
			nextStep: false,
		},
	],
};

export default TypeMesureLarg;
