import formInputsTypes from '../../simulatorConstant';
export const CATEG_ID_MOTEUR_RIDEAU = 17;
import RideauMetalliqueManuelCompletData from './manuel';
import { searchProduct } from '../../../../../odooApi/product';

const sortieCable = {
	id: 'sortieCable',
	type: formInputsTypes?.SELECT_INPUT,
	label: 'Sortie du câble',
	name: 'sortieCable',
	className: 'col-6',
	nextStepData: RideauMetalliqueManuelCompletData,
	nextStep: false,
	options: [
		{
			id: 'CVE',
			label: 'droite vue extérieur',
			value: 'droite vue extérieur',
			nextStep: false,
		},
		{
			id: 'DVI',
			label: 'droite vue intérieur',
			value: 'droite vue intérieur',
			nextStep: false,
		},
		{
			id: 'GVE',
			label: 'gauche vue extérieur',
			value: 'gauche vue extérieur',
			nextStep: false,
		},
		{
			id: 'GVI',
			label: 'gauche vue intérieur',
			value: 'gauche vue intérieur',
			nextStep: false,
		},
	],
};

const BoiteRMData = {
	id: 'BoiteRMData',
	type: formInputsTypes?.CHECKBOX_INPUT,
	label: 'Boite',
	name: 'BoiteRMData',
	className: 'col-6',
	nextStepData: RideauMetalliqueManuelCompletData,
	nextStep: false,
	options: [
		{
			id: 'Boîte à bouton',
			label: 'Boîte à bouton',
			value: 'Boîte à bouton',
			nextStep: false,
		},
		{
			id: 'Boîte à clé',
			label: 'Boîte à clé',
			value: 'Boîte à clé',
			nextStep: false,
		},
		{
			id: 'Boîte à clé avec système de débrayage',
			label: 'Boîte à clé avec système de débrayage',
			value: 'Boîte à clé avec système de débrayage',
			nextStep: false,
		},
		{
			id: 'Emetteur - Recepteur',
			label: 'Émetteur - Récepteur + Bip',
			value: 'Émetteur - Récepteur + Bip',
			nextStep: false,
		},
	],
};

const MoteurRMData = {
	id: 'MoteurRMData',
	type: formInputsTypes?.SELECT_INPUT,
	label: 'Moteur',
	name: 'MoteurRMData',
	className: 'col-6',
	nextStepData: BoiteRMData,
	nextStep: false,
	options: null,
	fetchData: (tokenUserCurent, currentFormValue, fnSuccess) => {
		const generateFilter = () => {
			const filters = [['categ_id', '=', CATEG_ID_MOTEUR_RIDEAU]];

			return filters;
		};
		searchProduct(tokenUserCurent, generateFilter(), async ({ elements }) => {
			const options = elements?.length
				? elements.map((item) => {
					const { product_template_attribute_value_ids } = item;
					let name = item?.display_name + ' ';
					product_template_attribute_value_ids.map((i) => (name += i.name + ' '));
					console.log('name', name);
					return {
						id: item?.id,
						label: name,
						value: item?.id,
					};
				})
				: [];
			fnSuccess(options);
		});
	},
};

const RideauMetalliqueMotoriseCompletData = {
	id: 'AxeMotoriseRMData',
	type: formInputsTypes?.SELECT_INPUT,
	label: 'Motorisation',
	name: 'AxeMotoriseRMData',
	className: 'col-6',
	nextStepData: MoteurRMData,
	nextStep: false,
	options: [
		{
			id: 'centrale monophasé',
			label: 'Centrale monophasé',
			value: 'central monophasé',
			nextStep: false,
		},
		{
			id: 'tubulaire',
			label: 'Tubulaire',
			value: 'tubulaire',
			nextStep: false,
		},
		{
			id: 'latérale',
			label: 'Latérale',
			value: 'latéral',
			nextStep: false,
		},

		{
			id: 'centrale triphasé',
			label: 'Centrale triphasé',
			value: 'central triphasé',
			nextStep: false,
		},
	],
};

export default RideauMetalliqueMotoriseCompletData;
