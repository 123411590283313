import formInputsTypes from '../simulatorConstant';
import tablierRideauMetalliqueData from './tablierRM';
import AxeManuelRMData from './axe/manuel';
import AxeMotoriseRMData from './axe/motorise';

import RMManuelCompletData from './complet/manuel';
import RMMotoriseCompletData from './complet/motorise';
import RideauMetalliqueMotorisationData from './motorisation';

const RideauMetalliqueData = {
	id: 'RMData',
	type: formInputsTypes?.SELECT_INPUT,
	label: 'Type de Fabrication',
	name: 'RMData',
	className: 'col-lg-12',
	nextStep: false,
	options: [
		{
			id: 87,
			label: 'Tablier rideau métallique',
			value: 'RMData_Tablier',
			nextStep: tablierRideauMetalliqueData,
		},
		{
			id: 82,
			label: 'Axe rideau métallique manuel',
			value: 'RMData_AxeManuel',
			nextStep: AxeManuelRMData,
		},
		{
			id: 83,
			label: 'Axe rideau métallique motorisé',
			value: 'RMData_AxeMotorise',
			nextStep: AxeMotoriseRMData,
		},
		{
			id: 85,
			label: 'Rideau métallique manuel complet',
			value: 'RMData_Manuelcomplet',
			nextStep: RMManuelCompletData,
		},
		{
			id: 86,
			label: 'Rideau métallique motorisé complet',
			value: 'RMData_Motorisecomplet',
			nextStep: RMMotoriseCompletData,
		},
		{
			id: 84,
			label: 'Moteur rideau métallique',
			value: 'RMData_Motorisation',
			nextStep: RideauMetalliqueMotorisationData,
		},
	],
};

export default RideauMetalliqueData;
