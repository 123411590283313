const motifRapportIntervention = [
    {
        id: 1,
        name: 'Pose',
        label: 'Pose'
    },
    {
        id: 2,
        name: 'Devis',
        label: 'Devis',
    },
    {
        id: 3,
        name: 'Paiement',
        label: 'Paiement',
    },
    {
        id: 4,
        name: 'Facture',
        label: 'En attente de facturation',
    }


]


export default motifRapportIntervention;


export const motifsPaiement = [
    {
        id: 1,
        name: "Espèces",
        label: 'en espèces',
        parentId: [3]

    },
    {
        id: 2,
        name: "Chéque",
        label: 'par chéque',
        parentId: [3]
    },
    {
        id: 3,
        name: "CB",
        label: 'par carte bancaire',
        parentId: [3]
    },
    {
        id: 4,
        name: "Virement",
        label: 'par virement',
        parentId: [3]
    },
    {
        id: 5,
        name: "Stripe",
        label: 'par stripe',
        parentId: [3]
    },
    {
        id: 6,
        name: 'Paypal',
        label: 'par paypal',
        parentId: [3]
    },

]


export const motifsDevis = [
    {
        id: 1,
        name: 'Accepté',
        label: 'accepté',
        parentId: [2]
    },
    {
        id: 2,
        name: 'En attente',
        label: 'en attente',
        parentId: [2]
    }
]



export const autreMotifs = [
    {
        id: 1,
        name: 'Problème mesure',
        label: ': problème mesure',
        parentId: [2]

    },
    {
        id: 2,
        name: 'Client ne répond pas',
        label: ': client ne répond pas',
        parentId: [2]
    },
    {
        id: 3,
        name: 'Manque Matériel',
        label: ': manque Matériel',
        parentId: [2]
    }
]