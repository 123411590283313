import { searchCategory } from '../../../../odooApi/categoryProdut';
import { searchProduct } from '../../../../odooApi/product';
import formInputsTypes from '../simulatorConstant';
import Moteur from './Moteur';
import TypeStorebanne from './Complet';
import toile from './toile';

export const CATEG_ID_MOTEURS_STORE_BANNE = 217;

export const capteur = {
	id: 'capteur_vent',
	type: formInputsTypes?.SELECT_INPUT,
	label: 'Capteur de vent',
	name: 'capteur_vent',
	nextStepData: toile,
	nextStep: false,
	options: [
		{
			id: 'avec',
			label: 'Avec capteur de vent',
			value: 'Avec capteur de vent',
			nextStep: false,
		},
		{
			id: 'sans',
			label: 'Sans capteur de vent',
			value: 'Sans capteur de vent',
			nextStep: false,
		},
	],
};

export const sortie = {
	id: 'sortie',
	type: formInputsTypes?.RADIO_INPUT,
	label: 'Sortie électrique (vue en face)',
	name: 'sortie',
	nextStepData: capteur,
	nextStep: false,
	options: [
		{
			id: 'droite',
			label: 'Droite',
			value: 'droite',
			nextStep: false,
		},
		{
			id: 'gauche',
			label: 'Gauche',
			value: 'gauche',
			nextStep: false,
		},
	],
};

export const telecommande = {
	id: 'telecommande',
	type: formInputsTypes?.SELECT_INPUT,
	label: 'Type de motorisation',
	name: 'telecommande',
	nextStepData: sortie,
	nextStep: false,
	options: [
		{
			id: 'motorisation_radio',
			label: 'Motorisation radio',
			value: 'motorisation radio',
			nextStep: false,
		},
		{
			id: 'motorisation_filaire',
			label: 'Motorisation filaire',
			value: 'motorisation filaire',
			nextStep: false,
		},
		{
			id: 'motorisation_solaire',
			label: 'Motorisation solaire',
			value: 'motorisation solaire',
			nextStep: false,
		},
	],
};

export const type_fixation = {
	id: 'type_fixation',
	type: formInputsTypes?.RADIO_INPUT,
	label: 'Type de fixation',
	name: 'type_fixation',
	nextStepData: capteur,
	nextStep: false,
	options: [
		{
			id: 'murale',
			label: 'Murale',
			value: 'murale',
			nextStep: false,
		},
		{
			id: 'plafond',
			label: 'Plafond',
			value: 'plafond',
			nextStep: false,
		},
		{
			id: 'entre_murs',
			label: 'Entre murs',
			value: 'entre murs',
			nextStep: false,
		},
	],
};
export const remplacement_telecommande = {
	id: 'remplacement_telecommande',
	type: formInputsTypes?.RADIO_INPUT,
	label: 'Remplacement de la télécommande',
	name: 'remplacement_telecommande',
	nextStepData: false,
	nextStep: false,
	fetchData: async (tokenUserCurent, currentFormValue, fnSuccess) => {
		const generateDescription = () => {
			return `${currentFormValue?.StoreBanneData
				? ` ` + currentFormValue?.StoreBanneData?.label
				: ''
				}
            ${currentFormValue?.remplacement_moteur == 'oui_moteur'
					? `\n - Remplacement moteur par ` + currentFormValue?.moteur_store_banne?.label
					: ''
				}
          `;
		};
		const options = [
			{
				id: 'oui_telecommande',
				label: 'Oui',
				value: 'oui',
				nextStep: type_fixation,
			},
			{
				id: 'non-telecommande',
				label: 'Non',
				value: 'non',

				nextStep: toile,
			},
		];
		fnSuccess(options);
	},
};
export const moteur_store_banne = {
	id: 'moteur_store_banne',
	type: formInputsTypes?.SELECT_INPUT,
	label: 'Sélectionner un moteur',
	name: 'moteur_store_banne',
	nextStepData: remplacement_telecommande,
	nextStep: false,
	options: null,
	fetchData: (tokenUserCurent, currentFormValue, fnSuccess) => {
		const generateFilter = (idCat) => {
			const filters = [['categ_id', '=', idCat]];

			return filters;
		};

		searchProduct(
			tokenUserCurent,
			generateFilter(currentFormValue?.marqueMoteurStore?.id),
			({ elements }) => {
				const options = elements?.length
					? elements.map((item) => ({
						id: item?.id,
						label: item?.display_name,
						value: item?.id,
						lst_price: item?.lst_price,
						brand_id: item?.brand_id,
					}))
					: [];
				fnSuccess(options);
			},
		);
	},
};
const marqueMoteurStore = {
	id: 'marqueMoteurStore',
	type: formInputsTypes?.SELECT_INPUT,
	label: 'Sélectionner la marque du moteur',
	name: 'marqueMoteurStore',
	nextStepData: moteur_store_banne,
	nextStep: false,
	options: null,
	fetchData: (tokenUserCurent, currentFormValue, fnSuccess) => {
		const generateFilter = () => {
			const filters = [['parent_id', '=', CATEG_ID_MOTEURS_STORE_BANNE]];

			return filters;
		};
		searchCategory(tokenUserCurent, generateFilter(currentFormValue), ({ elements }) => {
			const options = elements?.length
				? elements?.map((item) => ({
					id: item?.id,
					label: item?.name,
					value: item?.id,
					/* nextStep:item?.name=="Bubendorff"?typeMoteurBubendorff:typeMoteur */
				}))
				: [];
			fnSuccess(options);
		});
	},
};

export const remplacement_moteur = {
	id: 'remplacement_moteur',
	type: formInputsTypes?.RADIO_INPUT,
	label: 'Remplacement un moteur',
	name: 'remplacement_moteur',
	nextStepData: false,
	nextStep: false,
	options: [
		{
			id: 'oui_moteur',
			label: 'Oui',
			value: 'oui',
			nextStep: telecommande,
		},
		{
			id: 'non_moteur',
			label: 'Non',
			value: 'non',
			nextStep: remplacement_telecommande,
		},
	],
};

export const hauteur_manivelle = {
	id: 'hauteur_manivelle_store',
	type: formInputsTypes?.SELECT_INPUT,
	label: 'Hauteur de votre manivelle',
	name: 'hauteur_manivelle_store',
	nextStepData: toile,
	nextStep: false,
	options: [
		{
			id: '50',
			label: '50mm',
			value: '50mm',
			nextStep: false,
		},
		{
			id: '80',
			label: '80mm',
			value: '80mm',
			nextStep: false,
		},
		{
			id: '100',
			label: '100mm',
			value: '100mm',
			nextStep: false,
		},
		{
			id: '120',
			label: '120mm',
			value: '120mm',
			nextStep: false,
		},
		{
			id: '180',
			label: '180mm',
			value: '180mm',
			nextStep: false,
		},
	],
};

export const manivelle = {
	id: 'manivelle_store',
	type: formInputsTypes?.RADIO_INPUT,
	label: 'Type de commande',
	name: 'manivelle_store',
	nextStepData: false,
	nextStep: false,
	options: [
		{
			id: 'Manivelle',
			label: 'Manuelle à manivelle',
			value: 'Manuelle à manivelle',
			checked: true,
			nextStep: hauteur_manivelle,
		},
	],
};

export const manoeuvre = {
	id: 'manoeuvre_store',
	type: formInputsTypes?.RADIO_INPUT,
	label: 'Manoeuvre',
	name: 'manoeuvre_store',
	nextStepData: false,
	nextStep: false,
	options: [
		{
			id: 'manuel',
			label: 'Manuel',
			value: 'manuel',
			nextStep: manivelle,
		},
		{
			id: 'electrique',
			label: 'Électrique',
			value: 'électrique',
			nextStep: telecommande,
		},
	],
};

const StoreBanneData = {
	id: 'TF',
	type: formInputsTypes?.RADIO_INPUT,
	label: 'Type de Fabrication',
	name: 'StoreBanneData',
	className: 'col-12',
	nextStepData: false,
	nextStep: false,
	options: [
		{
			id: 64,
			label: 'Toile store banne',
			value: 'Toile store banne',
			nextStep: toile,
		},
		{
			id: 97,
			label: 'Store banne complet',
			value: 'Store banne',
			nextStep: TypeStorebanne,
		},

		{
			id: 98,
			label: 'Moteur store banne',
			value: 'Moteur store banne',
			nextStep: Moteur,
		},
		// {
		// 	id: 11296,
		// 	label: 'Lambrequin',
		// 	value: 'Lambrequin',
		// 	nextStep: manoeuvre,
		// },
		// {
		// 	id: 11296,
		// 	label: 'Bras',
		// 	value: 'Bras',
		// 	nextStep: manoeuvre,
		// },
	],
};

export default StoreBanneData;
