import { searchProduct } from '../../../../odooApi/product';
import { getSingleattribute } from '../../../../odooApi/productAttribute';
import formInputsTypes from '../simulatorConstant';
export const TOILE_STORE_BANNE = 106;
export const FIXATION_ATTRIBUTE = 7;

import droite from '../../../../assets/img/stock/store/Droite.jpg';

import gauche from '../../../../assets/img/stock/store/Gauche.jpg';

import manivelles from '../../../../assets/img/stock/store/manivelle.jpg';

export const capteur = {
    id: 'capteur_vent',
    type: formInputsTypes?.RADIO_INPUT,
    label: 'Capteur de vent',
    name: 'capteur_vent',
    nextStepData: false,
    nextStep: false,
    options: null,

    fetchData: (tokenUserCurent, currentFormValue, fnSuccess) => {
        let data = '';
        const generateDescription = (data) => {
            return ` ${currentFormValue?.StoreBanneData ? `` + currentFormValue?.StoreBanneData?.value : ''
                } ${currentFormValue?.manoeuvre_store
                    ? ` ` + currentFormValue?.manoeuvre_store?.value + ' complet'
                    : ''
                } ${currentFormValue?.TypeStorebanne
                    ? ` \n - Store banne ` + currentFormValue?.TypeStorebanne?.label
                    : ''
                } ${currentFormValue?.ToileStoreBanneData
                    ? ` \n - Toile ` + currentFormValue?.ToileStoreBanneData?.label
                    : ''
                }${currentFormValue?.TypetoileStorebanne
                    ? ` \n - ` + currentFormValue?.TypetoileStorebanne?.label
                    : ''
                } ${currentFormValue?.largeurToile
                    ? ` \n - Largeur de store banne ` + currentFormValue?.largeurToile + ' (mm)'
                    : ''
                } ${currentFormValue?.projection
                    ? ` \n - Projection ` + currentFormValue?.projection
                    : ''
                } ${currentFormValue?.avancee ? `\n - Avancée ` + currentFormValue?.avancee?.label : ''
                } ${currentFormValue?.cache
                    ? `\n - Couleur des caches en plastique ` + currentFormValue?.cache?.label
                    : ''
                } ${currentFormValue?.fixation_haute
                    ? `\n - Fixation haute ` + currentFormValue?.fixation_haute?.label
                    : ''
                } ${currentFormValue?.fixation_basse
                    ? `\n - Fixation basse ` + currentFormValue?.fixation_basse?.label
                    : ''
                } ${currentFormValue?.type_fixation
                    ? `\n - Fixation ` + currentFormValue?.type_fixation?.value
                    : ''
                } ${data ? `\n - ` + data : ''} ${currentFormValue?.lambrequin
                    ? `\n - ` + currentFormValue?.lambrequin?.value
                    : ''
                } ${currentFormValue?.remplacement_telecommande?.id == 'oui_telecommande'
                    ? `\n - Avec télécommande `
                    : ''
                } ${currentFormValue?.telecommande
                    ? `\n - ` + currentFormValue?.telecommande?.value
                    : ''
                } ${currentFormValue?.sortie
                    ? `\n - Sortie électrique ` + currentFormValue?.sortie?.value
                    : ''
                } 
             `;
        };
        const options = [

            {
                id: 'avec',
                label: 'Avec capteur de vent',
                value: 'Avec capteur de vent',
                product_id: currentFormValue?.StoreBanneData?.id,
                description: generateDescription((data = 'Avec capteur de vent')),
                nextStep: false,
            },
            {
                id: 'sans',
                label: 'Sans capteur de vent',
                value: 'Sans capteur de vent',
                product_id: currentFormValue?.StoreBanneData?.id,
                description: generateDescription((data = 'Sans capteur de vent')),
                nextStep: false,
            },

        ];

        fnSuccess(options);
    },
};

export const sortie = {
    id: 'sortie',
    type: formInputsTypes?.RADIO_INPUT,
    label: 'Sortie électrique (vue en face)',
    name: 'sortie',
    nextStepData: capteur,
    classGroup: "SProduit",
    nextStep: false,
    options: [

        {
            id: 'gauche',
            label: '',
            value: 'gauche',
            imageUrl: gauche,
            nextStep: false,
        },
        {
            id: 'droite',
            label: '',
            value: 'droite',
            imageUrl: droite,
            nextStep: false,
        }
    ],
};

export const telecommande = {
    id: 'telecommande',
    type: formInputsTypes?.RADIO_INPUT,
    label: 'Type de motorisation',
    name: 'telecommande',
    nextStepData: sortie,
    nextStep: false,
    options: [
        {
            id: 'motorisation_radio',
            label: 'Radio',
            value: 'Motorisation radio',
            nextStep: false,
        },
        {
            id: 'motorisation_filaire',
            label: 'Filaire',
            value: 'Motorisation filaire',
            nextStep: false,
        },
        {
            id: 'motorisation_solaire',
            label: 'Solaire',
            value: 'Motorisation solaire',
            nextStep: false,
        },
    ],
};

export const type_fixation = {
    id: 'type_fixation',
    type: formInputsTypes?.RADIO_INPUT,
    label: 'Type de fixation',
    name: 'type_fixation',
    nextStepData: capteur,
    nextStep: false,
    options: [
        {
            id: 'murale',
            label: 'Murale',
            value: 'murale',
            nextStep: false,
        },
        {
            id: 'plafond',
            label: 'Plafond',
            value: 'plafond',
            nextStep: false,
        },
        {
            id: 'entre_murs',
            label: 'Entre murs',
            value: 'entre murs',
            nextStep: false,
        },
    ],
};
export const remplacement_telecommande = {
    id: 'remplacement_telecommande',
    type: formInputsTypes?.RADIO_INPUT,
    label: 'Remplacement de la télécommande',
    name: 'remplacement_telecommande',
    nextStepData: false,
    nextStep: false,
    fetchData: async (tokenUserCurent, currentFormValue, fnSuccess) => {
        const generateDescription = () => {
            return `${currentFormValue?.StoreBanneData
                ? ` ` + currentFormValue?.StoreBanneData?.label
                : ''
                }
            ${currentFormValue?.remplacement_moteur == 'oui_moteur'
                    ? `\n - Remplacement moteur par ` + currentFormValue?.moteur_store_banne?.label
                    : ''
                }
          `;
        };
        const options = [
            {
                id: 'oui_telecommande',
                label: 'Oui',
                value: 'oui',
                nextStep: type_fixation,
            },
            {
                id: 'non-telecommande',
                label: 'Non',
                value: 'non',

                nextStep: type_fixation,
            },
        ];
        fnSuccess(options);
    },
};
export const moteur_store_banne = {
    id: 'moteur_store_banne',
    type: formInputsTypes?.SELECT_INPUT,
    label: 'Sélectionner un moteur',
    name: 'moteur_store_banne',
    nextStepData: remplacement_telecommande,
    nextStep: false,
    options: null,
    fetchData: (tokenUserCurent, currentFormValue, fnSuccess) => {
        const generateFilter = (idCat) => {
            const filters = [['categ_id', '=', idCat]];

            return filters;
        };

        searchProduct(
            tokenUserCurent,
            generateFilter(currentFormValue?.marqueMoteurStore?.id),
            ({ elements }) => {
                const options = elements?.length
                    ? elements.map((item) => ({
                        id: item?.id,
                        label: item?.display_name,
                        value: item?.id,
                        lst_price: item?.lst_price,
                        brand_id: item?.brand_id,
                    }))
                    : [];
                fnSuccess(options);
            },
        );
    },
};
const marqueMoteurStore = {
    id: 'marqueMoteurStore',
    type: formInputsTypes?.SELECT_INPUT,
    label: 'Sélectionner la marque du moteur',
    name: 'marqueMoteurStore',
    nextStepData: moteur_store_banne,
    nextStep: false,
    options: null,
    fetchData: (tokenUserCurent, currentFormValue, fnSuccess) => {
        const generateFilter = () => {
            const filters = [['parent_id', '=', CATEG_ID_MOTEURS_STORE_BANNE]];

            return filters;
        };
        searchCategory(tokenUserCurent, generateFilter(currentFormValue), ({ elements }) => {
            const options = elements?.length
                ? elements?.map((item) => ({
                    id: item?.id,
                    label: item?.name,
                    value: item?.id,
                    /* nextStep:item?.name=="Bubendorff"?typeMoteurBubendorff:typeMoteur */
                }))
                : [];
            fnSuccess(options);
        });
    },
};

export const remplacement_moteur = {
    id: 'remplacement_moteur',
    type: formInputsTypes?.RADIO_INPUT,
    label: 'Remplacement un moteur',
    name: 'remplacement_moteur',
    nextStepData: false,
    nextStep: false,
    options: [
        {
            id: 'oui_moteur',
            label: 'Oui',
            value: 'oui',
            nextStep: telecommande,
        },
        {
            id: 'non_moteur',
            label: 'Non',
            value: 'non',
            nextStep: remplacement_telecommande,
        },
    ],
};

export const hauteur_manivelle = {
    id: 'hauteur_manivelle_store',
    type: formInputsTypes?.RADIO_INPUT,
    label: 'Hauteur de votre manivelle',
    name: 'hauteur_manivelle_store',
    classGroup: "SProduit",
    nextStepData: false,
    nextStep: false,
    options: null,
    fetchData: (tokenUserCurent, currentFormValue, fnSuccess) => {
        let data = '';
        const generateDescription = (data) => {
            return ` ${currentFormValue?.StoreBanneData ? `` + currentFormValue?.StoreBanneData?.value : ''
                } ${currentFormValue?.manoeuvre_store
                    ? ` ` + currentFormValue?.manoeuvre_store?.value + ' complet'
                    : ''
                } ${currentFormValue?.TypeStorebanne
                    ? ` \n - Store banne ` + currentFormValue?.TypeStorebanne?.label
                    : ''
                } ${currentFormValue?.TypeStorebanne
                    ? ` \n - ` + currentFormValue?.TypeStorebanne?.label
                    : ''
                } ${currentFormValue?.ToileStoreBanneData
                    ? ` \n - Toile ` + currentFormValue?.ToileStoreBanneData?.label
                    : ''
                } ${currentFormValue?.TypetoileStorebanne
                    ? ` \n - ` + currentFormValue?.TypetoileStorebanne?.label
                    : ''
                } ${currentFormValue?.largeurToile
                    ? ` \n - Largeur de store banne ` + currentFormValue?.largeurToile + ' (mm)'
                    : ''
                } ${currentFormValue?.projection
                    ? ` \n - Projection ` + currentFormValue?.projection
                    : ''
                } ${currentFormValue?.avancee ? `\n - Avancée ` + currentFormValue?.avancee?.label : ''
                } ${currentFormValue?.cache
                    ? `\n - Couleur des caches en plastique ` + currentFormValue?.cache?.label
                    : ''
                } ${currentFormValue?.fixation_haute
                    ? `\n - Fixation haute ` + currentFormValue?.fixation_haute?.label
                    : ''
                } ${currentFormValue?.fixation_basse
                    ? `\n - Fixation basse ` + currentFormValue?.fixation_basse?.label
                    : ''
                } ${currentFormValue?.type_fixation
                    ? `\n - Fixation ` + currentFormValue?.type_fixation?.value
                    : ''
                }  ${currentFormValue?.lambrequin
                    ? `\n - ` + currentFormValue?.lambrequin?.value
                    : ''
                } ${currentFormValue?.manivelle_store
                    ? `\n - ` + currentFormValue?.manivelle_store?.label
                    : ''
                } ${data
                    ? `\n - Hauteur de la manivelle ` + data
                    : ''
                } 
             `;
        };
        const options = [
            {
                id: '50',
                label: '500 mm',
                value: '500 mm',
                product_id: currentFormValue?.StoreBanneData?.id,
                description: generateDescription((data = '500 mm')),
                imageUrl: manivelles,
                nextStep: false,
            },
            {
                id: '80',
                label: '800 mm',
                value: '800 mm',
                product_id: currentFormValue?.StoreBanneData?.id,
                description: generateDescription((data = '800 mm')),
                imageUrl: manivelles,
                nextStep: false,
            },
            {
                id: '100',
                label: '1000 mm',
                value: '1000 mm',
                product_id: currentFormValue?.StoreBanneData?.id,
                description: generateDescription((data = '1000 mm')),
                imageUrl: manivelles,
                nextStep: false,
            },
            {
                id: '120',
                label: '1200 mm',
                value: '1200 mm',
                product_id: currentFormValue?.StoreBanneData?.id,
                description: generateDescription((data = '1200 mm')),
                imageUrl: manivelles,
                nextStep: false,
            },
            {
                id: '180',
                label: '1800 mm',
                value: '1800 mm',
                product_id: currentFormValue?.StoreBanneData?.id,
                description: generateDescription((data = '1800 mm')),
                imageUrl: manivelles,
                nextStep: false,
            },
        ];

        fnSuccess(options);
    },
};

export const manivelle = {
    id: 'manivelle_store',
    type: formInputsTypes?.RADIO_INPUT,
    label: 'Type de commande',
    name: 'manivelle_store',
    nextStepData: false,
    nextStep: false,
    options: [
        {
            id: 'Manivelle',
            label: 'Manuelle à manivelle',
            value: 'Manuelle à manivelle',
            checked: true,
            nextStep: hauteur_manivelle,
        },
    ],
};

export const manoeuvre = {
    id: 'manoeuvre_store',
    type: formInputsTypes?.RADIO_INPUT,
    label: 'Manoeuvre',
    name: 'manoeuvre_store',
    nextStepData: false,
    nextStep: false,
    options: [
        {
            id: 'manuel',
            label: 'Manuel',
            value: 'manuel',
            nextStep: manivelle,
        },
        {
            id: 'electrique',
            label: 'Électrique',
            value: 'électrique',
            nextStep: telecommande,
        },
    ],
};


const lambrequin = {
    id: 'lambrequin',
    type: formInputsTypes?.SELECT_INPUT,
    label: 'Lambrequin',
    name: 'lambrequin',
    nextStepData: manoeuvre,
    nextStep: false,
    options: [
        {
            id: 'sans_lambrequin',
            label: 'Sans lambrequin',
            value: 'Sans lambrequin',

            nextStep: false,
        },
        {
            id: 'coupe_droite',
            label: 'Coupe droite',
            value: 'Lambrequin coupe droite',

            nextStep: false,
        },
        {
            id: 'coupe_ondulee',
            label: 'Coupe ondulée',
            value: 'Lambrequin coupe ondulée',

            nextStep: false,
        },
        {
            id: 'coupe_vagues',
            label: 'Coupe à vagues',
            value: 'Lambrequin coupe à vagues',

            nextStep: false,
        },
        {
            id: 'lambrequin_enroulable',
            label: 'Lambrequin enroulable 150mm',
            value: 'lambrequin enroulable 150mm',

            nextStep: false,
        },
    ],


};


const TypetoileStorebanne = {
    id: 'T_Store',
    type: formInputsTypes?.SELECT_INPUT,
    label: 'Type de la toile',
    name: 'TypetoileStorebanne',
    nextStepData: lambrequin,
    nextStep: false,
    options: [
        {
            id: 'TSB1',
            label: 'Toile de store banne',
            value: 'Toile de store banne',
            nextStep: false,
        },
        {
            id: 'TSBD1',
            label: 'Toile de store bras droits',
            value: 'Toile de store bras droits',
            nextStep: false,
        },
        {
            id: 'TSBD1',
            label: 'Toile store double pente',
            value: 'Toile store double pente',
            nextStep: false,
        },
        {
            id: 'TSBD1',
            label: 'Toile de store à descente verticale',
            value: 'Toile de store à descente verticale',
            nextStep: false,
        },
    ],
};



const ToileStoreBanneData = {
    id: 'T_Store',
    type: formInputsTypes?.RADIO_INPUT,
    label: 'Sélectionner une toile',
    name: 'T_Store',
    nextStepData: TypetoileStorebanne,
    nextStep: false,
    options: null,
    fetchData: (tokenUserCurent, currentFormValue, fnSuccess) => {
        const generateFilter = () => {
            const filters = [['product_tmpl_id', '=', TOILE_STORE_BANNE]];

            return filters;
        };
        searchProduct(tokenUserCurent, generateFilter(currentFormValue), ({ elements }) => {
            const options = elements?.length
                ? elements.map((item) => {
                    const { product_template_attribute_value_ids } = item;
                    let name = item?.display_name + ' ';
                    product_template_attribute_value_ids.map((i) => (name += i.name + ' '));
                    return {
                        id: item?.id,
                        label: name,
                        value: item?.id,
                    };
                })
                : [];
            fnSuccess(options);
        });
    },
};


const fixation_basse = {
    id: 'fixation_basse',
    type: formInputsTypes?.SELECT_INPUT,
    label: 'Fixation basse',
    name: 'fixation_basse',
    nextStepData: ToileStoreBanneData,
    nextStep: false,
    options: null,
    fetchData: (tokenUserCurent, currentFormValue, fnSuccess) => {
        getSingleattribute(tokenUserCurent, FIXATION_ATTRIBUTE, ({ value_ids }) => {
            const options = value_ids?.length
                ? value_ids?.map((item) => ({
                    id: item?.id,
                    label: item?.name,
                    value: item?.id,
                }))
                : [];
            fnSuccess(options);
        });
    },
};

const fixation_haute = {
    id: 'fixation_haute',
    type: formInputsTypes?.SELECT_INPUT,
    label: 'Fixation haute',
    name: 'fixation_haute',
    nextStepData: fixation_basse,
    nextStep: false,
    options: null,
    fetchData: (tokenUserCurent, currentFormValue, fnSuccess) => {
        getSingleattribute(tokenUserCurent, FIXATION_ATTRIBUTE, ({ value_ids }) => {
            const options = value_ids?.length
                ? value_ids?.map((item) => ({
                    id: item?.id,
                    label: item?.name,
                    value: item?.id,
                }))
                : [];
            fnSuccess(options);
        });
    },
};

const cache = {
    id: 'cache',
    type: formInputsTypes?.RADIO_INPUT,
    label: 'Couleur des caches en plastique',
    name: 'cache',
    className: 'col-12',
    nextStep: fixation_haute,
    options: [
        {
            id: 'blanc',
            label: 'blanc',
            value: 'blanc',
            nextStep: fixation_haute,
        },
        {
            id: 'noir',
            label: 'noir',
            value: 'noir',
            nextStep: fixation_haute,
        },
    ],
};

const avancee = {
    id: 'avancee',
    type: formInputsTypes?.RADIO_INPUT,
    label: "L'avancée de store banne",
    name: 'avancee',
    nextStepData: fixation_haute,
    //nextStep: fixation_haute,
    options: [
        {
            id: '150',
            label: '1500 mm',
            value: '1500 mm',
            nextStep: fixation_haute,
        },
        {
            id: '175',
            label: '1750 mm',
            value: '1750 mm',
            nextStep: fixation_haute,
        },
        {
            id: '200',
            label: '2000 mm',
            value: '2000 mm',
            nextStep: fixation_haute,
        },
        {
            id: '225',
            label: '2250 mm',
            value: '2250 mm',
            nextStep: fixation_haute,
        },
        {
            id: '250',
            label: '2500 mm',
            value: '2500 mm',
            nextStep: fixation_haute,
        },
        {
            id: '300',
            label: '3000 mm',
            value: '3000 mm',
            nextStep: fixation_haute,
        },
        {
            id: '350',
            label: '3500 mm',
            value: '3500 mm',
            nextStep: fixation_haute,
        },
    ],
};

const largeurStorebanne = {
    id: 'L-TOILE',
    type: formInputsTypes?.INPUT_NUMBER,
    name: 'largeurToile',
    className: 'col-6',
    label: 'Largeur de store banne(mm)',
    nextStepData: avancee,
    nextStep: false,
    validation: (value) => {
        return value > 0;
    },
};

const TypeStorebanne = {
    id: 'T_Store',
    type: formInputsTypes?.RADIO_INPUT,
    label: 'Type de store banne',
    name: 'TypeStorebanne',
    nextStepData: largeurStorebanne,
    nextStep: false,
    options: [
        {
            id: 'TSB3',
            label: 'Monobloc (sans coffre)',
            value: 'Monobloc',
            nextStep: false,
        },
        {
            id: 'TSB2',
            label: 'Coffre',
            value: 'Coffre',
            nextStep: false,
        },
        {
            id: 'TSB1',
            label: 'Semi coffre',
            value: 'Semi coffre',
            nextStep: false,
        },

    ],
};


export default TypeStorebanne;